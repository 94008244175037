import { Component, OnInit } from "@angular/core";
import { InformationPageComponent } from "../../generic-components/information-page/information-page.component";

@Component({
    selector: "app-privacy",
    templateUrl: "./privacy.component.html",
    styleUrls: ["./privacy.component.scss"],
    standalone: true,
    imports: [InformationPageComponent]
})
export class PrivacyComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
