import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { FeedbackService } from "../services/feedback.service";

@Injectable({
  providedIn: "root",
})
export class CanActivateFeedbackGuard  {
  constructor(
    private feedbackService: FeedbackService,
    private router: Router
  ) {}

  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    let displayFeedback = this.feedbackService.getDisplayFeedbackValue();

    /* displayFeedback service has no value, meaning we need to make initial api call. */
    if (displayFeedback === null) {
      await this.feedbackService.getDisplayFeedback();
      displayFeedback = this.feedbackService.getDisplayFeedbackValue();
    }

    if (!displayFeedback) {
      this.router.navigateByUrl("/");
      return false;
    }

    return true;
  }
}
