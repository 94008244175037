export enum FileErrorsEnum {
  MIME_TYPE_ERROR = "סוג קובץ שגוי",
  FILE_SIZE_ERROR = "גודל קובץ שגוי, גודל מירבי: ",
  NUM_OF_FILES_ERROR = "כמות קבצים מותרת: ",
}

export interface IFileAttachmentResponse {
  success: boolean;
  message?: string;
  errors: IFileAttachmentError[];
}

export interface IFileAttachmentError {
  error: string;
  fileName: string;
  fileNumber: number;
}
