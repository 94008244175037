// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast {
  position: fixed;
  right: 0;
  left: 0;
  margin: 0 auto;
  width: 300px;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  animation: move 2s both;
}
.toast.toast-success {
  color: var(--toaster-success-color);
  background-color: var(--toaster-success-bg-color);
  border-color: var(--toaster-success-border-color);
}
.toast.toast-error {
  color: var(--toaster-error-color);
  background-color: var(--toaster-error-bg-color);
  border-color: var(--toaster-error-border-color);
}
.toast.toast-warning {
  color: var(--toaster-warning-color);
  background-color: var(--toaster-warning-bg-color);
  border-color: var(--toaster-warning-border-color);
}
.toast .close {
  position: absolute;
  top: 7px;
  right: 10px;
  font-size: 1.5em;
  cursor: pointer;
}
.toast .toast-heading {
  margin-top: 10px;
}
.toast .content-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 2rem;
}
.toast .content-wrapper img {
  width: 30px;
  height: 30px;
}
@keyframes move {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}
.toast .text {
  font-family: var(--main-font-regular);
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/toaster-container/toaster/toaster.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,QAAA;EACA,OAAA;EACA,cAAA;EACA,YAAA;EACA,wBAAA;EACA,6BAAA;EACA,sBAAA;EACA,uBAAA;AACF;AACE;EACE,mCAAA;EACA,iDAAA;EACA,iDAAA;AACJ;AAEE;EACE,iCAAA;EACA,+CAAA;EACA,+CAAA;AAAJ;AAGE;EACE,mCAAA;EACA,iDAAA;EACA,iDAAA;AADJ;AAIE;EACE,kBAAA;EACA,QAAA;EACA,WAAA;EACA,gBAAA;EACA,eAAA;AAFJ;AAKE;EACE,gBAAA;AAHJ;AAME;EACE,aAAA;EACA,2BAAA;EACA,mBAAA;EACA,SAAA;AAJJ;AAMI;EACE,WAAA;EACA,YAAA;AAJN;AAQE;EACE;IACE,4BAAA;EANJ;EAQE;IACE,wBAAA;EANJ;AACF;AASE;EACE,qCAAA;EACA,eAAA;AAPJ","sourcesContent":[".toast {\n  position: fixed;\n  right: 0;\n  left: 0;\n  margin: 0 auto;\n  width: 300px;\n  padding: 0.75rem 1.25rem;\n  border: 1px solid transparent;\n  border-radius: 0.25rem;\n  animation: move 2s both;\n\n  &.toast-success {\n    color: var(--toaster-success-color);\n    background-color: var(--toaster-success-bg-color);\n    border-color: var(--toaster-success-border-color);\n  }\n\n  &.toast-error {\n    color: var(--toaster-error-color);\n    background-color: var(--toaster-error-bg-color);\n    border-color: var(--toaster-error-border-color);\n  }\n\n  &.toast-warning {\n    color: var(--toaster-warning-color);\n    background-color: var(--toaster-warning-bg-color);\n    border-color: var(--toaster-warning-border-color);\n  }\n\n  .close {\n    position: absolute;\n    top: 7px;\n    right: 10px;\n    font-size: 1.5em;\n    cursor: pointer;\n  }\n\n  .toast-heading {\n    margin-top: 10px;\n  }\n\n  .content-wrapper {\n    display: flex;\n    justify-content: flex-start;\n    align-items: center;\n    gap: 2rem;\n\n    img {\n      width: 30px;\n      height: 30px;\n    }\n  }\n\n  @keyframes move {\n    from {\n      transform: translateY(-100%);\n    }\n    to {\n      transform: translateY(0);\n    }\n  }\n\n  .text {\n    font-family: var(--main-font-regular);\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
