import { Injectable } from "@angular/core";
import { HttpHandler } from "./httpHandler";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { BehaviorSubject } from "rxjs";

interface IUser {
  person: {
    full_name: string;
    id: string;
  };
}

@Injectable()
export class AuthService extends HttpHandler {
  private _user = new BehaviorSubject<IUser | null>(null);

  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService
  ) {
    super(_http, httpService, errorService);
  }
  logout() {
    sessionStorage.removeItem("token");
    if (sessionStorage.getItem("a-auth-token"))
      sessionStorage.removeItem("a-auth-token");
  }

  getToken() {
    return sessionStorage.getItem("token");
  }

  setToken(token: string) {
    sessionStorage.setItem("token", token);
  }

  getUser() {
    return this._user.asObservable();
  }

  /**
   * Return boolean if current token is valid
   */
  validateToken(): Promise<boolean> {
    return new Promise((resolve) => {
      // Dummy API Call to check for auth token validity
      let token = this.getToken();
      if (token) {
        const url = "/contact/profile-flat";
        this.get(url)
          .toPromise()
          .then((resp) => {
            this._user.next(resp);
            resolve(true);
          })
          .catch((err) => {
            resolve(false);
          });
      } else {
        resolve(false);
      }
    });
  }
}
