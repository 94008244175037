import { Injectable } from "@angular/core";
import { UntypedFormControl, Validators } from "@angular/forms";
import * as moment from "moment";
import { RegisterUserDataEnum } from "../models/register-user-data.model";
import { TextService } from "./text.service";
import { WorkTerminationDataEnum } from "../models/work-termination.model";

@Injectable({
  providedIn: "root",
})
export class FormValidatorsService {
  NumbersOrDecimalsRegex: RegExp = /^\d*\.?\d+$/i;

  constructor(private textService: TextService) {
    if (!this.textService.textSource) {
      this.textService.setTextSource();
    }
  }

  validateBirthDateFormat(control: UntypedFormControl) {
    let date = moment(control.value).format("DD/MM/YYYY");
    let invalid = false;
    const da = date.split("/");
    let today = new Date();

    if (
      da.length !== 3 ||
      da[0].length !== 2 ||
      da[1].length !== 2 ||
      da[2].length !== 4
    ) {
      invalid = true;
    } else if (moment(date).diff(moment(today).format("DD/MM/YYYY")) > 0) {
      invalid = true;
    }

    if (invalid) {
      return { date: true };
    } else {
      return null;
    }
  }

  validateDate(control: UntypedFormControl) {
    if (control.value) {
      const date = moment(control.value);
      const isValid = date.isValid();
      if (!isValid) {
        return { date: true };
      }
    }
    return null;
  }

  formValidatorMap(inputName: string) {
    return (
      {
        [RegisterUserDataEnum.ID_NUMBER_TYPE]: [Validators.required],
        [RegisterUserDataEnum.ID_NUMBER]: [
          Validators.required,
          this.validateIsraeliId,
          Validators.pattern(/^[0-9]+$/i),
        ],
        [RegisterUserDataEnum.FIRST_NAME]: [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(40),
          Validators.pattern(/^[a-z \u0590-\u05fe \']+$/i),
        ],
        [RegisterUserDataEnum.LAST_NAME]: [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(40),
          Validators.pattern(/^[a-z \u0590-\u05fe \']+$/i),
        ],
        [RegisterUserDataEnum.DATE_OF_BIRTH]: [
          Validators.required,
          this.validateBirthDateFormat,
        ],
        [RegisterUserDataEnum.FAMILY_STATUS]: [],
        [RegisterUserDataEnum.CITY]: [
          Validators.maxLength(40),
          Validators.minLength(2),
        ],
        [RegisterUserDataEnum.STREET]: [
          Validators.maxLength(40),
          Validators.minLength(2),
        ],
        [RegisterUserDataEnum.GENDER]: [Validators.required],
        [RegisterUserDataEnum.BUILDING_NUMBER]: [Validators.maxLength(8)],
        [RegisterUserDataEnum.EMAIL]: [Validators.email, Validators.required],
        [RegisterUserDataEnum.MOBILE_PHONE]: [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(10),
          Validators.pattern(/^[0-9]+$/i),
        ],
        // 2nd step
        [RegisterUserDataEnum.EMPLOYEE_NUMBER]: [
          Validators.maxLength(40),
          Validators.minLength(2),
          Validators.pattern(/^[0-9]+$/i),
        ],
        [RegisterUserDataEnum.ROLE]: [
          Validators.minLength(2),
          Validators.maxLength(40),
        ],
        [RegisterUserDataEnum.START_WORK_DATE]: [
          Validators.required,
          this.validateDate,
        ],
        [RegisterUserDataEnum.SALARY_TYPE]: [Validators.required],
        [RegisterUserDataEnum.SALARY]: [
          Validators.required,
          Validators.maxLength(6),
          Validators.pattern(/^[0-9]+$/i),
        ],
        [RegisterUserDataEnum.MONTHLY_PENSION_SALARY]: [
          Validators.maxLength(6),
          Validators.pattern(/^[0-9]+$/i),
        ],
        // 3rd step
        [RegisterUserDataEnum.ALLOWANCE_COMPENSATIONS_PERCENTAGE]: [
          Validators.pattern(this.NumbersOrDecimalsRegex),
          Validators.max(100),
        ],
        [RegisterUserDataEnum.EMPLOYEE_BENEFITS]: [
          Validators.pattern(this.NumbersOrDecimalsRegex),
          Validators.max(10),
          Validators.min(0),
        ],
        [RegisterUserDataEnum.EMPLOYER_BENEFITS]: [
          Validators.pattern(this.NumbersOrDecimalsRegex),
          Validators.max(10),
          Validators.min(0),
        ],
        [RegisterUserDataEnum.ELIGIBILITY_DATE]: [this.validateDate],
        [RegisterUserDataEnum.PARAGRAPH_14]: [],
        [RegisterUserDataEnum.EMPLOYEE_INCAPACITY_TO_WORK]: [],
        [RegisterUserDataEnum.ELIGIBILITY_STUDY_FUND]: [],
        [RegisterUserDataEnum.ELIGIBILITY_DATE_STUDY_FUND]: [this.validateDate],
        [RegisterUserDataEnum.STUDY_FUND_DEPOSIT]: [],
        [RegisterUserDataEnum.STUDY_FUND_EMPLOYER_DEPOSIT]: [
          Validators.pattern(this.NumbersOrDecimalsRegex),
          Validators.max(10),
          Validators.min(0),
        ],
        [RegisterUserDataEnum.STUDY_FUND_EMPLOYEE_DEPOSIT]: [
          Validators.pattern(this.NumbersOrDecimalsRegex),
          Validators.max(10),
          Validators.min(0),
        ],
        [RegisterUserDataEnum.HEALTH_COLLECTIVE]: [],
        [RegisterUserDataEnum.FOREIGN_EMPLOYEE]: [],
        [RegisterUserDataEnum.LANGUAGE]: [],
        ["PASSPORT"]: [
          Validators.required,
          Validators.minLength(2),
          Validators.maxLength(40),
        ],
      }[inputName] ?? []
    );
  }

  validateIsraeliId(control: UntypedFormControl) {
    let invalid = false;

    let id = String(control.value).trim();
    if (id.length !== 9 || isNaN(+id || NaN)) {
      invalid = true;
    }
    if (!invalid) {
      id = id.length < 9 ? ("00000000" + id).slice(-9) : id;
      const calculated =
        Array.from(id, Number).reduce((counter, digit, i) => {
          const step = digit * ((i % 2) + 1);
          return counter + (step > 9 ? step - 9 : step);
        }) %
        10 ===
        0;
      invalid = !calculated;
    }

    if (invalid) {
      return { id: true };
    } else {
      return null;
    }
  }

  getFormValidationMessages(inputName: string) {
    return (
      {
        [RegisterUserDataEnum.ID_NUMBER_TYPE]: [
          { type: "required", message: "שדה חובה" },
        ],
        [RegisterUserDataEnum.ID_NUMBER]: [
          { type: "required", message: "שדה חובה" },
          {
            type: "pattern",
            message: this.textService.textSource["ERROR_FIELD_NUMBERS_ONLY"],
          },
          { type: "maxlength", message: "יש להזין לכל היותר 40 תווים" },
          { type: "minlength", message: "יש להזין לפחות 2 תווים" },
          {
            type: "id",
            message: this.textService.textSource["ERROR_FIELD_ID_NUMBER"],
          },
        ],
        [RegisterUserDataEnum.FIRST_NAME]: [
          { type: "required", message: "שדה חובה" },
          {
            type: "pattern",
            message: this.textService.textSource["ERROR_FIELD_CHARS_ONLY"],
          },
          { type: "maxlength", message: "יש להזין לכל היותר 40 תווים" },
          { type: "minlength", message: "יש להזין לפחות 2 תווים" },
        ],
        [RegisterUserDataEnum.LAST_NAME]: [
          { type: "required", message: "שדה חובה" },
          {
            type: "pattern",
            message: this.textService.textSource["ERROR_FIELD_CHARS_ONLY"],
          },
          { type: "maxlength", message: "יש להזין לכל היותר 40 תווים" },
          { type: "minlength", message: "יש להזין לפחות 2 תווים" },
        ],
        [RegisterUserDataEnum.DATE_OF_BIRTH]: [
          { type: "required", message: "שדה חובה" },
          {
            type: "date",
            message: "יש להזין תאריך עבר בפורמט הבא: DD/MM/YYYY",
          },
          {
            type: "matDatepickerMin",
            message: "יש להזין תאריך עבר בפורמט הבא: DD/MM/YYYY",
          },
          {
            type: "matDatepickerFilter",
            message: "יש להזין תאריך עבר בפורמט הבא: DD/MM/YYYY",
          },
          {
            type: "matDatepickerMax",
            message: "יש להזין תאריך עבר בפורמט הבא: DD/MM/YYYY",
          },
        ],
        [RegisterUserDataEnum.GENDER]: [
          { type: "required", message: "שדה חובה" },
        ],
        [RegisterUserDataEnum.FAMILY_STATUS]: [
          { type: "required", message: "שדה חובה" },
        ],
        [RegisterUserDataEnum.CITY]: [
          { type: "required", message: "שדה חובה" },
          { type: "maxlength", message: "יש להזין לכל היותר 40 תווים" },
          { type: "minlength", message: "יש להזין לפחות 2 תווים" },
        ],
        [RegisterUserDataEnum.STREET]: [
          { type: "required", message: "שדה חובה" },
          { type: "maxlength", message: "יש להזין לכל היותר 40 תווים" },
          { type: "minlength", message: "יש להזין לפחות 2 תווים" },
        ],
        [RegisterUserDataEnum.BUILDING_NUMBER]: [
          { type: "required", message: "שדה חובה" },
          { type: "maxlength", message: "יש להזין לכל היותר 8 תווים" },
          { type: "minlength", message: "יש להזין לפחות 2 תווים" },
        ],
        [RegisterUserDataEnum.EMAIL]: [
          { type: "required", message: "שדה חובה" },
          {
            type: "pattern",
            message: this.textService.textSource["ERROR_FIELD_EMAIL"],
          },
          {
            type: "email",
            message: this.textService.textSource["ERROR_FIELD_EMAIL"],
          },
        ],
        [RegisterUserDataEnum.MOBILE_PHONE]: [
          { type: "required", message: "שדה חובה" },
          {
            type: "pattern",
            message: this.textService.textSource["ERROR_FIELD_NUMBERS_ONLY"],
          },
          {
            type: "maxlength",
            message: this.textService.textSource["ERROR_FIELD_PHONE"],
          },
          {
            type: "minlength",
            message: this.textService.textSource["ERROR_FIELD_PHONE"],
          },
        ],
        [RegisterUserDataEnum.EMPLOYEE_NUMBER]: [
          { type: "maxlength", message: "יש להזין לכל היותר 40 תווים" },
          { type: "minlength", message: "יש להזין לפחות 2 תווים" },
          { type: "pattern", message: "יש להזין מספרים בלבד" },
        ],
        [RegisterUserDataEnum.ROLE]: [
          { type: "required", message: "שדה חובה" },
          { type: "maxlength", message: "יש להזין לכל היותר 40 תווים" },
          { type: "minlength", message: "יש להזין לפחות 2 תווים" },
        ],
        [RegisterUserDataEnum.START_WORK_DATE]: [
          { type: "required", message: "שדה חובה" },
          { type: "pattern", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" },
          { type: "date", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" },
        ],
        [RegisterUserDataEnum.SALARY_TYPE]: [
          { type: "required", message: "שדה חובה" },
        ],
        [RegisterUserDataEnum.SALARY]: [
          { type: "required", message: "שדה חובה" },
          { type: "pattern", message: "יש להזין מספרים בלבד" },
          { type: "maxlength", message: "יש להזין לכל היותר 5 תווים" },
        ],
        [RegisterUserDataEnum.MONTHLY_PENSION_SALARY]: [
          { type: "required", message: "שדה חובה" },
          { type: "pattern", message: "יש להזין מספרים בלבד" },
          { type: "maxlength", message: "יש להזין לכל היותר 5 תווים" },
        ],
        [RegisterUserDataEnum.ALLOWANCE_COMPENSATIONS_PERCENTAGE]: [
          { type: "required", message: "שדה חובה" },
          { type: "maxlength", message: "יש להזין לכל היותר 4 תווים" },
          { type: "max", message: "יש להזין מספרים קטנים מ - 100" },
          { type: "pattern", message: "יש להזין מספרים בלבד" },
        ],
        [RegisterUserDataEnum.EMPLOYER_BENEFITS]: [
          { type: "required", message: "שדה חובה" },
          { type: "pattern", message: "יש להזין מספרים בלבד" },
          { type: "max", message: "יש להזין מספרים קטנים מ - 10" },
          { type: "min", message: "יש להזין מספרים גדולים מ - 0" },
        ],
        [RegisterUserDataEnum.EMPLOYEE_BENEFITS]: [
          { type: "required", message: "שדה חובה" },
          { type: "pattern", message: "יש להזין מספרים בלבד" },
          { type: "max", message: "יש להזין מספרים קטנים מ - 10" },
          { type: "min", message: "יש להזין מספרים גדולים מ - 0" },
        ],
        [RegisterUserDataEnum.PARAGRAPH_14]: [],
        [RegisterUserDataEnum.EMPLOYEE_INCAPACITY_TO_WORK]: [
          { type: "required", message: "שדה חובה" },
          { type: "pattern", message: "יש להזין מספרים בלבד" },
          { type: "max", message: "יש להזין מספרים קטנים מ - 10" },
          { type: "min", message: "יש להזין מספרים גדולים מ - 0" },
        ],
        [RegisterUserDataEnum.ELIGIBILITY_STUDY_FUND]: [
          { type: "required", message: "שדה חובה" },
        ],
        [RegisterUserDataEnum.ELIGIBILITY_DATE_STUDY_FUND]: [
          { type: "required", message: "שדה חובה" },
        ],
        [RegisterUserDataEnum.ELIGIBILITY_DATE]: [
          { type: "required", message: "שדה חובה" },
          { type: "pattern", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" },
          { type: "date", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" },
        ],
        [RegisterUserDataEnum.STUDY_FUND_DEPOSIT]: [
          { type: "required", message: "שדה חובה" },
        ],
        [RegisterUserDataEnum.STUDY_FUND_EMPLOYER_DEPOSIT]: [
          { type: "required", message: "שדה חובה" },
          { type: "pattern", message: "יש להזין מספרים בלבד" },
          { type: "max", message: "יש להזין מספרים קטנים מ - 10" },
          { type: "min", message: "יש להזין מספרים גדולים מ - 0" },
        ],
        [RegisterUserDataEnum.STUDY_FUND_EMPLOYEE_DEPOSIT]: [
          { type: "required", message: "שדה חובה" },
          { type: "pattern", message: "יש להזין מספרים בלבד" },
          { type: "max", message: "יש להזין מספרים קטנים מ - 10" },
          { type: "min", message: "יש להזין מספרים גדולים מ - 0" },
        ],
        [RegisterUserDataEnum.HEALTH_COLLECTIVE]: [
          { type: "required", message: "שדה חובה" },
        ],
        [RegisterUserDataEnum.FOREIGN_EMPLOYEE]: [
          { type: "required", message: "שדה חובה" },
        ],
        [RegisterUserDataEnum.LANGUAGE]: [
          { type: "required", message: "שדה חובה" },
        ],
        [WorkTerminationDataEnum.START_WORK_DATE]: [
          { type: "required", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" },
          { type: "pattern", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" },
          { type: "date", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" },
        ],
        [WorkTerminationDataEnum.END_WORK_DATE]: [
          { type: "required", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" },
          { type: "pattern", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" },
          { type: "date", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" },
        ],
        [WorkTerminationDataEnum.LAST_MONTHLY_SALARY]: [
          { type: "required", message: "שדה חובה" },
          { type: "pattern", message: "יש להזין מספרים בלבד" }
        ],
        [WorkTerminationDataEnum.LAST_INSURED_SALARY]: [
          { type: "required", message: "שדה חובה" },
          { type: "pattern", message: "יש להזין מספרים בלבד" }
        ],
        [WorkTerminationDataEnum.LAST_PREMIUM_DATE]: [
          { type: "date", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" },
        ],
        [WorkTerminationDataEnum.SALARY_COMPENSATION_LIABILITY]: [
          { type: "pattern", message: "יש להזין מספרים בלבד" },
          { type: "required", message: "שדה חובה" }
        ],
        [WorkTerminationDataEnum.LAST_SALARY_PERIOD]: [
          { type: "pattern", message: "יש להזין מספרים בלבד" },
          { type: "maxlength", message: "יש להזין לכל היותר 6 תווים" },
        ],
        [WorkTerminationDataEnum.NUM_HOUSE]: [{ type: "pattern", message: "יש להזין מספרים בלבד" }],
        [WorkTerminationDataEnum.BUILDING_NUMBER]: [{ type: "pattern", message: "יש להזין מספרים בלבד" },],
        [WorkTerminationDataEnum.POSTAL_CODE]: [{ type: "pattern", message: "יש להזין מספרים בלבד" }],
        [WorkTerminationDataEnum.BIRTHDATE]: [{ type: "required", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" }],
        [WorkTerminationDataEnum.DEDUCTIBLE_TAX_RATED]: [{ type: "pattern", message: "יש להזין מספרים בלבד" }],
        [WorkTerminationDataEnum.LAST_PREMIUM]: [{ type: "pattern", message: "יש להזין מספרים בלבד" }],
        [WorkTerminationDataEnum.DEDUCTIONS_PORTFOLIO]: [{ type: "required", message: "שדה חובה" }, { type: "pattern", message: "יש להזין מספרים בלבד" }],
        [WorkTerminationDataEnum.PERSONAL_EMAIL]: [
          { type: "required", message: "שדה חובה" },
          {
            type: "pattern",
            message: this.textService.textSource["ERROR_FIELD_EMAIL"]
          }
        ],
        [WorkTerminationDataEnum.MOBILE_PHONE]: [{ type: "required", message: "שדה חובה" }, { type: "pattern", message: "יש להזין מספר טלפון תקין" }],
        [WorkTerminationDataEnum.ZAKAAOT_DATE]: [{ type: "date", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" }],
        [WorkTerminationDataEnum.ZAKAAOT_SALARY]: [{ type: "pattern", message: "יש להזין מספרים בלבד" }],
        [WorkTerminationDataEnum.JOB_RATE_PERCENT]: [
          { type: "pattern", message: "יש להזין מספרים בלבד" },
          { type: "maxlength", message: "יש להזין לכל היותר 5 תווים" },
        ],
        [WorkTerminationDataEnum.PART_TIME_SALARY_REDUCTION_END_DATE]: [
          { type: "date", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" }
        ],
        [WorkTerminationDataEnum.PART_TIME_SALARY_REDUCTION_START_DATE]: [
          { type: "date", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" }
        ],
        [WorkTerminationDataEnum.TAXED_PAYMENTS]: [
          { type: "pattern", message: "יש להזין מספרים בלבד" }
        ],
        [WorkTerminationDataEnum.PAYMENT_DATE]: [
          { type: "date", message: "יש להזין תאריך בפורמט הבא: DD/MM/YYYY" }
        ],
        [WorkTerminationDataEnum.PAYMENT_SUM]: [
          { type: "pattern", message: "יש להזין מספרים בלבד" }
        ]
      }[inputName] ?? []
    );
  }

}
