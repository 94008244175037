import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { TextService } from "../../../services/text.service";
import { NgIf } from "@angular/common";
@Component({
    selector: "app-appointment-type-component",
    templateUrl: "./appointment-type-component.component.html",
    styleUrls: ["./appointment-type-component.component.scss"],
    standalone: true,
    imports: [NgIf]
})
export class AppointmentTypeComponentComponent implements OnInit {
  @Input() data;

  contact_name;
  contact_number_id;
  meeting_status;
  meeting_date;
  meeting_method;
  status;
  meeting_description;
  assigned_to;
  request_number;
  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    this.contact_name = this.dataService.getResourceText(
      this.textService.textSource["MEETINGS_SCREEN_ITEM_LABEL_1"],
      this.data
    );
    this.contact_number_id = this.dataService.getResourceText(
      this.textService.textSource["MEETINGS_SCREEN_ITEM_LABEL_2"],
      this.data
    );
    this.meeting_status = this.dataService.getResourceText(
      this.textService.textSource["MEETINGS_SCREEN_ITEM_LABEL_8"],
      this.data
    );
    this.meeting_date = this.dataService.getResourceText(
      this.textService.textSource["MEETINGS_SCREEN_ITEM_LABEL_7"],
      this.data
    );
    this.meeting_method = this.dataService.getResourceText(
      this.textService.textSource["MEETINGS_SCREEN_ITEM_LABEL_6"],
      this.data
    );
    this.status = this.dataService.getResourceText(
      this.textService.textSource["MEETINGS_SCREEN_ITEM_LABEL_5"],
      this.data
    );
    this.meeting_description = this.dataService.getResourceText(
      this.textService.textSource["MEETINGS_SCREEN_ITEM_LABEL_9"],
      this.data
    );
    this.assigned_to = this.dataService.getResourceText(
      this.textService.textSource["MEETINGS_SCREEN_ITEM_LABEL_3"],
      this.data
    );
    this.request_number = this.dataService.getResourceText(
      this.textService.textSource["MEETINGS_SCREEN_ITEM_LABEL_4"],
      this.data
    );
  }
}
