import { Injectable } from '@angular/core';
import { HttpHandler } from './httpHandler';
import { HttpClient } from '@angular/common/http';
import { HttpService } from './http.service';
import { ErrorService } from './error.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AllowedClassifiedInformationService extends HttpHandler {

  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
  ) {
    super(_http, httpService, errorService);
  }
  private _associatePersonallowedClassified = new BehaviorSubject<boolean>(null);

  async getAssociatePersonAllowedClassified() {
    const url = "/contact/person-allowed-classified-information";
    try {
      const { isAllowed } = await this.get(url).toPromise();
      this._associatePersonallowedClassified.next(isAllowed);
    } catch (error) {
      // couldnt get
    }
  }

  getAssociatePersonAllowedClassifiedObs() {
    return this._associatePersonallowedClassified.asObservable();
  }

  getAssociatePersonAllowedClassifiedValue() {
    return this._associatePersonallowedClassified.value;
  }

}
