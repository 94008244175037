import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DataService } from "../../../services/data.service";
import { EmployeeService } from "src/app/services/employee.service";
import { TextService } from "../../../services/text.service";
import { Subject, Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, switchMap } from "rxjs/operators";
import { SearchService, ISearchable } from "src/app/services/search.service";
import { element } from "protractor";
import { MatLegacyPaginator as MatPaginator, MatLegacyPaginatorModule } from "@angular/material/legacy-paginator";
import { MyEmployeesTableComponent } from "../../tables/my-employees-table/my-employees-table.component";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { NgIf, NgFor } from "@angular/common";

declare var $;
@Component({
    selector: "app-regular-worker",
    templateUrl: "./regular-worker.component.html",
    styleUrls: ["./regular-worker.component.scss"],
    standalone: true,
    imports: [NgIf, MatLegacyFormFieldModule, MatLegacyInputModule, ReactiveFormsModule, FormsModule, MatLegacySelectModule, NgFor, MatLegacyOptionModule, MyEmployeesTableComponent, MatLegacyPaginatorModule]
})
export class RegularWorkerComponent implements OnInit, ISearchable, OnChanges {
  @Input() regularWorker: boolean;
  @Input() showAllWorkers: boolean;
  @ViewChild("paginator") paginator: MatPaginator;
  data: any = [];
  search_text = new Subject<string>();
  sizeDataArray = 3;
  searchingWorkersRequestsArray = [];
  paginatorLength;
  showGhost = false;
  showGhostForEmployeesCards = false;
  currentItemsToShow = [];
  activeFilterSelected;
  factorFilterSelected;
  optionsActiveFilter;
  optionsFactorFilter;
  textModel;
  constructor(
    public textService: TextService,
    public dataService: DataService,
    public employeeService: EmployeeService,
    private searchService: SearchService
  ) {
    this.textService.setTextSource();
    this.searchService.search(this).subscribe(
      (resp) => {
        this.onSearchResponse(resp);
      },
      (err) => {
        this.employeeService.employeeCardsGhostElements = false;
        this.dataService.showDataLoader = false;
        this.dataService.handleErrors(err);
      }
    );
    this.optionsFactorFilter = [
      this.textService.textSource.MY_EMPLOYEES_SCREEN_FACTOR_FILTER_0,
      this.textService.textSource.MY_EMPLOYEES_SCREEN_FACTOR_FILTER_1,
      this.textService.textSource.MY_EMPLOYEES_SCREEN_FACTOR_FILTER_2,
      this.textService.textSource.MY_EMPLOYEES_SCREEN_FACTOR_FILTER_3,
    ];
    this.factorFilterSelected = this.optionsFactorFilter[0];
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.regularWorker?.firstChange ||
      !changes.showAllWorkers?.firstChange
    ) {
      this.showGhost = true;
      this.searchByFilter({ label: "הכל", value: null });
    }
  }

  //ISearchable interface implementation
  textObserver(): Observable<string> {
    //represent the text field key up observer
    return this.search_text.asObservable();
  }
  //represent the endpoint for the search request
  endpoint: string = "/contact/employees";
  //represent the params for the search request
  paramsObj = { skip: 0, limit: 10 };
  //threshold to start sending request
  threshold: number = 3;
  //what to perform while searching
  onSearch() {
    this.employeeService.employeeCardsGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
  }

  ngOnInit() {
    this.showGhost = true;
    this.searchByFilter({ label: "פעיל", value: true });
  }

  onSearchResponse(resp: any) {
    this.showGhost = false;
    this.employeeService.employeeCardsGhostElements = false;
    this.dataService.showDataLoader = false;
    this.paginatorLength = resp["pagination"]["total_count"];
    this.resetPaginatorIndex();
    this.data = resp["employees"];
    this.optionsActiveFilter = resp["filter"];
    this.activeFilterSelected = this.optionsActiveFilter[0];
    this.sizeDataArray = this.data.length;
  }

  async onPageChange($event) {
    let indexStart = $event.pageIndex * $event.pageSize;
    this.employeeService.employeeCardsGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }

    (
      await this.employeeService.getRegularWorker(
        indexStart,
        10,
        this.textModel,
        this.activeFilterSelected["value"],
        this.factorFilterSelected,
        this.regularWorker,
        this.showAllWorkers
      )
    ).subscribe(
      (resp) => {
        this.employeeService.employeeCardsGhostElements = false;
        this.dataService.showDataLoader = false;
        this.data = resp["employees"];
      },
      (err) => {
        this.employeeService.employeeCardsGhostElements = false;
        this.dataService.showDataLoader = false;
        this.dataService.handleErrors(err);
      }
    );
  }

  searchByFilter(chosenFilter) {
    this.employeeService.employeeCardsGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.textModel = "";
    this.employeeService
      .getRegularWorker(
        0,
        10,
        "",
        chosenFilter["value"],
        this.factorFilterSelected,
        this.regularWorker,
        this.showAllWorkers
      )
      .subscribe(
        (resp) => {
          this.employeeService.employeeCardsGhostElements = false;
          this.dataService.showDataLoader = false;
          this.showGhost = false;
          this.data = resp["employees"];
          this.optionsActiveFilter = resp["filter"];
          this.paginatorLength = resp["pagination"]["total_count"];
          this.resetPaginatorIndex();
          this.activeFilterSelected = this.optionsActiveFilter.filter(
            (element) => {
              return element.value === chosenFilter.value;
            }
          )[0];
        },
        (err) => {
          this.employeeService.employeeCardsGhostElements = false;
          this.dataService.showDataLoader = false;
          this.dataService.handleErrors(err);
        }
      );
  }

  resetPaginatorIndex() {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
  }
}
