import { Component, Input } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { EmployeeService } from "src/app/services/employee.service";
import { BidiModule } from "@angular/cdk/bidi";
import { NgIf, NgFor, NgClass } from "@angular/common";

@Component({
    selector: "app-my-employees-table",
    templateUrl: "./my-employees-table.component.html",
    styleUrls: ["./my-employees-table.component.scss"],
    standalone: true,
    imports: [NgIf, BidiModule, NgFor, NgClass]
})
export class MyEmployeesTableComponent {
  @Input() data;

  constructor(
    public dataService: DataService,
    private employeeService: EmployeeService,
    private router: Router
  ) {}

  goToEmployeeCard(employment_id) {
    this.employeeService.employee_id = employment_id;
    this.router.navigate(["/employee-card", employment_id]);
  }
}
