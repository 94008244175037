
import { Location, NgClass, NgIf } from "@angular/common";
import { AfterViewChecked, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from "@angular/core";
import {
  ITableData,
  TabValue,
} from "src/app/models/import-new-employees.model";
import { PopupTypes } from "src/app/models/popuptype";
import { DataService } from "src/app/services/data.service";
import { FileUploadService } from "src/app/services/file-upload.service";
import { ModalService } from "src/app/services/modal.service";
import { RegisterPageService } from "src/app/services/register-page.service";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
import { LoaderComponent } from "../../common/loader/loader.component";
import { RegisteredImportedEmployeesSuccessComponent } from "../../modals/registered-imported-employees-success/registered-imported-employees-success.component";
import { GeneralPopupComponent } from "../../common/general-popup/general-popup.component";
import { ToasterContainerComponent } from "../../common/toaster-container/toaster-container.component";
import { ButtonComponent } from "../../common/button/button.component";
import { ImportedEmployeesTableComponent } from "../../tables/imported-employees-table/imported-employees-table.component";
import { LogoComponent } from "../../common/logo/logo.component";
import { BreadcrumbsComponent } from "../../common/breadcrumbs/breadcrumbs.component";
import { TerminateEmployeesWorkTableComponent } from "../../tables/terminate-employees-work-table/terminate-employees-work-table.component";
import { IWorkTerminationRequestBody, WorkTerminationDataEnum } from "src/app/models/work-termination.model";
import { NewAppointmentService } from "src/app/services/new-appointment.service";
import { EmployeeService } from "src/app/services/employee.service";
import { Subscription } from "rxjs";

enum DefaulOptionsEnum {
  YES = "כן",
  NO = "לא",
}

@Component({
  selector: 'app-import-employees-work-termination',
  templateUrl: './import-employees-work-termination.component.html',
  styleUrls: ['./import-employees-work-termination.component.scss'],
  standalone: true,
  imports: [BreadcrumbsComponent, LogoComponent, NgClass, NgIf, ImportedEmployeesTableComponent, ButtonComponent, ToasterContainerComponent, GeneralPopupComponent, RegisteredImportedEmployeesSuccessComponent, LoaderComponent, TerminateEmployeesWorkTableComponent],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ImportEmployeesWorkTerminationComponent implements OnInit, OnDestroy {
  tabValue = TabValue;
  activeTab: TabValue = this.tabValue.INCORRECT;
  tableData: ITableData;
  activeData: Array<{}>;
  error = PopupTypes.error;
  newAppointment = PopupTypes.newAppointment;

  err_message: string;
  modals: {};
  breadcrumbsPages = [
    BreadcrumbsLinkNames.HOME,
    BreadcrumbsLinkNames.IMPORT_EMPLOYEES_WORK_TERMINATION,
  ];
  parsedFileSub: Subscription;
  wasBuild = false;
  sendRecords = false;
  options;
  constructor(
    public modalService: ModalService,
    private fileUploadService: FileUploadService,
    public dataService: DataService,
    private registerPageService: RegisterPageService,
    private location: Location,
    private cdr: ChangeDetectorRef,
    private newAppointmentService: NewAppointmentService, private employeeService: EmployeeService
  ) { }
  ngOnDestroy(): void {
    this.fileUploadService.setAllowUploadNewEmployeesPageAccess(false);
    this.parsedFileSub.unsubscribe();
  }

  ngOnInit(): void {
    this.parsedFileSub = this.fileUploadService.getParsedFileResponse().subscribe((data) => {
      !this.wasBuild ? this.initDataTable(data) : null;
      this.tableData = data;
      this.tableData.incorrect.length > 0
        ? this.clickTab(this.tabValue.INCORRECT)
        : this.clickTab(this.tabValue.CORRECT);
    });

    this.modalService.getModals().subscribe((modals) => {
      this.modals = modals;
    });
  }

  clickTab(chosenTab: TabValue) {
    this.activeTab = chosenTab;
    this.activeData =
      chosenTab === TabValue.CORRECT
        ? this.tableData.correct
        : this.tableData.incorrect;
  }

  async submit() {
    this.dataService.showDataLoader = true;
    const correct = this.tableData.correct;
    const employees = correct.map((employee) => {
      const body = this.newAppointmentService.generateWorkTerminationRequestBody(
        employee as IWorkTerminationRequestBody
      );
      return body;
    });
    try {
      this.sendRecords = true;
      await this.registerPageService.createEmployeesWorkTermination(employees).toPromise();
      await this.fileUploadService.sendFileAttachment();
      this.modalService.openModal("registeredSuccessfully");
   
    } catch (error) {
      await this.fileUploadService.sendFileAttachment();
      this.dataService.showErrorPopup = true;
    }
    finally {
      this.dataService.showDataLoader = false;
      this.fileUploadService.removeFile();
      this.cdr.detectChanges();
    }
  }
  goBack() {
    this.location.back();
  }

  buildData(data: any) {
    const partTimeSalaryReductionPeriods = this.getDataArray(5, [
      WorkTerminationDataEnum.PART_TIME_SALARY_REDUCTION_START_DATE,
      WorkTerminationDataEnum.PART_TIME_SALARY_REDUCTION_END_DATE,
      WorkTerminationDataEnum.JOB_RATE_PERCENT,
      WorkTerminationDataEnum.LAST_SALARY_PERIOD
    ], data);
    const providentMoneyArray = this.getDataArray(3, [
      WorkTerminationDataEnum.PAYER_NAME,
      WorkTerminationDataEnum.DEDUCTIONS_PORTFOLIO,
      WorkTerminationDataEnum.TAXED_PAYMENTS
    ], data);
    const retirementGrantPaymentRaterArray = this.getDataArray(3, [
      WorkTerminationDataEnum.PAYMENT_DATE,
      WorkTerminationDataEnum.PAYMENT_SUM
    ], data);
    data.partTimeSalaryReductionPeriods = partTimeSalaryReductionPeriods;
    data.providentMoneyArray = providentMoneyArray;
    data.retirementGrantPaymentRaterArray = retirementGrantPaymentRaterArray;
    data.section14 = data.section14 == DefaulOptionsEnum.YES ? true : false;
    data.compensationRelease = data.compensationRelease == DefaulOptionsEnum.YES ? true : false;
    data.entitlementBudgetPension = data.entitlementBudgetPension == DefaulOptionsEnum.YES ? true : false;
    data.controllingOwner = data.controllingOwner != "" ? (data.controllingOwner == 'אני בעל שליטה' ? "1" : "2") : null;
    data.workTerminationReason = this.options["work_termination_reason"].find(x =>
      x.label === data.workTerminationReason
    )?.value;
    if (!(/^[0-9.]+$/).test(data.lastPremia)) {
      data.comments = data.lastPremia;
      delete data.lastPremia;
    }
    data.wasBuild = true;
  }

  getDataArray(count, keys, data) {
    const dataArray = [];
    for (let i = 0; i < count; i++) {
      const dataObject = {};

      let isEmpty = true;

      for (const key of keys) {
        const dataKey = key + i;
        const value = data[dataKey];
        dataObject[key] = value;
        if (typeof value == 'object') {
          dataObject[key] = value.value;
        }
        if (value !== '') {
          isEmpty = false;
        }
        delete data[dataKey];
      }
      if (!isEmpty) {
        dataArray.push(dataObject);
      }
    }
    if (dataArray.length === 0) {
      const emptyObject = keys.reduce((obj, key) => {
        obj[key] = '';
        return obj;
      }, {});
      dataArray.push(emptyObject);
    }

    return dataArray;
  }

  initDataTable(data) {
    this.dataService.showDataLoader = true;
    this.registerPageService.getWorkTerminationReasons().subscribe(
      options => {
        this.dataService.showDataLoader = false;
        this.cdr.detectChanges();
        this.options = options;
        data.correct.forEach((user) => {
          this.buildData(user);
        });
        data.incorrect.forEach((user) => {
          this.buildData(user);
        });
        this.wasBuild = true;
      }
    )
  }
}
