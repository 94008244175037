import { Component, OnInit, Input } from "@angular/core";
import {
  FeedbackStatsModel,
  DateRange,
} from "src/app/models/feedback.stats.model";
import { DataService } from "src/app/services/data.service";
import { FeedbackService } from "src/app/services/feedback.service";
import { TextService } from "src/app/services/text.service";
import { MatLegacyPaginatorModule } from "@angular/material/legacy-paginator";
import { EmployersInterfaceCardComponent } from "../employers-interface-card/employers-interface-card.component";
import { NgIf, NgFor } from "@angular/common";
import { EmployersInterfaceTypeDetailsComponent } from "../employers-interface-type-details/employers-interface-type-details.component";
import { BidiModule } from "@angular/cdk/bidi";

@Component({
    selector: "app-employee-card-feedback",
    templateUrl: "./employee-card-feedback.component.html",
    styleUrls: ["./employee-card-feedback.component.scss"],
    standalone: true,
    imports: [BidiModule, EmployersInterfaceTypeDetailsComponent, NgIf, NgFor, EmployersInterfaceCardComponent, MatLegacyPaginatorModule]
})
export class EmployeeCardFeedbackComponent implements OnInit {
  @Input() employmentId;
  total_related_money;
  total_unrelated_money;
  total_money_sent;
  search_text: string;
  feedbackStatsData: FeedbackStatsModel;
  pieFeedbackData: any;
  selectedType: string;
  selectedDates: DateRange = {
    from: "2019-01-30",
    to: "2020-06-30",
  };
  status_title = "";
  absorption_rate = "";
  showCardGhost: boolean;
  paginatorLength;
  constructor(
    public textService: TextService,
    public dataService: DataService,
    public feedbackService: FeedbackService
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    this.dataService.showHomePie = false;
    this.selectedType = "היזון חוזר כללי";
    this.getStats({
      // from: this.selectedDates.from,//delete
      // to:  this.selectedDates.to,//delete
      type: this.selectedType,
    });
  }

  getStats(query) {
    this.dataService.showGhostElements = true;
    this.feedbackService
      .getFeedbackStatsByID(this.employmentId, query)
      .subscribe((res) => {
        this.feedbackStatsData = res;
        this.absorption_rate = this.dataService.getResourceText(
          this.textService.textSource[
            "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_LABEL_4"
          ],
          this.feedbackStatsData
        );
        this.total_related_money = this.dataService.getResourceText(
          this.textService.textSource[
            "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_VALUE_2"
          ],
          this.feedbackStatsData
        );
        this.total_unrelated_money = this.dataService.getResourceText(
          this.textService.textSource[
            "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_VALUE_3"
          ],
          this.feedbackStatsData
        );
        this.total_money_sent = this.dataService.getResourceText(
          this.textService.textSource[
            "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_VALUE_1"
          ],
          this.feedbackStatsData
        );

        console.log(res);

        this.dataService.showGhostElements = false;
        setTimeout(() => {
          this.dataService.dataArrived.next();
          this.feedbackService.createChart.next();
        }, 0);
      });
  }

  datesChanged(dates) {
    this.pieFeedbackData = null;
    this.paginatorLength = 0;
    this.selectedDates = dates;
    let data = {
      from: dates.from,
      to: dates.to,
      type: this.selectedType,
    };
    this.getStats(data);
  }

  getPieDetails(status) {
    //first function after clicking on pie
    this.status_title = status;
    this.showCardGhost = true;
    if (!status) {
      return;
    }
    this.filterEmitted(0);
  }

  getSelectedPieData(data) {
    //click on pie part - server request

    this.feedbackService
      .getFeedbackByID(this.employmentId, data)
      .subscribe((res) => {
        this.pieFeedbackData = res;
        this.paginatorLength =
          this.pieFeedbackData["pagination"]["total_count"];
        console.log(res);

        this.showCardGhost = false;
      });
  }

  filterEmitted(skip) {
    //click on pie part - collect data for the server request
    this.showCardGhost = true;

    let data = {
      skip: skip,
      limit: 10,
      from: this.selectedDates.from,
      to: this.selectedDates.to,
      type: this.selectedType,
      status: this.status_title,
    };

    this.getSelectedPieData(data);
  }
  onPageChange($event) {
    let startIndex = $event.pageIndex * $event.pageSize;
    this.filterEmitted(startIndex);
  }
}
