import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { EmployeeService } from "src/app/services/employee.service";
import { ActivatedRoute } from "@angular/router";
import { TextService } from "../../../services/text.service";
import { ISearchable, SearchService } from "src/app/services/search.service";
import { Observable, Subject } from "rxjs";
import { MatLegacyPaginatorModule } from "@angular/material/legacy-paginator";
import { ForeignComponentComponent } from "../../generic-components/foreign-component/foreign-component.component";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { NgIf, NgFor } from "@angular/common";
declare var $;
@Component({
    selector: "app-foreign-worker",
    templateUrl: "./foreign-worker.component.html",
    styleUrls: ["./foreign-worker.component.scss"],
    standalone: true,
    imports: [NgIf, MatLegacyFormFieldModule, MatLegacyInputModule, ReactiveFormsModule, FormsModule, NgFor, ForeignComponentComponent, MatLegacyPaginatorModule]
})
export class ForeignWorkerComponent implements OnInit, ISearchable {
  constructor(
    public searchService: SearchService,
    public textService: TextService,
    private route: ActivatedRoute,
    public dataService: DataService,
    private employeeService: EmployeeService
  ) {
    this.textService.setTextSource();
    this.searchService.search(this).subscribe(
      (resp) => {
        this.onSearchResponse(resp);
      },
      (err) => {
        this.employeeService.employeeCardsGhostElements = false;
        this.dataService.showDataLoader = false;
        this.dataService.handleErrors(err);
      }
    );
  }
  data: any = ["", "", "", "", "", "", "", ""];
  search_text = new Subject<string>();
  textModel;
  sizeDataArray = 8;
  showGhost = false;
  @Input() paginatorLength = this.route.snapshot.paramMap.get("number")
    ? this.route.snapshot.paramMap.get("number")
    : 500;
  searchingWorkersRequestsArray = [];

  //ISearchable interface implementation
  endpoint: string = "/contact/policy/foreign-employee";
  threshold: number = 3;
  paramsObj: { skip: 0; limit: 10 };
  onSearch(): void {
    this.employeeService.employeeCardsGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
  }
  textObserver(): Observable<string> {
    //represent the text field key up observer
    return this.search_text.asObservable();
  }
  ngOnInit(): void {
    this.showGhost = true;
    this.employeeService.employeeCardsGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.employeeService.getForeignWorker(0, 10, "").subscribe(
      (resp) => {
        this.showGhost = false;
        this.dataService.showDataLoader = false;
        this.employeeService.employeeCardsGhostElements = false;
        this.data = resp.foreign_employee_contacts;
        this.paginatorLength = resp["pagination"]["total_count"];
        this.sizeDataArray = this.data.length;
      },
      (err) => {
        this.dataService.handleErrors(err);
      }
    );
  }
  onSearchResponse(resp: any) {
    this.employeeService.employeeCardsGhostElements =
      this.searchingWorkersRequestsArray.length > 0;
    this.dataService.showDataLoader = false;
    this.data = resp.foreign_employee_contacts;
    this.sizeDataArray = this.data.length;
    this.paginatorLength = resp["pagination"]["total_count"];
  }

  onPageChange($event) {
    let indexStart = $event.pageIndex * $event.pageSize;
    this.employeeService.employeeCardsGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.employeeService
      .getForeignWorker(indexStart, 10, this.textModel)
      .subscribe(
        (resp) => {
          this.employeeService.employeeCardsGhostElements = false;
          this.dataService.showDataLoader = false;
          this.data = resp.foreign_employee_contacts;
          setTimeout(() => {
            $(".workers-list").focus();
            window.scroll(0, 0);
          }, 100);
        },
        (err) => {
          this.employeeService.employeeCardsGhostElements = false;
          this.dataService.handleErrors(err);
        }
      );
  }
}
