import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl, ReactiveFormsModule } from "@angular/forms";
import {
  ISelectOption,
  IValidationMessage,
} from "src/app/models/import-new-employees.model";
import { FormValidatorsService } from "src/app/services/form-validators.service";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { NgFor, NgIf } from "@angular/common";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";

@Component({
    selector: "app-select",
    templateUrl: "./select.component.html",
    styleUrls: ["./select.component.scss"],
    standalone: true,
    imports: [MatLegacyFormFieldModule, MatLegacySelectModule, ReactiveFormsModule, NgFor, MatLegacyOptionModule, NgIf]
})
export class SelectComponent implements OnInit {
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() label: string = "";
  @Input() options: ISelectOption[] = [];
  @Input() controlValidationName: string = "";
  @Input() optionLabelAsValue: boolean = true;
  validation_messages: IValidationMessage[] = [];

  constructor(private formValidatorsService: FormValidatorsService) {}

  ngOnInit(): void {
    this.validation_messages =
      this.formValidatorsService.getFormValidationMessages(
        this.controlValidationName
      );
  }
}
