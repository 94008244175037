import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import * as moment from "moment";
import { TextService } from "../../../services/text.service";
import { Router, ActivatedRoute } from "@angular/router";
import { TasksService } from "../../../services/tasks.service";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { APP_DATE_FORMATS } from "../../../format-datepicker";
//import { MAT_DATE_LOCALE } from "saturn-datepicker";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import { ISearchable, SearchService } from "src/app/services/search.service";
import { Subject } from "rxjs";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
import { NoContentComponent } from "../../common/no-content/no-content.component";
import { MatLegacyPaginatorModule } from "@angular/material/legacy-paginator";
import { TerminationWorkTableComponent } from "../../tables/termination-work-table/termination-work-table.component";
import { NewEmployeesWelcomeTableComponent } from "../../tables/new-employees-welcome-table/new-employees-welcome-table.component";
import { BidiModule } from "@angular/cdk/bidi";
import { TypeTaskComponentComponent } from "../../generic-components/type-task-component/type-task-component.component";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { HeaderComponent } from "../../common/header/header.component";
import { BreadcrumbsComponent } from "../../common/breadcrumbs/breadcrumbs.component";
import { NgIf, NgFor, NgClass } from "@angular/common";
declare var $: any;
@Component({
    selector: "app-type-task",
    templateUrl: "./type-task.component.html",
    styleUrls: ["./type-task.component.scss"],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    ],
    standalone: true,
    imports: [NgIf, BreadcrumbsComponent, HeaderComponent, MatLegacyFormFieldModule, MatLegacySelectModule, ReactiveFormsModule, FormsModule, NgFor, MatLegacyOptionModule, NgClass, MatLegacyInputModule, TypeTaskComponentComponent, BidiModule, NewEmployeesWelcomeTableComponent, TerminationWorkTableComponent, MatLegacyPaginatorModule, NoContentComponent]
})
export class TypeTaskComponent implements OnInit, ISearchable {
  typeName = "הזמנת טופס צבירות";
  pageWidth = window.innerWidth;
  name;
  total_count = "סך משימות: 20";
  total_scheduled = "בוצעו: 20";
  total_held = "טרם בוצעו: 20";
  selectedStatus;
  selectedTime;
  search_text = new Subject<string>();
  textModel;
  data = [];
  sizeDataArray = 6;
  searchingWorkersRequestsArray = [];
  datePicker: any;
  dateInput = { from: undefined, to: undefined };
  timeOptionForFilter = [
    { label: "שנה נוכחית", value: "" },
    { label: "רבעון קודם", value: "" },
    { label: "חודש נוכחי", value: "" },
  ];
  statusOptionForFilter;
  paginatorLength;
  showGhostElementsTopPage = false;
  breadcrumbsPages: Array<any>;

  //ISearchable interface implementation
  endpoint: string = "/contact/missions";
  threshold: number = 3;
  paramsObj: { skip: 0; limit: 10; type: string };
  onSearch(): void {
    this.dataService.showGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
  }
  textObserver(): import("rxjs").Observable<string> {
    //represent the text field key up observer
    return this.search_text.asObservable();
  }
  constructor(
    public dataService: DataService,
    public tasksService: TasksService,
    public textService: TextService,
    public router: Router,
    private route: ActivatedRoute,
    public searchService: SearchService
  ) {
    this.textService.setTextSource();
    this.searchService.search(this).subscribe(
      (resp) => {
        this.onSearchResponse(resp);
      },
      (err) => {
        this.showGhostElementsTopPage = false;
        this.dataService.showDataLoader = false;
        this.dataService.showGhostElements = false;
        this.dataService.handleErrors(err);
      }
    );
  }

  ngOnInit(): void {
    this.name = this.dataService.name;
    this.typeName = this.route.snapshot.paramMap.get("name");
    this.breadcrumbsPages = [
      BreadcrumbsLinkNames.HOME,
      BreadcrumbsLinkNames.MY_EMPLOYEES,
      BreadcrumbsLinkNames.TASKS,
      this.typeName,
    ];
    this.paramsObj = { skip: 0, limit: 10, type: this.typeName };
    let periodTime = sessionStorage.getItem("task-filter");
    this.dateInput.from = moment(
      sessionStorage.getItem("task-from-date"),
      "DD/MM/YYYY"
    );
    this.dateInput.to = moment(
      sessionStorage.getItem("task-to-date"),
      "DD/MM/YYYY"
    );
    $(".date-input").val(
      this.dateInput.to.format("DD/MM/YY") +
        " - " +
        this.dateInput.from.format("DD/MM/YY")
    );
    if (periodTime != "") {
      $(".date-input").prop("disabled", true);
    } else {
      $(".date-input").prop("disabled", false);
    }
    this.showGhostElementsTopPage = true;
    this.tasksService.getCountsType(this.typeName, moment(this.dateInput.from).format("YYYY-MM-DD"),
      moment(this.dateInput.to).format("YYYY-MM-DD"),
      periodTime).subscribe(
        (resp) => {
          this.total_count = this.dataService.getResourceText(
            this.textService.textSource["MISSIONS_TYPE_SCREEN_SUBTITLE_1"],
            resp
          );
          this.total_held = this.dataService.getResourceText(
            this.textService.textSource["MISSIONS_TYPE_SCREEN_SUBTITLE_2"],
            resp
          );
          this.total_scheduled = this.dataService.getResourceText(
            this.textService.textSource["MISSIONS_TYPE_SCREEN_SUBTITLE_3"],
            resp
          );
        },
        (err) => {
          this.dataService.handleErrors(err);
        }
      );
    this.tasksService
      .getAllTasksByTypeByFilter(
        this.typeName,
        moment(this.dateInput.from).format("YYYY-MM-DD"),
        moment(this.dateInput.to).format("YYYY-MM-DD"),
        periodTime,
        "",
        "",
        0,
        10
      )
      .subscribe(
        (resp) => {
          this.dataService.showGhostElements = false;
          this.showGhostElementsTopPage = false;
          this.dataService.showDataLoader = false;

          this.dateInput.from = moment(
            sessionStorage.getItem("task-from-date"),
            "DD/MM/YYYY"
          );
          this.dateInput.to = moment(
            sessionStorage.getItem("task-to-date"),
            "DD/MM/YYYY"
          );
          $(".date-input").val(
            this.dateInput.to.format("DD/MM/YY") +
              " - " +
              this.dateInput.from.format("DD/MM/YY")
          );

          this.statusOptionForFilter = resp["status_filter"];
          this.timeOptionForFilter = resp["date_filter"];
          this.selectedStatus = this.statusOptionForFilter[0];
          this.timeOptionForFilter.forEach((element) => {
            if (element["value"] === periodTime) {
              this.selectedTime = element;
            }
          });
          this.data = resp["missions"];
          this.sizeDataArray = this.data.length;
          this.paginatorLength = resp["pagination"]["total_count"];
          this.sizeDataArray = this.data.length;
        },
        (err) => {
          this.dataService.handleErrors(err);
        }
      );
  }
  ngAfterContentInit(): void {
    this.datePicker = $(".date-input").daterangepicker(
      {
        autoApply: true,
        autoUpdateInput: false,
        opens: "center",

        locale: {
          format: "DD/MM/YYYY",
          separator: " - ",
          applyLabel: "אישור",
          cancelLabel: "ביטול",
          fromLabel: "מתאריך",
          toLabel: "עד תאריך",
          daysOfWeek: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
          monthNames: [
            "ינואר",
            "פברואר",
            "מרץ",
            "אפריל",
            "מאי",
            "יוני",
            "יולי",
            "אוגוסט",
            "ספטמבר",
            "אוקטובר",
            "נובמבר",
            "דצמבר",
          ],
          firstDay: 0,
        },
      },
      (start, end, label) => {
        this.dateInput.from = start;
        this.dateInput.to = end;
        $(".date-input").val(
          end.format("DD/MM/YY") + " - " + start.format("DD/MM/YY")
        );

        this.filterTasks(0, "");
      }
    );
    $(".date-input").val("");
  }
  filterTasks(startIndex, text) {
    if (this.selectedTime["value"] != "") {
      $(".date-input").prop("disabled", true);
      this.dateInput.from = moment(this.selectedTime["date_range"]["from"]);
      this.dateInput.to = moment(this.selectedTime["date_range"]["to"]);
      $(".date-input").val(
        this.dateInput.to.format("DD/MM/YY") +
          " - " +
          this.dateInput.from.format("DD/MM/YY")
      );
    } else {
      $(".date-input").prop("disabled", false);
    }
    if (this.selectedStatus["value"] == null) {
      this.selectedStatus["value"] = "";
    }
    this.dataService.showGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    const searchText = text || "";
    this.tasksService
      .getAllTasksByTypeByFilter(
        this.typeName,
        moment(this.dateInput.from).format("YYYY-MM-DD"),
        moment(this.dateInput.to).format("YYYY-MM-DD"),
        this.selectedTime["value"],
        this.selectedStatus["value"],
        searchText,
        startIndex,
        10
      )
      .subscribe(
        (resp) => {
          if (this.searchingWorkersRequestsArray.length > 0) {
            this.searchingWorkersRequestsArray.pop();
          }
          this.dataService.showGhostElements =
            this.searchingWorkersRequestsArray.length > 0;
          this.dataService.showDataLoader = false;
          this.data = resp["missions"];
          this.paginatorLength = resp["pagination"]["total_count"];
          this.sizeDataArray = this.data.length;
        },
        (err) => {
          this.dataService.showGhostElements = false;

          this.dataService.handleErrors(err);
        }
      );
    this.tasksService
      .getCountsType(
        this.typeName,
        moment(this.dateInput.from).format("YYYY-MM-DD"),
        moment(this.dateInput.to).format("YYYY-MM-DD"),
        this.selectedTime["value"]
      )
      .subscribe(
        (resp) => {
          this.total_count = this.dataService.getResourceText(
            this.textService.textSource["MISSIONS_TYPE_SCREEN_SUBTITLE_1"],
            resp
          );
          this.total_held = this.dataService.getResourceText(
            this.textService.textSource["MISSIONS_TYPE_SCREEN_SUBTITLE_2"],
            resp
          );
          this.total_scheduled = this.dataService.getResourceText(
            this.textService.textSource["MISSIONS_TYPE_SCREEN_SUBTITLE_3"],
            resp
          );
        },
        (err) => {
          this.dataService.handleErrors(err);
        }
      );
  }
  onSearchResponse(resp: any) {
    this.dataService.showGhostElements = false;
    this.dataService.showDataLoader = false;
    this.data = resp["missions"];
    this.paginatorLength = resp["pagination"]["total_count"];
    this.sizeDataArray = this.data.length;
  }
  resize(event: any) {
    this.pageWidth = event.target.innerWidth;
  }

  onPageChange($event) {
    let startIndex = $event.pageIndex * $event.pageSize;
    this.filterTasks(startIndex, this.textModel);
    setTimeout(() => {
      $(".employee-task-list").focus();
      window.scroll(0, 0);
    }, 100);
  }
}
