import { Component } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { AnimationItem } from "lottie-web";
import { AnimationOptions, LottieModule } from "ngx-lottie";

@Component({
    selector: "app-loader",
    template: ` <ng-lottie [options]="lottieConfig" [styles]="styles">
  </ng-lottie>`,
    standalone: true,
    imports: [LottieModule]
})
export class LoaderComponent {
  public lottieConfig: Object;
  public styles: Partial<CSSStyleDeclaration>;
  constructor(dataService: DataService) {
    this.lottieConfig = {
      path: "assets/animations/" + dataService.name + "-loader.json",
      renderer: "canvas",
      autoplay: true,
      loop: true,
    };
    this.styles = {
      maxWidth: "300px",
      margin: "0 auto",
    };
  }
}
