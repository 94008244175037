import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { LoginComponent } from "./components/pages/login/login.component";
import { LoginSmsComponent } from "./components/pages/login-sms/login-sms.component";
import { HomeComponent } from "./components/pages/home/home.component";
import { CanActivateLoggedUser } from "./shared/CanActivateLoggedUser";
import { CanActivateNotLoggedUser } from "./shared/CanActivateNotLoggedUser";
import { LoginErrorComponent } from "./components/pages/login-error/login-error.component";
import { ThankYouComponent } from "./components/pages/thank-you/thank-you.component";
import { ThankYouRegisterEmployeeComponent } from "./components/pages/thank-you-register-employee/thank-you-register-employee.component";
import { RegisterEmployeeComponent } from "./components/pages/register-employee/register-employee.component";
import { PolicyArchiveComponent } from "./components/pages/policy-archive/policy-archive.component";
import { ForeignWorkerComponent } from "./components/pages/foreign-worker/foreign-worker.component";
import { TermsComponent } from "./components/pages/terms/terms.component";
import { PrivacyComponent } from "./components/pages/privacy/privacy.component";
import { AccessabilityComponent } from "./components/pages/accessability/accessability.component";
import { AppointmentsComponent } from "./components/pages/appointments/appointments.component";
import { AppointmentTypeComponent } from "./components/pages/appointment-type/appointment-type.component";
import { GeneralErrorPopupComponent } from "./components/pages/general-error-popup/general-error-popup.component";
import { WorkersComponent } from "./components/pages/workers/workers.component";
import { EmployeeCardComponent } from "./components/pages/employee-card/employee-card.component";
import { NewAppointmentPopupComponent } from "./components/pages/new-appointment-popup/new-appointment-popup.component";
import { EmployeesTasksComponent } from "./components/pages/employees-tasks/employees-tasks.component";
import { TypeTaskComponent } from "./components/pages/type-task/type-task.component";
import { EmployersInterfaceComponent } from "./components/pages/employers-interface/employers-interface.component";
import { AdminLoginComponent } from "./components/admin/pages/admin-login/admin-login.component";
import { IpGuardGuard } from "./components/admin/guards/ip-guard.guard";
import { ImportNewEmployeesComponent } from "./components/pages/import-new-employees/import-new-employees.component";
import { ImportEmployeesGuard } from "./shared/import-employees.guard";
import { CanActivateFeedbackGuard } from "./shared/can-activate-feedback.guard";
import { ElementaryPolicyComponent } from "./components/pages/elementary-policy/elementary-policy.component";
import { ImportEmployeesWorkTerminationComponent } from "./components/pages/import-employees-work-termination/import-employees-work-termination.component";
import { EmployerActionComponent } from "./components/employer-action/employer-action.component";

const routes: Routes = [
  { path: "", component: HomeComponent, canActivate: [CanActivateLoggedUser] },
  {
    path: "login",
    component: LoginComponent,
    canActivate: [CanActivateNotLoggedUser],
  },
  {
    path: "dashboard/user",
    component: AdminLoginComponent,
    canActivate: [IpGuardGuard],
  },
  { path: "login-error", component: LoginErrorComponent },
  { path: "sms", component: LoginSmsComponent },
  { path: "thank-you", component: ThankYouComponent },
  {
    path: "register-thank-you",
    component: ThankYouRegisterEmployeeComponent,
    canActivate: [CanActivateLoggedUser],
  },
  {
    path: "register",
    component: RegisterEmployeeComponent,
    canActivate: [CanActivateLoggedUser],
  },
  {
    path: "policy",
    component: PolicyArchiveComponent,
    canActivate: [CanActivateLoggedUser],
  },
  {
    path: "foreign/:number",
    component: ForeignWorkerComponent,
    canActivate: [CanActivateLoggedUser],
  },
  {
    path: "elementary-policies",
    component: ElementaryPolicyComponent,
    canActivate: [CanActivateLoggedUser],


  },
  { path: "terms", component: TermsComponent },
  { path: "error", component: GeneralErrorPopupComponent },
  { path: "privacy", component: PrivacyComponent },
  { path: "accessibility", component: AccessabilityComponent },
  {
    path: "appointments",
    component: AppointmentsComponent,
    canActivate: [CanActivateLoggedUser],
  },
  {
    path: "appointment-type/:name",
    component: AppointmentTypeComponent,
    canActivate: [CanActivateLoggedUser],
  },
  {
    path: "my-employees",
    component: WorkersComponent,
    canActivate: [CanActivateLoggedUser],
  },
  {
    path: "employee-card/:id",
    component: EmployeeCardComponent,
    canActivate: [CanActivateLoggedUser],
  },
  {
    path: "tasks",
    component: EmployeesTasksComponent,
    canActivate: [CanActivateLoggedUser],
  },
  {
    path: "task-type/:name",
    component: TypeTaskComponent,
    canActivate: [CanActivateLoggedUser],
  },
  {
    path: "import-new-employees",
    component: ImportNewEmployeesComponent,
    canActivate: [CanActivateLoggedUser, ImportEmployeesGuard],
  },
  {
    path: "import-employees-work-termination",
    component: ImportEmployeesWorkTerminationComponent,
    canActivate: [CanActivateLoggedUser, ImportEmployeesGuard],
  },
  {
    path: "employers-interface",
    component: EmployersInterfaceComponent,
    canActivate: [CanActivateLoggedUser, CanActivateFeedbackGuard],
  },
  {
    path: "actions",
    component: EmployerActionComponent,
    canActivate: [CanActivateLoggedUser]

  },
  { path: "**", redirectTo: "login", pathMatch: "full" },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
