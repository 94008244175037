import { Component, OnInit } from "@angular/core";
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators, ReactiveFormsModule } from "@angular/forms";
import * as moment from "moment";
import {
  IPartTimeSalaryReductionPeriod,
  WorkTerminationDataEnum,
} from "src/app/models/work-termination.model";
import { TextService } from "src/app/services/text.service";
import { InputComponent } from "../../../common/input/input.component";

@Component({
    selector: "app-work-termination-period-item-component",
    templateUrl: "./work-termination-period-item-component.component.html",
    styleUrls: ["./work-termination-period-item-component.component.scss"],
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: WorkTerminationPeriodItemComponentComponent,
            multi: true,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: WorkTerminationPeriodItemComponentComponent,
            multi: true,
        },
    ],
    standalone: true,
    imports: [ReactiveFormsModule, InputComponent]
})
export class WorkTerminationPeriodItemComponentComponent
  implements OnInit, ControlValueAccessor, Validator
{

  workTerminationDataEnum = WorkTerminationDataEnum;

  perieodItem = this.fb.group({
    [this.workTerminationDataEnum.PART_TIME_SALARY_REDUCTION_START_DATE]:
      new UntypedFormControl("", [this.validateDate]),
    [this.workTerminationDataEnum.PART_TIME_SALARY_REDUCTION_END_DATE]:
      new UntypedFormControl("", [this.validateDate]),
    [this.workTerminationDataEnum.JOB_RATE_PERCENT]: new UntypedFormControl("", [
      Validators.maxLength(5),
      Validators.pattern(/^[0-9.]+$/i),
    ]),
    [this.workTerminationDataEnum.LAST_SALARY_PERIOD]: new UntypedFormControl("", [
      Validators.pattern(/^[0-9.]+$/i),
    ]),
  });

  callBack?: any;

  constructor(
    public textService: TextService,
    private readonly fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.perieodItem.valueChanges.subscribe((o) => {
      if (this.callBack) {
        this.callBack(o);
      }
    });
  }

  validateDate(control: UntypedFormControl) {
    if (control.value) {
      const date = moment(control.value);
      const isValid = date.isValid();
      if (!isValid) {
        return { date: true };
      }
    }
    return null;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.perieodItem.valid) {
      return null;
    }
    return { EditProductItemComponent: "not-valid" };
  }

  writeValue(obj: IPartTimeSalaryReductionPeriod): void {
    if (!obj) {
      return;
    }
    this.perieodItem.patchValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.callBack = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.perieodItem.disable();
    } else {
      this.perieodItem.enable();
    }
  }
}
