import { Injectable } from "@angular/core";
import { HttpHandler } from "./httpHandler";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";

@Injectable({
  providedIn: "root",
})
export class OtpService extends HttpHandler {
  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService
  ) {
    super(_http, httpService, errorService);
    this.httpService.headers.subscribe();
  }

  public validateOtp(code: string) {
    const url = "/login/verify";
    let body = {
      code: code,
    };
    return this.post(url, body);
  }

  public resendOTP() {
    const url = "/login/resend-code";
    return this.get(url);
  }
}
