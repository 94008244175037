// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button {
  cursor: pointer;
  border-radius: 5px;
  background-color: var(--main_button_color);
  font-size: var(--font-size-button-mobile);
  font-family: var(--main-font-regular);
  color: #fff;
  text-align: center;
  margin-top: 1.5rem;
  margin-right: 12px;
  padding: 10px;
  width: 99%;
  border: 2px solid var(--main_button_color);
  position: relative;
}

.popup {
  background-color: white;
  padding: 10%;
}

.title-text {
  margin-top: 8px;
  margin-bottom: 8px;
  margin-right: 10px;
  font-size: 25px;
  color: var(--main_button_color);
  font-family: var(--secondary-font-regular);
  text-align: center;
  direction: rtl;
}

@media only screen and (max-width: 599px) {
  .button {
    font-size: 15px;
    margin-top: 0.5rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/pages/login/login-popup/login-popup.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;EACA,kBAAA;EACA,0CAAA;EACA,yCAAA;EACA,qCAAA;EACA,WAAA;EACA,kBAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,UAAA;EACA,0CAAA;EACA,kBAAA;AACF;;AAGA;EACE,uBAAA;EACA,YAAA;AAAF;;AAGA;EACE,eAAA;EACA,kBAAA;EACA,kBAAA;EACA,eAAA;EACA,+BAAA;EACA,0CAAA;EACA,kBAAA;EACA,cAAA;AAAF;;AAGA;EACE;IACE,eAAA;IACA,kBAAA;EAAF;AACF","sourcesContent":[".button {\n  cursor: pointer;\n  border-radius: 5px;\n  background-color: var(--main_button_color);\n  font-size: var(--font-size-button-mobile);\n  font-family: var(--main-font-regular);\n  color: #fff;\n  text-align: center;\n  margin-top: 1.5rem;\n  margin-right: 12px;\n  padding: 10px;\n  width: 99%;\n  border: 2px solid var(--main_button_color);\n  position: relative;\n\n}\n\n.popup {\n  background-color: white;\n  padding: 10%;\n}\n\n.title-text {\n  margin-top: 8px;\n  margin-bottom: 8px;\n  margin-right: 10px;\n  font-size: 25px;\n  color: var(--main_button_color);\n  font-family: var(--secondary-font-regular);\n  text-align: center;\n  direction: rtl;\n}\n\n@media only screen and (max-width: 599px) {\n  .button {\n    font-size: 15px;\n    margin-top: 0.5rem\n  }\n\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
