import { Component, OnInit } from "@angular/core";
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl, ReactiveFormsModule } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { AdminService } from "../../services/admin.service";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { NgIf, NgClass } from "@angular/common";
import { HttpService } from "src/app/services/http.service";
import { LoginService } from "src/app/services/login.service";
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { LoginPopupComponent } from "src/app/components/pages/login/login-popup/login-popup.component";
import { BusinessesForUserService } from "src/app/services/businesses-for-user.service";
import { LoaderComponent } from "src/app/components/common/loader/loader.component";

const ADMIN_TOKEN_KEY = "a-auth-token";

@Component({
  selector: "app-admin-login",
  templateUrl: "./admin-login.component.html",
  styleUrls: ["./admin-login.component.scss"],
  standalone: true,
  imports: [NgIf, ReactiveFormsModule, MatLegacyFormFieldModule, MatLegacyInputModule, NgClass, LoaderComponent],
})
export class AdminLoginComponent implements OnInit {
  password: any;
  userName: any;
  errorMessage: any;
  public form: UntypedFormGroup;
  profileForm = new UntypedFormGroup({
    name: new UntypedFormControl(""),
    password: new UntypedFormControl(""),
  });
  userId: string;


  constructor(
    public dataService: DataService,
    private fb: UntypedFormBuilder,
    public adminService: AdminService,
    private router: Router,
    private route: ActivatedRoute,
    private httpService: HttpService,
    private loginService: LoginService,
    private businessesForUserService: BusinessesForUserService,
    private dialog: MatDialog,

  ) {
    sessionStorage.setItem("check", "check");
  }

  ngOnInit() {
    this.route.queryParams
      .subscribe(params => {
        if (!params.id || Array.isArray(params.id)) {
          alert("something went wrong! ")
          this.router.navigateByUrl('/');
        }
        this.userId = params.id;
      });
    this.profileForm = this.fb.group({
      name: ["", Validators.compose([Validators.required])],
      password: [
        "",
        Validators.compose([Validators.required, Validators.minLength(3)]),
      ],
    });
    this.dataService.logOutBeforeLoginAdmin();
  }

  public login() {
    this.dataService.showDataLoader = true;
    this.adminService
      .login(this.profileForm.value.name, this.profileForm.value.password)
      .subscribe(
        (resp) => {
          let accessToken = resp.access_token;
          if (accessToken) {
            this.adminService.setAccessTokenAndUpdateHeader(accessToken);
            this.userlogin();
          }
        },
        (err) => {
          this.dataService.showDataLoader = false;
          switch (err.message) {
            case "Invalid credentials":
              this.errorMessage =
                "לא נמצאה התאמה בין הנתונים שהוקלדו לנתונים הקיימים במערכת";
              break;

            case undefined:
              this.errorMessage = "אירעה שגיאה, אנא נסו שוב";

              break;
            default:
              break;
          }
        }
      );
  }
  public userlogin() {
    this.loginService.loginBySalesForceID(this.userId).subscribe(
      (resp) => {
        this.dataService.showDataLoader = false;
        this.httpService.updateHeader('token', resp.verify_token);
        sessionStorage.setItem('token', resp.verify_token);

        if (resp.businessesForUser.length > 0) {
          sessionStorage.setItem("businesses", JSON.stringify(resp.businessesForUser));
          const dialogRef = this.dialog.open(LoginPopupComponent, {
            data: resp.businessesForUser
          });
          dialogRef.afterClosed().subscribe(result => {
            this.businessesForUserService.loadBusinesses(resp.businessesForUser);
            if (result!=undefined) {
              this.businessesForUserService.changeBusiness(result, false).subscribe(res => {
                this.router.navigateByUrl('/');
              });
            }
          });
        }
      },
      (err) => {
        this.dataService.showDataLoader = false;
        switch (err.code) {
          case 403:
            this.errorMessage = 'לאיש קשר זה אין הרשאה לפורטל מעסיקים';
            break;

          case undefined:
            this.errorMessage = 'אירעה שגיאה, אנא נסו שוב';
        }
      }
    )
  }


}
