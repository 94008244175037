// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.soon-text {
  text-align: center;
  direction: rtl;
  font-size: 22px;
  margin-bottom: 4rem;
  margin-top: unset;
}

@media screen and (min-width: 600px) {
  .desktop .soon-text {
    margin-top: 2rem;
    margin-bottom: unset;
    font-size: 30px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/pages/home/home.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;EACA,eAAA;EACA,mBAAA;EACA,iBAAA;AACF;;AAEA;EAEI;IACE,gBAAA;IACA,oBAAA;IACA,eAAA;EAAJ;AACF","sourcesContent":[".soon-text {\n  text-align: center;\n  direction: rtl;\n  font-size: 22px;\n  margin-bottom: 4rem;\n  margin-top: unset;\n}\n\n@media screen and (min-width: 600px) {\n  .desktop {\n    .soon-text {\n      margin-top: 2rem;\n      margin-bottom: unset;\n      font-size: 30px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
