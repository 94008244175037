import { Injectable } from "@angular/core";
import { HttpHandler } from "./httpHandler";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { DataService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class HomepageService extends HttpHandler {
  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService
  ) {
    super(_http, httpService, errorService);
  }

  getHomepageData() {
    const url = "/contact/profile-lean";
    return this.get(url);
  }

  getMeetings(year: number, quarter: number) {
    year = year || 2021;
    quarter = quarter || 4;
    const params = new HttpParams({
      fromObject: {
        year: `${year}`,
        quarter: `${quarter}`,
      },
    });
    const url = "/contact/service-request/meeting-type/important/counts?";
    return this.get(url, params);
  }
}
