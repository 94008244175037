import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class IpService {
  private baseUrl = environment.url;

  constructor(private http: HttpClient) {}

  public getIPAddress() {
    return this.http.get("https://api.ipify.org/?format=json");
  }

  async getIP(): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        this.http.get("https://api.ipify.org/?format=json").subscribe((ip) => {
          console.log(ip);
          resolve(ip);
        });
      } catch (e) {
        console.log("cant get your ip");
        reject(e);
      }
    });
  }

  /**
   * Return boolean if current IP is allowed to admin route
   */
  validateIP(): Promise<boolean> {
    return new Promise((resolve) => {
      // Dummy API Call to check for IP permission for admin route
      let ip = this.getIPAddress();
      if (ip) {
        const url = this.baseUrl.replace("api/v1", "admin") + "/guard";
        this.http
          .get(url)
          .toPromise()
          .then((resp) => {
            if (resp === true) {
              resolve(true);
            } else {
              resolve(false);
            }
          })
          .catch((err) => {
            resolve(false);
          });
      } else {
        resolve(false);
      }
    });
  }
}
