import { Injectable } from "@angular/core";
import { HttpHandler } from "./httpHandler";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { DataService } from "./data.service";
import { Subject, Observable } from "rxjs";
import {
  debounceTime,
  distinctUntilChanged,
  switchMap,
  map,
} from "rxjs/operators";


export interface ISearchable {
  // search_text: Observable<string>
  endpoint: string;
  threshold: number;
  paramsObj: {};
  onSearch(): void;
  textObserver(): Observable<string>;
}

@Injectable({
  providedIn: "root",
})
export class SearchService extends HttpHandler {
  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService
  ) {
    super(_http, httpService, errorService);
  }

  search(searchable: ISearchable): Observable<any> {
    return new Observable((obj) => {
      searchable
        .textObserver()
        .pipe(debounceTime(400), distinctUntilChanged())
        .subscribe((term) => {
          if (term.length >= searchable.threshold || term.length == 0) {
            let params = new HttpParams({
              fromObject: {
                ...searchable.paramsObj,
                searchTerm: `${term}`,
              },
            });
            searchable.onSearch();
            this.get(searchable.endpoint, params).subscribe(
              (res) => {
                obj.next(res);
              },
              (err) => {
                obj.error(err);
              }
            );
          }
        });
    });
  }
}
