import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { CommonModule, NgIf } from "@angular/common";
import { DataService } from "../services/data.service";
import { TextService } from "../services/text.service";
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";

@Component({
  selector: "app-monthly-or-hourly",
  templateUrl: "./monthly-or-hourly.component.html",
  styleUrls: ["./monthly-or-hourly.component.scss"],
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
})
export class MonthlyOrHourlyComponent implements OnInit {
  @Output() salaryMethodChange = new EventEmitter();
  salaryOptionsForm: FormGroup;
  salaryOptions = [
    { label: "שעתי", value: "hourly" },
    { label: "חודשי", value: "monthly" },
  ];
  salaryOptionsControl= new FormControl<{value:string,label:string}>(null);
  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {}
  ngOnInit(): void { 
    this.salaryOptionsForm = new FormGroup({
      salary_method: new FormControl<{value:string,label:string}>(null),
    });
  }
  ok(){
    this.salaryMethodChange.emit(this.salaryOptionsControl.value);
}
}
