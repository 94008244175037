import { Component, HostListener, OnInit } from "@angular/core";
import { Router, NavigationEnd, RouterEvent, Event, RouterOutlet } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";
import { environment } from "../environments/environment";
import { filter, map, startWith } from "rxjs/operators";
import { HomepageService } from "./services/homepage.service";
import { FeedbackService } from "./services/feedback.service";
import { AuthService } from "./services/auth.service";
import { interval } from "rxjs";
import { BusinessesForUserService } from "./services/businesses-for-user.service";
import { FooterComponent } from "./components/common/footer/footer.component";
import { NavbarComponent } from "./components/common/navbar/navbar.component";
import { NgIf, AsyncPipe } from "@angular/common";
import { AllowedClassifiedInformationService } from "./services/allowed-classified-information.service";

declare var $;
declare var gtag;
declare var dataLayer;
@Component({
    selector: "app-root",
    templateUrl: "./app.component.html",
    styleUrls: ["./app.component.scss"],
    standalone: true,
    imports: [NgIf, NavbarComponent, RouterOutlet, FooterComponent, AsyncPipe]
})
export class AppComponent implements OnInit {
  title = "portalEmployeeAngular";
  areYoulogedIn;
  src;
  lastRoute;
  loadInitData: boolean = false;

  hideOnLoad$ = this.businessesForUserService.needToShowAll$;

  constructor(
    public textService: TextService,
    public router: Router,
    public dataservice: DataService,
    public homepageService: HomepageService,
    private feedbackService: FeedbackService,
    private businessesForUserService: BusinessesForUserService,
    private allowedClassifiedInformationService: AllowedClassifiedInformationService,
    private authService: AuthService
  ) {
    router.events
    .pipe(filter((e: Event): e is NavigationEnd => e instanceof NavigationEnd))
    .subscribe((e: NavigationEnd) => {
      if (this.lastRoute !== e.url) {
        this.lastRoute = e.url;
        this.loadInitData = false;
      }
      this.areYoulogedIn = this.dataservice.AreYouLoggedIn();
      if (this.areYoulogedIn && !this.loadInitData) {
        this.loadInitData = true;
        this.homepageService.getHomepageData().subscribe(
          (resp) => {
            this.dataservice.company_logo_binary =
              resp["person"]["contact"]["logo_base_64"];
            this.src =
              this.dataservice.company_logo_binary === null
                ? ""
                : "data:image/png;base64," +
                  this.dataservice.company_logo_binary;
          },
          (err) => {
            this.dataservice.handleErrors(err);
            this.loadInitData = false;
          }
        );
      }
    });

    this.textService.getResources().subscribe(
      (resp) => {
        this.textService.textSource = resp;
        sessionStorage.setItem("resource", JSON.stringify(resp));
      },
      (err) => { }
    );

    if (environment.name == "gur") {
      const navEndEvent$ = router.events.pipe(
        filter((e) => e instanceof NavigationEnd)
      );
      navEndEvent$.subscribe((e: NavigationEnd) => {
        dataLayer.push({ event: "virtualPageview" });
        gtag("config", environment.analyticsKey, {
          page_path: e.urlAfterRedirects,
        });
      });
    }
  }

  ngOnInit(): void {
    this.loadGoogleAnalytics(environment.analyticsKey);

    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty("--vh", `${vh}px`);

    this.authService.getUser().subscribe((user) => {
      const displayFeedbackValue =
        this.feedbackService.getDisplayFeedbackValue();
      const isAllowedClassifiedInformation =
        this.allowedClassifiedInformationService.getAssociatePersonAllowedClassifiedValue();
      if (user) {
        if (displayFeedbackValue === null) {
          this.feedbackService.getDisplayFeedback();
        }
        if (isAllowedClassifiedInformation === null) {
          this.allowedClassifiedInformationService.getAssociatePersonAllowedClassified();
        }
      }
    });
  }

  loadGoogleAnalytics(trackingID: string): void {
    let gaScript2 = document.createElement("script");
    gaScript2.innerText = `window.dataLayer = window.dataLayer || [];function gtag(){dataLayer.push(arguments);}gtag(\'js\', new Date());gtag(\'config\', \'${trackingID}\');`;
    document.getElementsByTagName("body")[0].appendChild(gaScript2);
  }

  onActivate(event) {
    setTimeout(() => {
      $("#hidden-menu").focus();
    });
  }
}
