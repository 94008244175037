// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.text {
  font-family: var(--main-font-light);
  font-size: 20px;
  text-align: right;
  direction: rtl;
  color: var(--main_color);
  line-height: 20px;
}

.title {
  font-family: var(--secondary-font-regular);
  font-size: 25px;
  text-align: center;
  direction: rtl;
  color: var(--main_color);
  line-height: 27px;
  position: relative;
  padding-top: 10px;
}

.img {
  width: 40px;
  height: 40px;
}

.no-content {
  text-align: center;
}

@media screen and (min-width: 600px) {
  .desktop.no-content {
    padding: 15px 0 0;
  }
  .desktop.no-content .text {
    width: 30%;
    margin-left: auto;
  }
  .desktop.no-content .img {
    width: 48px;
    height: 48px;
  }
  .desktop.no-content .title {
    top: auto;
    padding-top: 27px;
    font-size: var(--font-size-card-title-appointments-desktop);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/no-content/no-content.component.scss"],"names":[],"mappings":"AAAA;EACE,mCAAA;EACA,eAAA;EACA,iBAAA;EACA,cAAA;EACA,wBAAA;EACA,iBAAA;AACF;;AAEA;EACE,0CAAA;EACA,eAAA;EACA,kBAAA;EACA,cAAA;EACA,wBAAA;EACA,iBAAA;EACA,kBAAA;EACA,iBAAA;AACF;;AACA;EACE,WAAA;EACA,YAAA;AAEF;;AAAA;EACE,kBAAA;AAGF;;AADA;EACE;IACE,iBAAA;EAIF;EAFE;IACE,UAAA;IACA,iBAAA;EAIJ;EAFE;IACE,WAAA;IACA,YAAA;EAIJ;EADE;IACE,SAAA;IACA,iBAAA;IACA,2DAAA;EAGJ;AACF","sourcesContent":[".text {\n  font-family: var(--main-font-light);\n  font-size: 20px;\n  text-align: right;\n  direction: rtl;\n  color: var(--main_color);\n  line-height: 20px;\n}\n\n.title {\n  font-family: var(--secondary-font-regular);\n  font-size: 25px;\n  text-align: center;\n  direction: rtl;\n  color: var(--main_color);\n  line-height: 27px;\n  position: relative;\n  padding-top: 10px;\n}\n.img {\n  width: 40px;\n  height: 40px;\n}\n.no-content {\n  text-align: center;\n}\n@media screen and (min-width: 600px) {.desktop {\n  &.no-content {\n    padding: 15px 0 0;\n\n    .text {\n      width: 30%;\n      margin-left: auto;\n    }\n    .img {\n      width: 48px;\n      height: 48px;\n    }\n\n    .title {\n      top: auto;\n      padding-top: 27px;\n      font-size: var(--font-size-card-title-appointments-desktop);\n    }\n  }\n}\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
