import { IPersonalDetails } from "./register-user-data.model";

export enum WorkTerminationDataEnum {
  START_WORK_DATE = "startWorkDate",
  END_WORK_DATE = "retirementDate",
  LAST_MONTHLY_SALARY = "lastMonthlySalary",
  LAST_INSURED_SALARY = "lastInsuredsalary",
  SECTION14 = "section14",
  COMPENSATION_RELEASE = "compensationRelease",
  COMPLETION_AMOUNT = "haschomLeyomHaprish",
  DATE_PAYMENT_COMPENSATION_COMPLETION = "taarichHafkadaRishon",
  ENTITLEMEMT_BUDGET_PENSION = "entitlementBudgetPension",
  CONTROLLING_OWNER = "controllingOwner",
  RELATIONSHIP_CONTROLLING_OWNER = "relationshipControllingOwner",
  LAST_PREMIUM_DATE = "lastPremiumDate",
  SALARY_COMPENSATION_LIABILITY = "salaryCompensationLiability",
  PART_TIME_SALARY_REDUCTION_START_DATE = "partTimeSalaryReductionStartDate",
  PART_TIME_SALARY_REDUCTION_END_DATE = "partTimeSalaryReductionEndDate",
  PART_TIME_SALARY_REDUCTION_PERIODS = "partTimeSalaryReductionPeriods",
  JOB_RATE_PERCENT = "jobRatePercent",
  LAST_SALARY_PERIOD = "lastSalaryForPeriod",
  PAYER_NAME = "payerName",
  DEDUCTIONS_PORTFOLIO = "deductionsPortfolio",
  AUTHORIZED_TO_SIGN_FORM = "authorizedSignatory161",
  AUTHORIZED_TO_VIEW_FORM = "authorizedViewing161",
  TAXED_PAYMENTS = "texedPayments",
  PAYMENT_DATE = "paymentDate",
  PAYMENT_SUM = "paymentSum",
  PAYER_NAME60 = "payerName60",
  DEDUCTIONS_PORTFOLIO60 = "deductionsPortfolio60",
  PAYER_ID = "payerId",
  CONVERSION_MONTH = "conversionMonth",
  PENSION_AMOUNT = "pensionAmout",
  TOTAL_AMOUNT = "totalAmout",
  PENSION_SEQUENCE_OPTION = "pemsionSequence",
  GRANT_AMOUNT = "grantAmout",
  PENSION_SEQUENCE_AMOUNT = "pensionSequenceAmout",
  COMPLETION_SEQUENCE_AMOUNT = "completionSequenceAmout",
  GRANT_AMOUNT_RELEASE = "grantAmoutRelease",
  GRANT_AMOUNT_FORCED = "grantAmoutForces",
  CITY = "city",
  STREET = "street",
  BUILDING_NUMBER = "buldingNumber",
  NUM_HOUSE = "apartmentNumber",
  POSTAL_CODE = "postalCode",
  DEDUCTIBLE_TAX_RATED = "govaHamass",
  LAST_PREMIUM = "lastPremia",
  BIRTHDATE = "birthDate",
  MOBILE_PHONE = "mobilePhone",
  PERSONAL_EMAIL = "personalEmail",
  ZAKAAOT_DATE = "zakaaotDate",
  ZAKAAOT_SALARY = "zakaaotSalary",
  FOREIGN_LANGUAGE = "foreignLanguage",
  COMMENTS = "comments",
  WORKER_NUMBER = "employerNumber"
}

export enum WorkTerminationReasonsEnum {
  RETIREMENT = "Retirement",
  RESIGNATION = "Resignation",
  DEATH = "Death",
}

export interface IWorkTerminationRequestBody {
  workTerminationReason: string;
  startWorkDate?: string;
  lastMonthlySalary?: string;
  lastInsuredsalary?: string;
  section14?: boolean;
  compensationRelease?: boolean;
  eligibilityForCompensation?: boolean;
  completionLimit?: string;
  lastPremiumDate?: string;
  salaryCompensationLiability?: string;
  entitlementBudgetPension: boolean;
  controllingOwner: boolean;
  relationshipControllingOwner: string;
  pemsionSequence: PemsionSequenceEnum,
  grantAmout: string;
  pensionSequenceAmout: string;
  completionSequenceAmout: string;
  grantAmoutRelease: string;
  grantAmoutForces: string;
  partTimeSalaryReductionPeriods?: Array<IPartTimeSalaryReductionPeriod>;
  providentMoneyArray?: Array<IProvidentMoneyItem>;
  retirementGrantPaymentRaterArray?: Array<IRetirementGrantPaymentRaterItem>;
  pensionBeforeRetirementArray?: Array<IPensionBeforeRetirementItem>;
  govaHamass: number;
  lastPremia: number;
  deductionsPortfolio: string;
  zakaaotDate: string,
  zakaaotSalary: number;
  personal_details: Partial<IPersonalDetails>;
  retirementDate: string;
  taarichHafkadaRishon: string;
  haschomLeyomHaprish: number;
  authorizedSignatory161: string;
  authorizedViewing161: string;
  foreignLanguage: string;
  comments: string;
  employment_id?: string;
  employerNumber?: number;
  procedureId?: string;
}

export interface IPartTimeSalaryReductionPeriod {
  partTimeSalaryReductionStartDate?: string;
  partTimeSalaryReductionEndDate?: string;
  lastSalaryForPeriod?: string;
  jobRatePercent?: string;
}

export interface IProvidentMoneyItem {
  payerName?: string;
  deductionsPortfolio?: string;
  texedPayments?: string;
}

export interface IRetirementGrantPaymentRaterItem {
  paymentDate?: string;
  paymentSum?: string;
}

export interface IPensionBeforeRetirementItem {
  payerName60?: string;
  deductionsPortfolio60?: string;
  payerId?: string;
  conversionMonth?: string;
  pensionAmout?: string;
  totalAmout?: string;
}

export enum PemsionSequenceEnum {
  NOT = '"אינו עולה על תקרת "רצף ברירת מחדל',
  YES = '"עולה על תקרת "רצף ברירת מחדל',
}
