import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { DataService } from 'src/app/services/data.service';
import { BidiModule } from '@angular/cdk/bidi';
import { NgIf, NgFor, NgClass, CommonModule } from '@angular/common';
import { TasksService } from 'src/app/services/tasks.service';
import { Observable } from 'rxjs';
import { AllowedClassifiedInformationService } from 'src/app/services/allowed-classified-information.service';
import { GeneralPopupComponent } from '../../common/general-popup/general-popup.component';
import { NewAppointmentService } from 'src/app/services/new-appointment.service';
import { PopupTypes } from 'src/app/models/popuptype';
import { EmployeeService } from 'src/app/services/employee.service';

@Component({
  selector: 'app-termination-work-table',
  templateUrl: './termination-work-table.component.html',
  styleUrls: ['./termination-work-table.component.scss'],
  standalone: true,
  imports: [BidiModule, CommonModule, GeneralPopupComponent]
})
export class TerminationWorkTableComponent {
  @Input() data;
  @ViewChild("table") myTable: ElementRef;
  isSticky: boolean = false;
  allowedClassifiedInformation$: Observable<boolean>;
  newAppointment = PopupTypes.newAppointment;
  aprovedRequest = PopupTypes.approvedRequest;
  constructor(public dataService: DataService, public tasksService: TasksService, private allowedClassifiedInformationService: AllowedClassifiedInformationService,
    private newAppointmentService: NewAppointmentService, private employeeService: EmployeeService
  ) {
    this.allowedClassifiedInformation$ = this.allowedClassifiedInformationService.getAssociatePersonAllowedClassifiedObs();
  }

  detectTableScroll(event) {
    const horizontal = event.currentTarget.scrollLeft;
    const maxScrollLeft =
      this.myTable.nativeElement.scrollWidth -
      this.myTable.nativeElement.clientWidth;
    const scrollDifference = maxScrollLeft - horizontal;

    this.isSticky = scrollDifference > 0 ? true : false;
  }
  download161Form(attachment_id: string) {
    this.tasksService
      .download161Form(attachment_id)
      .subscribe(
        (resp) => {
          const blob = new Blob([resp]); 
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.setAttribute("download", "161 form.pdf");
          a.setAttribute("href", url);
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
        },
        (err) => {
          alert("קובץ לא נמצא");  
        }
      );
  }

  editProcedureDetails(id: string) {
    this.dataService.showDataLoader = true;
    this.tasksService.getWorkTerminationProcedureDetails(id).subscribe(
      data => {
        this.dataService.showNewAppointmentPopup = true;
        this.newAppointmentService.dataForWorkTermination = data;
        this.employeeService.employeeData = data.employeeData;
        this.newAppointmentService.newAppointmentForSpecificEmployee = {
          id_number: data.employeeData.personal_details["id_number"],
          name: data.employeeData.personal_details["first_name"] + ' ' + data.employeeData.personal_details["last_name"],
        };
        this.newAppointmentService.isItAppointmentForSpecificEmployee = true;
        this.newAppointmentService.isExcelUpload = false;
      }

    );
  }
}
