import { Injectable } from "@angular/core";
import { HttpHandler } from "./httpHandler";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { DataService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class AppointmentsService extends HttpHandler {
  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService
  ) {
    super(_http, httpService, errorService);
  }

  getAllAppointmentsTypesByFilter(from, to, unit) {
    const url = "/contact/service-request/meeting-type/all/counts";
    let boolean = from != "" || to != "" || unit != "";
    let params = new HttpParams({
      fromObject: {
        from: `${from}`,
        to: `${to}`,
      },
    });
    if (unit != "") {
      params = params.append("unit", `${unit}`);
    }
    return boolean ? this.get(url, params) : this.get(url);
  }

  getAllAppointmentsByTypeByFilter(from, to, unit, status, type, limit, skip) {
    const url = "/contact/service-request/meeting-type/all/meetings";
    let params = new HttpParams({
      fromObject: {
        from: `${from}`,
        to: `${to}`,
        type: `${type}`,
      },
    });

    if (unit != "") {
      params = params.append("unit", `${unit}`);
    }

    if (status !== "") {
      params = params.append("status", `${status}`);
    }

    if (limit != "") {
      params = params.append("limit", `${limit}`);
    }

    if (skip != "") {
      params = params.append("skip", `${skip}`);
    }

    return this.get(url, params);
  }
}
