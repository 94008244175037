import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl, ReactiveFormsModule } from "@angular/forms";
import { IValidationMessage } from "src/app/models/import-new-employees.model";
import { FormValidatorsService } from "src/app/services/form-validators.service";
import { APP_DATE_FORMATS } from "../../../format-datepicker";
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from "@angular/material/core";
import { DateFormat } from "../../../services/date-format.service";
import * as moment from "moment";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { NgIf, NgFor } from "@angular/common";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";

@Component({
    selector: "app-input",
    templateUrl: "./input.component.html",
    styleUrls: ["./input.component.scss"],
    providers: [
        { provide: DateAdapter, useClass: DateFormat },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    ],
    standalone: true,
    imports: [MatLegacyFormFieldModule, NgIf, MatLegacyInputModule, ReactiveFormsModule, MatDatepickerModule, NgFor]
})
export class InputComponent implements OnInit {
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() type: string = "text";
  @Input() placeholder: string = "";
  @Input() label: string = "";
  @Input() controlValidationName: string = "";
  @Input() isDatePickerInput: boolean = false;
  @Input() maxDate: Date = null;
  @Input() dateFormat: "DD/MM/YYYY" | "MM/YYYY" = "DD/MM/YYYY";
  validation_messages: IValidationMessage[];
  dateFormats = ["DD/MM/YYYY", "D/M/YY", "YYYY-MM-DD"];

  constructor(
    private formValidatorsService: FormValidatorsService,
    private dateAdapter: DateAdapter<any>
  ) {}

  ngOnInit(): void {
    this.validation_messages =
      this.formValidatorsService.getFormValidationMessages(
        this.controlValidationName
      );

    // format incoming date
    if (this.isDatePickerInput && this.control?.value) {
      this.control.setValue(
        moment(this.control.value as string, this.dateFormats)
          .format("YYYY-MM-DD")
          .toString()
      );
    }

    // override display format
    if (this.dateFormat && this.dateAdapter instanceof DateFormat) {
      this.dateAdapter.overrideDisplayFormat(this.dateFormat);
    }
  }
}
