
import {
  Component,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DataService } from "../../../services/data.service";
import { TextService } from "../../../services/text.service";
import { Subject, Observable, BehaviorSubject } from "rxjs";
import { SearchService, ISearchable } from "src/app/services/search.service";
import { MatLegacyPaginator as MatPaginator, MatLegacyPaginatorModule } from "@angular/material/legacy-paginator";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { NgIf, NgFor } from "@angular/common";
import { MatLegacyRadioModule } from "@angular/material/legacy-radio";
import { HeaderComponent } from "../../common/header/header.component";
import { PolicyService } from "src/app/services/policy.service";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
import { BreadcrumbsComponent } from "../../common/breadcrumbs/breadcrumbs.component";
import { MyEmployeesTableComponent } from "../../tables/my-employees-table/my-employees-table.component";
import { ElementaryPoliciesTableComponent } from "../../tables/elementary-policies-table/elementary-policies-table.component";

declare var $;
@Component({
  selector: 'app-elementary-policy',
  templateUrl: './elementary-policy.component.html',
  styleUrls: ['./elementary-policy.component.scss'],
  standalone: true,
  imports: [NgIf, MatLegacyRadioModule, MatLegacyFormFieldModule, MatLegacyInputModule, ReactiveFormsModule, FormsModule, MatLegacySelectModule, NgFor, MatLegacyOptionModule, MatLegacyPaginatorModule, HeaderComponent, BreadcrumbsComponent, ElementaryPoliciesTableComponent]
})
export class ElementaryPolicyComponent implements OnInit, ISearchable, OnChanges {
  @ViewChild("paginator") paginator: MatPaginator;
  data: any = [];
  search_text = new Subject<string>();
  sizeDataArray = 3;
  paginatorLength;
  showGhost = false;
  activeFilterSelected = { label: "פעיל", value: 1 };
  subActiveFilterSelected = new BehaviorSubject(this.activeFilterSelected.value);
  optionsFilter;
  optionsFactorFilter;
  textModel;
  breadcrumbsPages = [
    BreadcrumbsLinkNames.HOME,
    BreadcrumbsLinkNames.COMPANY_POLICIES,
    BreadcrumbsLinkNames.COMPANY_ELEMENTARY_POLICIES
  ];
  constructor(
    public textService: TextService,
    public dataService: DataService,
    private searchService: SearchService,
    public policyService: PolicyService
  ) {
    // this.policyService.getElementaryPolicies(0, 10, "", 1).subscribe((resp) => {
    //   this.optionsFilter = resp["filter"];
    //   this.activeFilterSelected = this.optionsFilter[0];
    // }
    // );
    this.textService.setTextSource();
    this.searchService.search(this).subscribe(
      (resp) => {
        this.onSearchResponse(resp);
      },
      (err) => {
        this.policyService.policiesGhostElements = false;
        this.dataService.showDataLoader = false;
        this.dataService.handleErrors(err);
      }
    );
    this.subActiveFilterSelected.subscribe((value) => {
      this.paramsObj.actives = value;
    });

  }
  ngOnChanges(changes: SimpleChanges): void {
    if (
      !changes.regularWorker?.firstChange ||
      !changes.showAllWorkers?.firstChange
    ) {
      this.showGhost = true;
      this.searchByFilter({ label: "פעיל", value: 1 });
    }
  }

  //ISearchable interface implementation
  textObserver(): Observable<string> {
    //represent the text field key up observer
    return this.search_text.asObservable();
  }
  //represent the endpoint for the search request
  endpoint: string = "/contact/policy/elementary-policies";

  //represent the params for the search request
  paramsObj: { skip: number; limit: number; actives?: number } = { skip: 0, limit: 10 };
  //threshold to start sending request
  threshold: number = 3;
  //what to perform while searching
  onSearch() {
    this.policyService.policiesGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
  }

  ngOnInit() {
    this.showGhost = true;
    this.searchByFilter({ label: "פעיל", value: 1 });
  }

  onSearchResponse(resp: any) {
    this.showGhost = false;
    this.policyService.policiesGhostElements = false;
    this.dataService.showDataLoader = false;
    this.paginatorLength = resp["pagination"]["total_count"];
    this.resetPaginatorIndex();
    this.data = resp["policies"];
    this.optionsFilter = resp["filter"];
    this.activeFilterSelected = this.optionsFilter.filter(
      (element) => {
        return element.value === this.activeFilterSelected.value;
      }
    )[0];
    this.sizeDataArray = this.data.length;
  }

  async onPageChange($event) {
    let indexStart = $event.pageIndex * $event.pageSize;
    this.policyService.policiesGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }

    (
      await this.policyService.getElementaryPolicies(
        indexStart,
        10,
        this.textModel,
        this.activeFilterSelected["value"],
      )
    ).subscribe(
      (resp) => {
        this.policyService.policiesGhostElements = false;
        this.dataService.showDataLoader = false;
        this.data = resp["policies"];
        this.sizeDataArray = this.data.length;
      },
      (err) => {
        this.policyService.policiesGhostElements = false;
        this.dataService.showDataLoader = false;
        this.dataService.handleErrors(err);
      }
    );
  }

  searchByFilter(chosenFilter) {
    this.subActiveFilterSelected.next(chosenFilter.value)
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }

    this.textModel = "";
    this.policyService.getElementaryPolicies(0, 10, "", chosenFilter["value"]).subscribe(
      (resp) => {
        this.dataService.showDataLoader = false;
        this.showGhost = false;
        this.data = resp["policies"];
        this.sizeDataArray = this.data.length;
        this.optionsFilter = resp["filter"];
        this.paginatorLength = resp["pagination"]["total_count"];
        this.resetPaginatorIndex();
        this.activeFilterSelected = this.optionsFilter.filter(
          (element) => {
            return element.value === chosenFilter.value;
          }
        )[0];
      },
      (err) => {
        this.policyService.policiesGhostElements = false;
        this.dataService.showDataLoader = false;
        this.dataService.handleErrors(err);
      }
    )
  }

  resetPaginatorIndex() {
    if (this.paginator) {
      this.paginator.pageIndex = 0;
    }
  }
}
