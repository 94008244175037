import { Component, Input, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { ModalService } from "src/app/services/modal.service";
import { TextService } from "src/app/services/text.service";
import { NgIf } from "@angular/common";
import { ButtonComponent } from "../button/button.component";
import { DataService } from "src/app/services/data.service";
import { NewAppointmentService } from "src/app/services/new-appointment.service";
import * as $ from 'jquery';
import { FileUploadService } from "src/app/services/file-upload.service";


@Component({
    selector: "app-button-dropdown",
    templateUrl: "./button-dropdown.component.html",
    styleUrls: ["./button-dropdown.component.scss"],
    standalone: true,
    imports: [ButtonComponent, NgIf]
})
export class ButtonDropdownComponent implements OnInit {
  @Input() isRegisterEmployee: boolean = false;
  @Input() mainButton: string;
  @Input() mainButtonArrowColor: "primary" | "white" | "black" = "white";
  showDropdown = false;

  constructor(
    private modalService: ModalService,
    private router: Router,
    public textService: TextService,
    public dataService: DataService,
    private newAppointmentService: NewAppointmentService,
    private fileUploadService: FileUploadService
  ) {}

  ngOnInit(): void {}

  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
  }

  openModal() {
    this.fileUploadService.setType(this.isRegisterEmployee);
    this.modalService.openModal("uploadFile");
  }

  goToRegister() {
    this.router.navigateByUrl("/register");
  }

  terminateEmployeeWork() {
    this.toggleDropdown()
    this.dataService.showNewAppointmentPopup = true;
    this.newAppointmentService.isItAppointmentForSpecificEmployee = false;
    this.newAppointmentService.isSingleWorkTermination = true;
    this.focusOnNewAppointmentPopup();
  }

  focusOnNewAppointmentPopup() {
    setTimeout(() => {
      $(".close").focus();
    }, 100);
  }
}
