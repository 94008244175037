// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EDM__form {
  padding: 2rem 0;
}
.EDM__form ::ng-deep .mat-form-field-wrapper {
  text-align: right;
}
.EDM__form ::ng-deep .mat-form-field-flex {
  background: rgba(240, 240, 240, 0.6);
}
.EDM__form ::ng-deep .mat-form-field .error-message {
  font-size: var(--font-size-error-message);
  padding-right: 10px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}
.EDM__form ::ng-deep .mat-form-field .error-message.step-one {
  font-size: 25px;
}

.EDM__controls-wrapper {
  display: flex;
  -moz-column-gap: 1rem;
       column-gap: 1rem;
  row-gap: 1.25rem;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
}

.EDM__container {
  width: 90%;
  max-height: 100%;
}
.EDM__container .EDM__form-title {
  font-size: 30px;
  text-align: start;
  margin-bottom: 1rem;
}
.EDM__container .EDM__form-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
}

.error-message {
  font-size: 16px;
  color: red;
  font-family: var(--main-font-regular);
  text-align: right;
  background-color: var(--body-background-color);
}
.error-message .mat-form-field {
  border-bottom: none;
}
.error-message.step-one {
  border-top: none;
  font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/generic-components/employee-details-modal/employee-details-modal.component.scss"],"names":[],"mappings":"AAAA;EACE,eAAA;AACF;AACI;EACE,iBAAA;AACN;AACI;EACE,oCAAA;AACN;AAGM;EACE,yCAAA;EACA,mBAAA;EACA,+BAAA;EACA,8BAAA;AADR;AAEQ;EACE,eAAA;AAAV;;AAOA;EACE,aAAA;EACA,qBAAA;OAAA,gBAAA;EACA,gBAAA;EACA,eAAA;EACA,2BAAA;EACA,mBAAA;AAJF;;AAOA;EACE,UAAA;EACA,gBAAA;AAJF;AAME;EACE,eAAA;EACA,iBAAA;EACA,mBAAA;AAJJ;AAOE;EACE,aAAA;EACA,uBAAA;EACA,mBAAA;AALJ;;AASA;EACE,eAAA;EACA,UAAA;EACA,qCAAA;EACA,iBAAA;EACA,8CAAA;AANF;AAQE;EACE,mBAAA;AANJ;AASE;EACE,gBAAA;EACA,eAAA;AAPJ","sourcesContent":[".EDM__form {\n  padding: 2rem 0;\n  ::ng-deep {\n    .mat-form-field-wrapper {\n      text-align: right;\n    }\n    .mat-form-field-flex {\n      background: rgba(240, 240, 240, 0.6);\n    }\n\n    .mat-form-field {\n      .error-message {\n        font-size: var(--font-size-error-message);\n        padding-right: 10px;\n        border-bottom-right-radius: 4px;\n        border-bottom-left-radius: 4px;\n        &.step-one {\n          font-size: 25px;\n        }\n      }\n    }\n  }\n}\n\n.EDM__controls-wrapper {\n  display: flex;\n  column-gap: 1rem;\n  row-gap: 1.25rem;\n  flex-wrap: wrap;\n  justify-content: flex-start;\n  align-items: center;\n}\n\n.EDM__container {\n  width: 90%;\n  max-height: 100%;\n\n  .EDM__form-title {\n    font-size: 30px;\n    text-align: start;\n    margin-bottom: 1rem;\n  }\n\n  .EDM__form-buttons {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n  }\n}\n\n.error-message {\n  font-size: 16px;\n  color: red;\n  font-family: var(--main-font-regular);\n  text-align: right;\n  background-color: var(--body-background-color);\n\n  .mat-form-field {\n    border-bottom: none;\n  }\n\n  &.step-one {\n    border-top: none;\n    font-size: 20px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
