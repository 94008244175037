export const text = {
  disclaimer: `אתר זה הינו אתר אמנון גור סוכנות לביטוח (2000) בע"מ וחברות קשורות עמה (להלן לשם הנוחות –"החברה"), אשר אינה סוכנות ביטוח ואינה עוסקת בייעוץ. תנאי השימוש הינם בכפוף לנוסח המופיע באתר. יודגש, כי המידע המופיע באתר אינו בגדר ייעוץ ו/או שיווק מכל מין וסוג ואינו תחליף להיוועצות עם בעל רישיון אשר יתאים את השירות באופן אישי לצרכי הלקוח; החברה אינה אחראית על עדכניות או מהימנות המידע, והמידע המחייב הינו המידע המופיע אצל הגופים המוסדיים בלבד ובהתאם אין להסתמך על המידע לכל צורך. כל הסתמכות על המידע המצוי באתר הינה על אחריות המשתמש בלבד. כל פעולה שתבוצע באמצעות האתר אינה מהווה אישור מטעם החברה ו/או מטעם כל גוף מוסדי לתקינות הפעולה והיא כפופה לאישור החברה והגופים המוסדיים.`,

  privacy: `מדיניות זו הינה מדיניות הפרטיות הנוהגת קבוצת גור הלוא היא אמנון גור סוכנות לביטוח (2000) בע"מ, וכן חברות אם ו/או בת ו/או אחות של סוכנות זו (להלן "הסוכנות"  ו- "האתר") לפי העניין.
    <br><br>
    המסמך כתוב בלשון זכר מטעמי נוחות בלבד, אך מתייחס לשני המינים כאחד. אנו מודים לך שהקדשת מזמנך לקריאת מסמך זה.
    <br><br>
    גישתך לאתר כפופה למדיניות הפרטיות המפורטת להלן ולמסמך תנאי השימוש המהווים יחד מסמך משפטי מחייב בינך לבין הסוכנות. השימוש באתר ייחשב להסכמה מצדך לכל התנאים, ההתניות וההודעות. 
    <br><br>

   
    <h2> שינויים במדיניות הפרטיות </h2>
    <br>
    הסוכנות שומרת לעצמה את הזכות, בהתאם לשיקול דעתה הבלעדי, לשנות ולעדכן את מדיניות הפרטיות בכל זמן נתון על ידי פרסום גרסה מעודכנת באתר. השינויים במדיניות ייכנסו לתוקפם בתוך עשרה (10) ימים מיום עדכון המדיניות, והמשך השימוש שלך באתר לאחר עשרת הימים יחשב כהסכמתך לשינויים שבוצעו. 
    <br>
    במקרה של עדכון מדיניות הפרטיות, אנחנו נעדכן את "תאריך עדכון" בראש המסמך. באחריותך לבדוק את מדיניות הפרטיות ותאריך העדכון שלה מעת לעת. בכל מקרה בו נבצע שינויים מהותיים במדיניות הפרטיות, נעדכן על כך באמצעות פרסום באתר האינטרנט במקום בולט ו/או באמצעות משלוח דואר אלקטרוני לכתובת המעודכנת במערכות הסוכנות. 

    <br><br>
    
    <h2> איסוף מידע</h2>
    <br>

    מידע שנאסף באופן אוטומטי: הסוכנות אוספת מידע אודות המבקרים באתר ובכלל זה עושה שימוש ב"Cookies" לצורך ניטור פעילות המשתמשים באתר והתייעלות השירות באתר באופן כללי.
    <br>
    Cookie הוא קובץ מידע קטן שאנחנו מעבירים למחשב או למכשיר הסלולרי שלך למטרות שמירת תיעוד אודות אופן השימוש שלך באתר. בעת כניסתך לאתר, אנחנו עשויים למקם קבצים אלה במחשב או במכשיר שלך.
    <br>
    אנו משתמשים בטכנולוגיה זו כדי לזהות אותך כלקוח, להתאים אישית שירותים ותוכן של הסוכנות, למדוד את האפקטיביות של פעולות לקידום מכירות, לעזור לוודא שאבטחת החשבון שלך נשמרת ולמזער סיכונים ולמנוע הונאות.
    <br>
    אתה חופשי לסרב לקבל את קובצי ה-Cookie שלנו אם הדפדפן או הרחבת הדפדפן שלך מאפשרים זאת, אלא אם קבצים אלה נחוצים למניעת הונאות או על מנת להבטיח את אבטחת אתר האינטרנט. עם זאת, סירוב לקבל קבצים אלה עלול להפריע לשימושך באתר האינטרנט. 
    <br><br>
    מידע שנמסר על ידך: לצורך קבלת השירותים עליך לספק לסוכנות מידע אישי ומזהה אודותיך כדוגמת כתובת דוא"ל, מספר תעודת זהות, מספר טלפון נייד וכל מידע אחר הרלוונטי לסוג השירות המבוקש. מידע זה משמש לצורכי אימות זהות המשתמשים וזכאותם לקבלת השירותים. בהתאם להוראות סעיף 11 לחוק הגנת הפרטיות, מובהר בזאת, כי ככלל אין חלה חובה חוקית למסור מידע לסוכנות אלא אם צוין אחרת, אך יובהר, כי אי העברת מידע בכלל או העברת מידע בלתי שלם עלולה לפגוע ביכולתה של הסוכנות להעניק את שירותיה, לרבות פגיעה בכיסויים ביטוחיים ו/או השפעה על תשלום תגמולי הביטוח. לצד האמור יובהר, כי ייתכן ולעניין פעולות מסוימות העברת המידע תהווה תנאי המתחייב בהתאם להוראות הדין (כגון מסירת מידע לצורך זיהוי לפי חוק איסור הלבנת הון, התש"ס-2000). במסירת פרטים אלה, הנך מצהיר כי הפרטים האישיים שמסרת לסוכנות אמינים ומדויקים וכי הם נמסרים בהסכמתך ומרצונך החופשי. המידע שייאסף מיועד למתן שירותי הסוכנות ואספקת מוצרים על ידי הסוכנות ו/או מי מטעמה ו/או צדדים קשורים.
    <br>
    מידע שנמסר על ידי משתמש על אחרים: מסירת מידע על ידי משתמש אודות אחרים תתבצע על אחריות מוסר המידע, ובכפוף לזכותו למסור את המידע לפי כל דין.
    <br><br>
    
    
    <h2> אזור אישי</h2>
    <br>
    באתר קיים אזור אישי עבורך ולנוחיותך בו תוכל לבצע פעולות ולצפות בפעולות שביצעת. בעת הרישום לאזור האישי עליך להזין מספר תעודת זהות ומספר טלפון נייד. לאחר הזנת הפרטים כאמור, יתקבל מסרון עם קוד למספר הטלפון שהוזן אשר עליך להזינו באתר.
    <br>
    אינך רשאי לאפשר לגורם אחר לבצע שימוש בחשבונך. הנך נדרש לדווח לסוכנות על כל שימוש לא מורשה בחשבון שלך על מנת שנוכל לנקוט בצעדים המתאימים. 
    <br>
    עליך לעדכן באופן מידי את הסוכנות בכל שינוי במספר הטלפון הנייד שברשותך או בכל פרט מזהה אחר.
    <br>
    האזור האישי מכיל את נתונים מהמסלקה הפנסיונית של משרד האוצר, ללא כל מניפולציות או שינויים שבוצעו על ידי הסוכנות. הסוכנות אינה אחראית על תקינות ואמינות המידע שהתקבל מהמסלקה הפנסיונית. 
    <br>
    קיימת אפשרות למשלוח הודעות לסוכנות דרך האזור האישי. כל מידע אשר מועבר לסוכנות בדרך זו, הינו באחריות המשתמש.
    <br>
    <br>
    <h2> שימוש במידע:</h2>
   
    <br>
    ביחס למידע אשר יתקבל אצלה ממשתמשי האתר ובכלל זה מידע אודות צדדים שלישיים (בני משפחה, עובדים, מוטבים, נתבעים בתביעות ביטוח, וכיוצ"ב), המידע נאסף לצורך מתן שירותים בתחומי הביטוח ובתחומים משיקים (כגון פיננסים), והוא דרוש לסוכנות על מנת להעניק את שירותיה, בהתאם להוראות כל דין. המידע שייאסף על ידי הסוכנות כולל מידע פרטי, ואף מידע רגיש כהגדרתו בחוק הגנת הפרטיות, תשמ"א-1981 (להלן – "חוק הגנת הפרטיות"), והוא ייאסף גם באמצעות "הצלבת" מידע עם מאגרים אשר יעבירו מידע לסוכנות (כגון מידע מחברות ביטוח, חברות מנהלות של קרנות פנסיה וקופות גמל, מעסיקים, עובדים, קרובים וכיוצ"ב) או שלסוכנות תהא גישה אליהם, וישמר במאגרי המידע של הסוכנות (מאגר לקוחות אמנון גור סוכנות לביטוח שמספרו 600003287)
   <br>
    המונח "שירותי הסוכנות" יתפרש באופן רחב וכולל גם אך לא רק את התנהלותה של הסוכנות בשיווק מוצרי ביטוח ופיננסים (לרבות באמצעות דיוור ישיר ואמצעים אלקטרוניים), במתן שירות למוצרים אלה, בתפעול הסדרים פנסיוניים, ניהולה הפנימי של הסוכנות, ובכלל זה עמידה בהוראות הדין והתנהלות שוטפת, שיפור השירותים, פילוח מידע וכיוצ"ב.
   <br><br>
   <h3> זכות העיון, תיקון ומחיקת המידע:</h3>
   
    <br>
    ניתן לעיין במידע בהתאם להוראות הדין וזאת באמצעות פנייה לסוכנות בדוא"ל si@gurg.co.il  או באמצעות פנייה למשרדי הסוכנות.
    <br>
    אנחנו נשיב לך על בקשתך בהקדם האפשרי ונאפשר לעיין במידע אודותייך לא יאוחר מ-30 יום מיום הגשת הבקשה. 
    <br>
    הנך זכאי, לאחר שעיינת במידע אודותיך ומצאת כי הוא אינו נכון, שלם, ברור או מעודכן, לבקש לתקן או למחוק את המידע אודותייך. לצורך כך, הנך מוזמן לפנות אלינו באמצעות משלוח דואר אלקטרוני לכתובת si@gurg.co.il . אנחנו נשיב לך על בקשתך בהקדם האפשרי ולא יאוחר מ-30 יום מיום הגשת הבקשה.
   <br>
   <br>
   
    <h2>  העברת מידע לצדדים שלישיים:</h2>
    <br> 
    ככלל, הסוכנות  לא תעביר לצד ג' כלשהו את פרטיך האישיים, לרבות כל מידע שנאסף ביחס אליך במהלך שימושך באתר (ככל שפרטים ומידע זה מזהים אותך אישית), אלא במקרים המפורטים להלן:
    <br> <br>
    יובהר, כי ייתכן שמידע אשר יימסר לסוכנות יועבר לצדדים שלישיים לצורך מתן שירותי הסוכנות (כגון העברת מידע לחברות ביטוח וחברות מנהלות של קרנות פנסיה וקופות גמל – אשר הסוכנות פועלת כשלוחה שלהן לעניין העברת מידע למעסיק) ככל שהדבר נדרש ורלבנטי לצורך מתן השירותים; 
    <br>
    לנותני שירות נוספים הנחוצים מטעם הסוכנות; 
    <br>
    בהסכמתך לגורמים שלישיים נוספים ואחרים. 
    <br>
    למען הסר ספק, רשאית הסוכנות להעביר את המידע לצדדים שלישיים המשמשים אותה כספקים, וכן בכל מקרה של מיזוג ו/או העברת פעילות וכיוצ"ב. יובהר, כי אין באמור כדי לגרוע משימוש במידע בהתאם להוראות כל דין.
    <br>
    מטעמים משפטיים: במקרה שהמשתמש יפר את תנאי השימוש באתר; אם יתקבל בידי הסוכנות צו שיפוטי המורה לה למסור את פרטיך או המידע אודותיך לצד שלישי; בכל מחלוקת, טענה, תביעה, דרישה או הליכים משפטיים, בינך לבין הסוכנות; בכל מקרה שהסוכנות תהיה סבורה שמסירת המידע נחוצה כדי למנוע נזק חמור לגופך/לרכושך או לגופו/לרכושו של צד ג'; בכל מקרה בו הסוכנות תעמוד בפני איום לנקיטת צעדים משפטיים (פליליים או אזרחיים) כנגדה, בגין פעולות שנעשו על ידך באתר; אם תתקבל אצל הסוכנות טענה או תלונה לפיה הפרת כל דין, לרבות ומבלי לגרוע מן האמור, ביצעת עוולה או עבירה בניגוד לדיני לשון הרע, דיני הגנת הפרטיות, דיני הקניין הרוחני, דיני העונשין או דיני עוולות מסחריות. במקרה זה תהא הסוכנות רשאית למסור את המידע אודותיך לצד הטוען כי נפגע ממך או בהתאם להוראות הצו השיפוטי; בכל מקרה בו מסירת המידע תהא נחוצה כדי להיענות לדרישת בית משפט ו/או ערכאה שיפוטית אחרת ו/או דרישות חוק אחרות, ו/או להקים ו/או לממש את הזכויות החוקיות של הסוכנות וכן לאכוף אותן, למניעת פעילויות כוזבות ובלתי חוקיות, להגן על הסוכנות נגד תביעות ולשמור ולהגן על הזכויות, הנכסים או הבטיחות של המשתמשים שלנו, הציבור או הסוכנות. 
    <br> <br>
    <h2> שיווק ודיוור ישיר:</h2>
   
    <br>
    לא נמכור או נשכיר את פרטייך האישיים לצד שלישי כלשהו לצרכיו השיווקיים, ללא הסכמתך המפורשת ולא נפעל בו בניגוד להוראות כל דין. 
    <br> <br>
    אם אינך מעוניין לקבל פרסומים שיווקיים מהסוכנות תוכל לבטל הסכמה זו בכל עת באמצעות כניסה ללינק המופיע בתחתית הדואר האלקטרוני שתקבל מאתנו המכיל פרסומים אלה.  
    <br> <br>
  
    <h2>   קישורים לאתרים של צדדים שלישיים</h2>
    <br> 
    האתר עשוי להכיל קישורים לאתרים או משאבי אינטרנט אחרים. כאשר תקליקו על אחד מקישורים אלה תתחברו לאתר או משאב אינטרנט אחר, אשר עלול לאסוף מידע אודותיכם מרצונכם או באמצעות Cookies או טכנולוגיות אחרות. הסוכנות אינה אחראית ואינה שולטת באתרים או במשאבים האחרים או באיסוף המידע האישי שנעשה בהם, בשימוש בו או בגילויו. עליכם לבדוק את מדיניות הפרטיות באתרים אלה ובמשאבי האינטרנט האחרים, כדי להבין כיצד הם אוספים מידע ומשתמשים בו.
    <br> <br>
    <h2>     הונאות מקוונות ואבטחת מידע    </h2>
    <br>
    מוסדות פיננסיים מהווים יעדים לניסיונות הונאה של גורמים שונים, אשר ינסו לחדור לאתר ולפרטיך האישיים, כגון באמצעות פנייה בדוא"ל מתחזה או דומה לסוכנות תוך בקשה למסירת פרטי זיהוי, לרבות באמצעות קישור לאתרים דומים, נבהיר, כי הסוכנות לעולם לא תדרוש ממך להעביר את הסיסמא שלא במסגרת כניסה לאתר. מוצע להיזהר מכל פעילות כאמור. 
    <br> <br>
    יובהר, כי קיימת סכנה לחדירה למאגרי הנתונים או לפריצות אבטחת מידע, והסוכנות לא תהיה אחראית לנזק שייגרם בפרט בעת שימוש ברשת האינטרנט אך יובהר, כי לא ניתן להבטיח באופן מוחלט את מניעת הגעת המידע לצד שלישי, והסוכנות לא תהא אחראית לנזק שייגרם כתוצאה מפעילות כאמור.
    <br> <br>
    המידע ישמר על ידי הסוכנות תוך נקיטת אמצעי אבטחת מידע בהתאם להוראות הדין. הסוכנות רואה חשיבות רבה בשמירתו של המידע והגנתו, והיא מיישמת אמצעים לשם אבטחת המידע בהתאם לדיני הגנת הפרטיות, אבטחת מידע וההסדרים המקובלים בענף. הסוכנות נוקטת בטכנולוגיות אבטחה למניעת גישה למידע על ידי גורמים מורשים. במסגרת אמצעי האבטחה נקבעו הסדרים לזיהוי והצפנה של נתוני ההזדהות של המשתמשים באתר.
    <br> <br>
    יחד עם זאת, אבטחת המידע דורשת שיתוף פעולה מהמשתמש, ובהתאם נדרשים המשתמשים להיות מודעים לסכנות, ולחשיבותן של הסיסמאות ושמירתן לצורך שימוש אישי בלבד, אספקת אמצעי פרטי קשר נכונים ומתאימם וכל דרישה מוקבלת נוספת.
    <br> <br>
    <h3>להלן כללים והמלצות לעניין שמירת הפרטיות:</h3>
    <br>
    
    
    מומלץ להימנע משימוש בסיסמאות בתווך ציבורי (כגון מחשבים משותפים וכיוצ"ב);
    <br>
    יש לוודא שכתובת השולח מתאימה ואינה כוללת שגיאות (לתשומת לב יכול שהתכתובת המתחזה תכלול שגיאה קטנה);
    <br>
    יש לבדוק את תוכן ההודעה ולוודא שהוא בנוסח תקני ללא שגיאות כתב או ניסוח;
    <br>
    מומלץ להקליד את כתובת האתר באופן ישיר או לבדוק את כתובת הקישור ונכונותה.
    <br>
    יש לשים לב להודעות באמצעות אפליקציות שונות ולוודא הקישורים המצורפים אליהן;
    <br>
    מומלץ להקפיד על שימוש בתכנות הגנה מוכחות ועדכניות.
    <br>
    מומלץ לנעול את המחשב בעת עזיבת העמדה לזמן לא מוגבל או בסיום השימוש לבצע התנתקות מהאתר.
    <br> <br>
    בכל מקרה של חשש לדליפת אמצעי הזיהוי ו/או כל שימוש בלתי מורשה אחר, אנא פנה מיד לפניות הציבור בכתובת דוא"ל: <a href="mailto:Si@gurg.co.il">Si@gurg.co.il</a>
`,

  terms: `אתר זה (להלן – "האתר") הינו אתר אמנון גור סוכנות לביטוח (2000) בע"מ ו/או חברות קשורות אליה מקבוצת "אמנון גור" (להלן  יחדיו לשם הנוחות – "החברה"):
<br>
<br>
  <h2> כללי</h2>

האמור באתר הינו בלשון זכר לשם הנוחות בלבד.
<br>
הכותרות הובאו לשם הנוחות בלבד, ואין לייחס להן משמעות פרשנית.
<br>
השימוש באתר מיועד לבגירים אזרחי/תושבי ישראל בלבד.
<br><br>
<h2> השימוש באתר</h2>

הנך מתבקש לקרוא בעיון את תנאי השימוש לפני השימוש באתר. הגלישה והשימוש באתר  הינם בכפוף להוראות תנאי שימוש אלה. אם אינך מסכים לכל תנאי השימוש, אל תעשה שימוש באתר. גלישה ושימוש באתר מהווים הסכמה מצדך להוראות ולתנאים אלה, והתחייבותך לפעול על פיהם.
<br><br>

<h2>גילוי נאות</h2>

אמנון גור סוכנות לביטוח (2000) בע"מ וסוכנויות אחרות הנמנות עם החברה הינן סוכנויות ביטוח בעלות זיקה למוצרים המשווקים על ידן, ואינן עוסקות בייעוץ פנסיוני. גילוי נאות לגבי כל אחת מהסוכנויות מצורף בקישור <a href="https://gurgroup.co.il/%d7%92%d7%99%d7%9c%d7%95%d7%99-%d7%a0%d7%90%d7%95%d7%aa-%d7%9c%d7%a9%d7%99%d7%95%d7%95%d7%a7-%d7%a4%d7%a0%d7%a1%d7%99%d7%95%d7%a0%d7%99/" target="_blank">גילוי נאות</a>.
<br><br>
<h2>הבהרה בעניין השירות</h2>

האמור באתר אינו מהווה שירותי ייעוץ ו/או שיווק מכל סוג.
<br> 
החברה אינה בעלת רישיון על פי חוק הסדרת העיסוק בייעוץ השקעות, בשיווק השקעות ובניהול תיקי השקעות, תשנ"ה-1995, והשירותים באתר לא מהווים ייעוץ השקעות ו/או ניהול תיקי השקעות.
<br> 
אין לראות באמור באתר בכל צורה ואופן משום המלצה בדבר כדאיות החיסכון באמצעות מוצר פנסיוני ו/או בדבר כדאיות הביצוע של עסקה כלשהי ו/או המלצה ו/או הצעה ו/או ייעוץ ו/או שידול כלשהו לבצע פעולה כלשהי ו/או חוות דעת בכל הנוגע להתאמת ביצוע פעולה כלשהי לצרכיו של המשתמש .
<br> 
יובהר ויודגש, כי פעולה שתתבצע באמצעות האתר, תתבצע כפעולה עצמאית על פי שיקול דעתו הבלעדי של הלקוח ללא מתן המלצה על ידי החברה. מובהר בזאת, כי בכל מקרה מומלץ להיוועץ בבעל רישיון, וכי הליך השיווק הפנסיוני נועד להתאים את המוצר הפנסיוני ללקוח.
<br> 
 החברה רשאית לבצע שירותי שיווק פנסיוני באמצעות האתר, ובמקרה כזה יינתנו השירותים באמצעות בעל רישיון מטעם החברה בלבד, בהתאם לתנאי הרישיון, ובהתאם להוראות חוק הפיקוח על שירותים פיננסיים (ייעוץ, שיווק ומערכת סליקה פנסיוניים), תשס"ה-2005 וכל הוראה מכוחו והדבר יובהר ללקוח מראש.
 <br> 
החברה רשאית לעשות שימוש באתר לצורך תיווך בביטוח, בקשר עם מוצרי ביטוח שאינם מוצרים פנסיוניים ואשר אינם מחייבים שיווק פנסיוני ובמקרה כזה יחולו החובות החלות עליה כסוכן ביטוח, שימוש כאמור יובהר ללקוח מראש.
<br> <br> 
<h2>קניין רוחני</h2>

האתר מכיל נתונים ותכנים המוגנים על ידי זכויות יוצרים, סימני מסחר ושאר זכויות קנייניות. כמו כן, האתר כמכלול מוגן כיצירה שלמה. אין לעשות כל פעולה שיש בה כדי לפגוע בזכויות קנייניות אלה. חל איסור להכניס שינויים, לשדר, להעביר, להפיץ, לשכפל, להציג, לפרסם, להעתיק ו/או לעשות כל שימוש במידע (לרבות נטילת נתונים –  Download למעט נטילת נתונים אישיים לצורך שימוש אישי בלבד), התכנים והשירותים באתר ו/או שמקורם באתר, כולם או חלקם ללא הסכמת החברה מראש ובכתב.
<br>
מבלי לגרוע מהאמור לעיל חל איסור לעשות כל פעולה באתר ו/או בתכניו ו/או במידע כלשהו שיש בו כדי לפגוע בזכויות הקניין של החברה ו/או של צד שלישי ו/או לעשות בהם כל שימוש מסחרי.
<br> <br> 
<h2>מידע פרטי</h2>

מסירת מידע אישי באמצעות האתר תתבצע מרצונו החופשי של המשתמש. כל מידע שיימסר על ידי המבקרים באתר לרבות במסגרת הרישום לאתר ו/או קבלת שירותים באמצעותו ו/או כל פנייה לאתר יישמר במאגרי המידע של החברה לצורך הצעת הצעות ו/או מתן שירותים בתחומי הביטוח והפיננסים ו/או לצרכי עיבוד סטטיסטי ו/או דיוור ישיר ו/או פילוח ו/או מיקוד שירותים ו/או שיפור השירות של החברה ו/או של האתר, ויחולו הוראות מדיניות הפרטיות המפורטות בקישור (Link) הבא: _________________.
מבלי לגרוע מהאמור לעיל יובהר, כי החברה תמסור כל מידע, לרבות מידע פרטי בהתאם לדרישה של כל רשות.
<br> <br> 
<h2>המידע באתר</h2>

האתר כולל מידע אודות החברה, לרבות מידע אודות מגוון השירותים אותם מענקיה החברה. לעתים, המידע הנכלל באתר אינו מפרט את כל התנאים, הסייגים וההוראות החלים על המוצרים והשירותים המסופקים על ידי החברה והוא מוצג באופן כללי בלבד, וכך יש להתייחס אליו. מבלי לגרוע מהאמור לעיל, בכל מקרה של סתירה ו/או אי התאמה בין המידע המופיע באתר לבין המידע המופיע בתכניות הביטוח או במסמכים מחייבים אחרים, או במערכות הגופים המוסדיים ו/או במערכות החברה יקבע אך ורק המידע המעודכן אצל הגופים המוסדיים או אם מדובר המצוי במערכות החברה בלבד המידע המצוי במערכות החברה.
החברה פועלת על מנת לוודא את אמינות, שלמות, דיוק ועדכון המידע המופיע באתר. עם זאת, ייתכן ובמידע שמופיע באתר נופלים שיבושים ו/או שגיאות ו/או אי דיוקים.
יובהר, כי אין להסתמך על המידע באתר לביצוע כל פעולה והוא אינו מהווה מצג כלשהו מטעם החברה ו/או מטעם כל צד שלישי, וכל הסתמכות עליו תהא באחריות המשתמש בלבד. החברה ו/או כל צד שלישי לא יהיו אחראים לנזקים בגין הסתמכות ו/או שימוש במידע כאמור.
<br> <br> 
<h2>קישורים (Link)</h2>

בכל מקרה של קישור או הפנייה בכל דרך מהאתר לאתר ו/או לכל מקום אחר  החברה לא תהיה אחראית בכל צורה ואופן לאתר אליו תתבצע הפנייה כאמור, ובכלל זה לתכניו, לשימוש בו, למידע המופיע בו וכל עניין אחר.
<br> <br> 
<h2>שימוש אסור</h2>
הנך מתחייב שלא לעשות שימוש באתר למטרות האסורות על פי תנאי השימוש באתר ו/או על פי דין ו/או לכל מטרה בלתי חוקית. מבלי לגרוע מכלליות האמור לעיל, הינך מתחייב שלא לעשות שימוש באתר ובתכניו, להעלות לאתר, לשלוף, לשדר, להפיץ או לפרסם מידע או חומר אחר, אשר הינו בין היתר:
אסור לפרסום או בגדר איום, פגיעה, הוצאת לשון הרע/דיבה, פורנוגרפיה או ביטוי פוגעני אחר;
כולל פרסומת מכל סוג שהוא בלי הרשאה מפורשת מראש ובכתב של החברה;
עלול להפר זכויות של אחר, לרבות זכויות קניין רוחני, זכויות יוצרים, פטנטים, זכויות להגנת הפרטיות ו/או כל זכות קניינית אחרת;
עלול להגביל או למנוע מאחרים את השימוש באתר;
עלול לפגוע באתר ו/או במשתמשים באתר לרבות כל וירוס או תוכנה אחר העלולים לגרום לפגיעות כלשהן.
בהעלותך מידע כלשהו לאתר, הינך מעניק לחברה אישור בלתי מוגבל ובלתי חוזר, לעשות במידע כל שיידרש לפי שיקול דעתה הבלעדי כדי להשיג את המטרות החוקיות בגינן הועלה המידע לאתר, והינך מוותר בזאת על כל טענה ו/או תביעה נגד החברה בעניין הפרת זכות קניין או פרטיות בכל הנוגע למידע. יובהר למען הסר ספק, כי מידע שהועבר לצורך טיפול בעניין פרטי של הלקוח יישמר בהתאם להוראות הסודיות והפרטיות החלות על החברה.
יובהר, כי החברה אינה יכולה לפקח על כל מידע ו/או הודעה המועלים לאתר על ידי משתמשים אחרים והיא לא תהיה אחראית לכל נזק ו/או הפסד העלול להיגרם כתוצאה מהאמור.
<br> <br> 
<h2>נזקים</h2>
האחריות לשימוש באתר ועל תוצאותיה הינה שלך בלבד, והחברה לא תישא בכל אחריות בגין כל נזק ו/או הפסד ישיר ו/או עקיף (לרבות אבדן נכסים ו/או הזדמנויות) העלול להיגרם כתוצאה משימוש באתר. מבלי לגרוע מכלליות האמור לעיל
החברה אינה אחראית לכל נזק או הפסד  שעלול להגירם עקב שימוש באתר, לרבות פגם או תקלה באתר, ולרבות בגין נזק שייגרם למחשב האישי של המשתמש ו/או כל ציוד אחר באמצעותו מתבצעת ההתחברות לאתר (להלן – "מחשב אישי").
החברה אינה אחראית בכל מקרה של הפסקת ו/או ביטול פעילות האתר ו/או שירותי האתר ו/או כל חלק מהם.
הינך מתחייב לשפות ולפצות את החברה, מנהליה, נושאי המשרה בה, בעלי מניות, עובדיה ו/או סוכניה ו/או כל הבאים מכוחה כנגד כל תביעה, דרישה והוצאה, לרבות שכ"ט עו"ד, הנובעים מכל הפרה שלך את הוראות כל דין ו/או תנאי השימוש באתר זה.
רישום לאתר ושימוש בסיסמאות
השימוש באינטרנט לצורך גישה למידע אישי חושף את המשתמש לסיכונים הנובעים ממבנה רשת האינטרנט ומהשימוש במחשב אישי ו/או כל ציוד אחר המאפשר התחברות לרשת. בעת שימוש באזור האישי עליך לשמור על הסיסמאות, וכל אמצעי אבטחת המידע בסודיות מוחלטת ולמנוע כל שימוש בלתי מורשה בהם ו/או במחשב האישי.

<br> <br> 
<h2>עדכון תנאי השימוש</h2>
החברה תהא רשאית לעדכן מעת לעת את תנאי השימוש באתר לפי שיקול דעתה המוחלט והבלעדי, ותנאי השימוש המחייבים בכל עת יהיו תנאי השימוש המפורסמים באתר.
<br> <br> 
<h2>דין וסמכות</h2>
על השימוש באתר יחולו דיני מדינת ישראל בלבד, ומקום השיפוט הבלעדי בכל ענין ומחלוקת בנוגע לאתר ו/או השימוש בו יהיה בית המשפט המוסמך בתל-אביב. תנאי שימוש אלה יהוו את הבסיס לדיון המשפטי. במקרה בו ייקבע על פי דין ו/או על ידי ערכאה שיפוטית ו/או כל גורם שיפוטי מוסמך אחר שהוקם על פי דין, כי חלק כלשהו מתנאי השימוש אינו תקף או שאינו ניתן לאכיפה, אזי הסעיפים שנשלל תוקפן או שאינם ניתנים לאכיפה ייחשבו כאילו הוחלפו בסעיפים תקפים הניתנים לאכיפה, שתוכנם תואם, במידת הקירוב הגדולה ביותר את כוונת הסעיפים המקוריים, ואילו יתר סעיפי תנאי השימוש יישארו בתוקפם.
`,
  accessibility: `אתר אינטרנט נגיש הוא אתר המאפשר לאנשים עם מוגבלות ולאנשים מבוגרים לגלוש באותה רמה של יעילות והנאה ככל הגולשים.
כ- 20 עד 25 אחוזים מהאוכלוסיה נתקלים בקשיי שימוש באינטרנט ועשויים להיטיב מתכני אינטרנט נגישים יותר, כך על פי מחקר שנערך בשנת 2003 ע"י חברת מייקרוסופט.
<br>
גור קבוצת ביטוח מאמינה ופועלת למען שיוויון הזדמנויות במרחב האינטרנטי לבעלי לקויות מגוונות ו/או המסתייעים בטכנולוגיות עזר לשימוש במחשב.
<br>
<br>

<h2> תו אתר נגיש </h2>

תו "אתר נגיש" הינו יוזמה של עמותת "נגישות ישראל" ואיגוד האינטרנט הישראלי. התו מוצב באתרים אשר בוצעו בהם ההתאמות הדרושות כדי להפכם לנגישים לאנשים עם מוגבלות.
מטרת הצבת התו היא קידום המודעות הציבורית לנגישות לאינטרנט ויידוע ציבור הגולשים באתר אודות מידת נגישותו.
<br>
 <br>

 <h2> נגישות האתר</h2>

אתר זה עומד בדרישות תקנות שיוויון זכויות לאנשים עם מוגבלות (התאמות נגישות לשירות), התשע"ג 2013.
האתר תואם את המלצות התקן הישראלי (ת"י 5568) לנגישות תכנים באינטרנט ברמת AA ואת המלצות מסמך WCAG2.0 מאת ארגון W3C.
כמו כן, מותאם לתצוגה בדפדפנים הנפוצים ולשימוש בטלפון הסלואלרי.

<br>
<br>

<h2> תיקונים שבוצעו</h2>
באתר זה בוצעו התאמות נגישות קפדניות הכוללות בין היתר מימוש סמנטי ואינפורמטיבי עבור טכנולוגיות מסייעות, קביעת ניווט והפעלה עם מקלדת בלבד עפ"י המלצות ארגון התקינה ברשת הבינלאומי (W3C), התאמות צבעים וכן הוספת תוויות עזר והנחיות בטפסים לטובת כלל הגולשים.כמו כן, לשם קבלת חווית גלישה מיטבית עם תוכנת הקראת מסך, אנו ממליצים לשימוש בתוכנת NVDA העדכנית ביותר.

<br><br>


<h2>חלק מהדברים שבוצעו באתר</h2>
הנגשנו את האתר באופן שמאפשר שליטה על גדלי פונטים, מעקף בלוקים, טקסטים מנחים לקורא מסך מסוג NVDA ו JAWS, ניווט מקלדת המקל על גלישה באתר ללא שימוש עכבר, התאמות לתאימות האתר לגלישה עם קורא מסך מסוג NVDA ו JAWS, יצירת נוחות לצפיה באתר בגווני שחור לבן/ ניגודיות צבעים, לחסום אנימציה וכל רכיב הזז באתר ולהבליט קישורים.

 יש לציין כי למרות מאמצנו להנגיש את כלל הדפים באתר, המסמכים המצורפים (כגון PDF, Word, Excel)מערכת ניהול פנימית וקבצי וידאו מצויים עדיין בשלבי הנגשה ואנו פועלים להשלים זאת.
 <br>
במידה ונדרש לכם מסמך המופיע באתר והוא אינו נגיש, אנא פנו אלינו עם קישור למקום ונדאג לכך בהקדם.
<br>
במידה ומצאתם רכיב או עמוד שלא נגיש באתר נשמח אפ תפנו אלינו ואנחנו נדאג לתקן בהקדם.
<br>

<br>

<h2>נגישות המקום</h2>
במקום יש גישה לאנשים עם מוגבלויות

<br>
<br>

<h2>יצירת קשר</h2>
אם במהלך הגלישה באתר נתקלת בבעיה בנושא נגישות נשמח אם תדווחו לנו ואנחנו נדאג לתקן בהקדם.

<br><br>


<h2>פרטים של רכז נגישות בארגון</h2>
שם: אדר גור
<br>
טלפון: 050-6485549
<br>
אימייל: <a href="mailto:adar@gurg.co.il">Adar@gurg.co.il</a>`,
  login_error_message:
    "לא נמצאה התאמה בין הנתונים שהוקלדו לנתונים הקיימים במערכת, נשמח לעמוד לשירותך במוקד שירות הלקוחות בטלפון" +
    ' <a href="tel: *2986">2986*</a>',
  missingOTP: "לא קיבלת את הקוד? אפשר לנסות שוב או ליצור איתנו קשר ב-2986*",
  otp_error_message: "קוד אימות שגוי",
  otp_too_many: "בוצעו מספר רב מידי של נסיונות, אנא נסה שנית מאוחר יותר",
  missingOTPPart1: "",
  missingOTPPart2: "",
};

export const tooltipText = {
  retirementAllowance: "סכום כסף שאפשר למשוך במלואו בגיל הפרישה.",
  retirementFortune: "סכום כסף שאפשר למשוך בגיל פרישה כתשלום חודשי בלבד.",
  total_employer_rewards: "סך הכסף שהופרש על-ידי המעסיק לחסכון שלך.",
  total_employee_rewards: "סך הכסף שהופרש על-ידך לחסכון שלך.",
  total_compensation:
    "סך הכסף שהופרש על-ידי המעסיקים שלך לפיצויים שמגיעים לך במקרה של פיטורים.",
  management_fee_from_accumulation:
    "דמי ניהול שנגבים כל שנה, כאחוז קבוע מסך הכסף.",
  management_fee_from_deposit: "דמי ניהול שנגבים כל שנה, כאחוז קבוע מסך הכסף.",
  investment_track_name:
    "המסלול בו מושקע הכסף שלך. מסלולי ההשקעה שונים זה מזה ברמת הסיכון ובהתאם גם בביצועים שלהם.",
  investment_track_net_yield:
    "אחוז השינוי המצטבר בתכנית החסכון ב-12 החודשים האחרונים.",
  type: "מסלולי הביטוח שונים זה מזה בהרכב האחוזים המיועדים לחיסכון ולפיצויים. אפשר לשנות את מסלול הביטוח בהתאם למצב המשפחתי שלך ולהעדפותיך.",
  predicted_retirement_amount: "קצבה חודשית מוערכת, מבוססת על צבירה קיימת.",
  disability_retirement:
    "קצבה חודשית המוכרת גם כאובדן כושר עבודה. גובה הקצבה והזכאות לה משתנה בין קרנות.",
  widow_retirement: "קצבה חודשית שנותנת מענה לבן/בת הזוג במקרה של פטירה.",
  orphant_retirement:
    "קצבה חודשית שנותנת מענה לילדים עד גיל 21 במקרה של פטירה.",
  deposit: "סך הכסף שהועבר על-ידך ועל-ידי המעסיק שלך לתוכנית החסכון בכל חודש.",
  Mutavim: "מי שיקבלו את הכספים שלך במקרה של פטירה",
  total_amount_from_last_employer: "סך כל הפיצויים שקיבלת מהמעסיק הקודם שלך",
  retirementPaidAllowance:
    "סכום כסף שאפשר למשוך בגיל פרישה כתשלום חודשי בלבד, על-ידי ניוד הכסף לקופה משלמת.",
  client_program_type:
    ".מעמד העמית משתנה בהתאם להפקדות הכספים שנעשו במשך תקופה",
  premium: "סך הכל התשלום החודשי שלך.",
  insured_amount: "הסכום המקסימלי שישולם במידה והביטוח יופעל.",
  waiting_period: "משך הזמן בו ישנה זכאות לקבלת שיפויֿֿ/ֿפיצוי.",
  pensionInformation: "החישוב מתבצע לפי גיל 64 לנשים וגיל 67 לגברים",
  total_47_rewards: "הפקדה מעבר לתקרה לפי סעיף 45 ו-47 לפקודת מס הכנסה.",
};

export const WorkTerminationTooltipText = {
  'lastMonthlySalary': 'משכורת חודשית אחרונה של העובד',
  'lastInsuredSalary': 'שכר לפיו שולם מרכיב הפיצויים לקופ"ג',
  'salaryCompensationLiability': 'השכר לצורך חישוב הפיצויים לפי חוק פיצויי פיטורים',
  'foreignLanguage': 'למקרים בהם המבוטח אינו יכול בשום אופן לבצע תהליך בעברית, ואנו הסוכן המטפל',
  'comments': 'במידה ופרמיה אחרונה הופרשה ליותר מקופה אחת יש לציין פירוט בהערות',
  'authorizedToSignForm': 'החותם בפועל על הטופס מטעם המעסיק',
  'authorizedToViewForm': 'במידה ויש צורך בגורם מאשר מטעם המעסיק לפני חתימה על הטופס'
}