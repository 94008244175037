// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.stages {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.stage-img {
  padding-top: 10px;
}

.app-breadbrumbs {
  display: none;
}

@media screen and (min-width: 600px) {
  .desktop ::ng-deep .bread-crumbs-wrapper {
    width: 96%;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/pages/register-employee/register-employee.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,sBAAA;EACA,uBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EAGM;IACE,UAAA;EADN;AACF","sourcesContent":[".stages {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n}\n\n.stage-img {\n  padding-top: 10px;\n}\n\n.app-breadbrumbs {\n  display: none;\n}\n\n@media screen and (min-width: 600px) {\n  .desktop {\n    ::ng-deep {\n      .bread-crumbs-wrapper {\n        width: 96%;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
