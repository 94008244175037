export enum TabValue {
  CORRECT = "CORRECT_RECORDS",
  INCORRECT = "INCORRECT_RECORDS",
}

export interface ITableData {
  correct: Array<{}>;
  incorrect: Array<{}>;
}

export interface ISelectOption {
  value: string | boolean;
  label: string;
}

export interface IValidationMessage {
  type: string;
  message: string;
}
