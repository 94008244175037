import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from "@angular/router";
import { Observable } from "rxjs";
import { IpService } from "../services/ip.service";

@Injectable({
  providedIn: "root",
})
export class IpGuardGuard  {
  constructor(private router: Router, private ipService: IpService) {}

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.canActivateGuard();
  }

  canActivateGuard(): Promise<boolean> {
    return new Promise((resolve) => {
      this.ipService
        .validateIP()
        .then((valid) => {
          if (valid) {
            resolve(true);
          } else {
            this.router.navigateByUrl("/login");
            resolve(false);
          }
        })
        .catch((e) => {
          this.router.navigateByUrl("/login");
          resolve(false);
        });
    });
  }
}
