import {
  Component,
  OnInit,
  Input,
  AfterViewInit,
  ElementRef,
} from "@angular/core";
import { environment } from "src/environments/environment";
import { Router } from "@angular/router";
import { Location, NgIf, NgClass } from "@angular/common";
import { DataService } from "src/app/services/data.service";

@Component({
    selector: "app-header",
    templateUrl: "./header.component.html",
    styleUrls: ["./header.component.scss"],
    standalone: true,
    imports: [NgIf, NgClass]
})
export class HeaderComponent implements OnInit, AfterViewInit {
  name: string = environment.name;
  @Input() backBtn: boolean = false;
  @Input() showNote: boolean = true;
  @Input() showExtraNote: boolean = false;
  @Input() showEdith: boolean = false;
  @Input() showHeaderDetails: boolean = true;
  @Input() ExtraNoteText: string = "";
  @Input() textNote: string = "";
  @Input() borderImage: string = "";
  @Input() title: string = "";
  @Input() type: string = "";
  @Input() align: string = "";
  @Input() margin_right = "0";
  @Input() smallLine = "flase";
  @Input() past_appointments = "";
  @Input() future_appointments = "";
  @Input() total_appointments = "";
  opened: boolean = true;
  events: string[] = [];
  showFiller = false;

  constructor(
    public dataService: DataService,
    private router: Router,
    private _location: Location,
    public el: ElementRef
  ) {}

  ngOnInit(): void {}

  ngAfterViewInit(): void {
    var element = this.el.nativeElement.querySelector(".header-details");
    if (element) {
      element.setAttribute(
        "style",
        "margin-right:" + this.margin_right + "px;"
      );
    }
  }

  back() {
    this._location.back();
  }
}
