import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { RegisterPageService } from "src/app/services/register-page.service";
import { TextService } from "../../../services/text.service";
import { NgIf } from "@angular/common";
@Component({
    selector: "app-thank-you-register-employee",
    templateUrl: "./thank-you-register-employee.component.html",
    styleUrls: ["./thank-you-register-employee.component.scss"],
    standalone: true,
    imports: [NgIf]
})
export class ThankYouRegisterEmployeeComponent implements OnInit {
  SR = "";
  text;
  constructor(
    public dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    private registerPageService: RegisterPageService,
    public textService: TextService
  ) {}

  ngOnInit(): void {
    this.SR = this.registerPageService.SR_request;
    this.textService.setTextSource();

    this.text = this.textService.textSource[
      "FINISH_NEW_EMPLOYER_SCREEN_DESCRIPTION"
    ].replace(new RegExp("{name}", "g"), this.registerPageService.name_request);
  }

  goToHomePage() {
    this.router.navigateByUrl("/");
  }
  goToCreatePage() {
    this.router.navigateByUrl("/register");
  }
}
