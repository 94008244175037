import { Component, Input, OnInit } from "@angular/core";
import {
  breadcrumbs,
  BreadcrumbsLinkNames,
} from "src/app/utils/breadcrumbs-links";
import { NgFor, NgClass } from "@angular/common";

@Component({
    selector: "app-breadcrumbs",
    templateUrl: "./breadcrumbs.component.html",
    styleUrls: ["./breadcrumbs.component.scss"],
    standalone: true,
    imports: [NgFor, NgClass]
})
export class BreadcrumbsComponent implements OnInit {
  @Input() pageNames: Array<string>;
  currentBreadcrumbs = [];

  constructor() {}

  ngOnInit(): void {
    this.fillBreadcrumbs();
  }

  fillBreadcrumbs() {
    this.currentBreadcrumbs = this.pageNames.map((page, index) => {
      const foundPage = {
        ...breadcrumbs.find((breadc) => breadc.name === page),
      };
      if (index !== this.pageNames.length - 1) {
        foundPage.name = `${foundPage.name} > ` as BreadcrumbsLinkNames;
      }
      return foundPage;
    });
  }
}
