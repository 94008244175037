import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { PolicyService } from "src/app/services/policy.service";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
import { TextService } from "../../../services/text.service";
import { NoContentComponent } from "../../common/no-content/no-content.component";
import { MatLegacyPaginatorModule } from "@angular/material/legacy-paginator";
import { PolicyComponentComponent } from "../../generic-components/policy-component/policy-component.component";
import { HeaderComponent } from "../../common/header/header.component";
import { BreadcrumbsComponent } from "../../common/breadcrumbs/breadcrumbs.component";
import { NgIf, NgFor } from "@angular/common";
declare var $;
@Component({
  selector: "app-policy-archive",
  templateUrl: "./policy-archive.component.html",
  styleUrls: ["./policy-archive.component.scss"],
  standalone: true,
  imports: [NgIf, BreadcrumbsComponent, HeaderComponent, NgFor, PolicyComponentComponent, MatLegacyPaginatorModule, NoContentComponent]
})
export class PolicyArchiveComponent implements OnInit {
  policies = [
    { name: "", policy_number: "", end_of_work_date: "" },
    { name: "", policy_number: "", end_of_work_date: "" },
    { name: "", policy_number: "", end_of_work_date: "" },
    { name: "", policy_number: "", end_of_work_date: "" },
    { name: "", policy_number: "", end_of_work_date: "" },
    { name: "", policy_number: "", end_of_work_date: "" },
  ];

  elementary_policies_count = 0;
  foreign_workers_premium;
  foreign_employee_policies_count;
  paginatorLength;
  name;
  elementaryPolicy = { name: "פוליסות עובדים זרים", policy_number: "", end_of_work_date: "" }
  breadcrumbsPages = [
    BreadcrumbsLinkNames.HOME,
    BreadcrumbsLinkNames.COMPANY_POLICIES,
  ];

  constructor(
    public dataService: DataService,
    private policyService: PolicyService,
    public textService: TextService
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    this.name = this.dataService.name;
    this.dataService.showGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.policyService.getPolicy(0, 9).subscribe(
      (resp) => {
        this.dataService.showGhostElements = false;
        this.dataService.showDataLoader = false;
        this.policies = resp.policies;
        this.foreign_employee_policies_count =
          resp.foreign_employee_policies_count;
        this.elementary_policies_count = resp.elementary_policies_count;
        this.foreign_workers_premium = resp.foreign_workers_premium;
        this.paginatorLength = resp["pagination"]["total_count"];
      },
      (err) => {
        this.dataService.handleErrors(err);
      }
    );
  }

  onPageChange($event) {
    let startLength = $event.pageIndex * $event.pageSize;
    this.dataService.showGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.policyService.getPolicy(startLength, 9).subscribe(
      (resp) => {
        this.dataService.showGhostElements = false;
        this.dataService.showDataLoader = false;
        this.policies = resp.policies;
        setTimeout(() => {
          $(".policy").focus();
        });
      },
      (err) => {
        this.dataService.handleErrors(err);
      }
    );
  }
}
