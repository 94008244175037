import { Injectable } from "@angular/core";
import { HttpHandler } from "./httpHandler";
import { HttpClient } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { Router, NavigationEnd } from "@angular/router";

@Injectable()
export class TextService extends HttpHandler {
  textSource: any = {};
  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public router: Router
  ) {
    super(_http, httpService, errorService);
  }

  setTextSource() {
    if (sessionStorage.getItem("resource")) {
      this.textSource = JSON.parse(sessionStorage.getItem("resource"));
    }
  }

  public getResources() {
    let url = "/resources";
    return this.get(url);
  }
}
