import { Injectable } from "@angular/core";
import { HttpHandler } from "./httpHandler";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { DataService } from "./data.service";
import { TextService } from "./text.service";
@Injectable({
  providedIn: "root",
})
export class RegisterPageService extends HttpHandler {
  resourceText = this.textService.setTextSource();
  SR_request = "";
  validation_messages = {};
  name_request = "";
  constructor(
    public textService: TextService,
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService
  ) {
    super(_http, httpService, errorService);
  }

  getFormOptions() {
    const url = "/contact/task/new-employee/form-options/:null";

    return this.get(url);
  }

  getWorkTerminationReasons(){
    const url="/contact/task/form-options/get-work-termination-reasons";
    return this.get(url);
  }

  createEmployee(body) {
    const url = "/contact/task/new-employee/create";
    return this.post(url, body);
  }

  createEmployees(body) {
    const url = "/contact/task/new-employees/create";
    return this.post(url, body);
  }

  createEmployeesWorkTermination(body) {
   const url = "/contact/task/employees-work-termination";
    return this.post(url, body);
  }


  getValidationMessages() {
    this.resourceText = this.textService.setTextSource();
    this.validation_messages = {
      firstname: [
        { type: "required", message: "שדה חובה" },
        {
          type: "pattern",
          message: this.textService.textSource["ERROR_FIELD_CHARS_ONLY"],
        },
        { type: "maxlength", message: "יש להזין לכל היותר 40 תווים" },
        { type: "minlength", message: "יש להזין לפחות 2 תווים" },
      ],
      lastname: [
        { type: "required", message: "שדה חובה" },
        {
          type: "pattern",
          message: this.textService.textSource["ERROR_FIELD_CHARS_ONLY"],
        },
        { type: "maxlength", message: "יש להזין לכל היותר 40 תווים" },
        { type: "minlength", message: "יש להזין לפחות 2 תווים" },
      ],
      id: [
        { type: "required", message: "שדה חובה" },
        {
          type: "pattern",
          message: this.textService.textSource["ERROR_FIELD_ID_NUMBER"],
        },
        {
          type: "maxlength",
          message: this.textService.textSource["ERROR_FIELD_ID_NUMBER"],
        },
        {
          type: "minlength",
          message: this.textService.textSource["ERROR_FIELD_ID_NUMBER"],
        },
      ],
      gender: [{ type: "required", message: "יש להזין מין" }],
      familystatus: [{ type: "required", message: "שדה חובה" }],
      birthday: [
        {
          type: "required",
          message: "שדה חובה",
        },
        { type: "pattern", message: "DD/MM/YYYY" },
        { type: "matDatepickerMin", message: "DD/MM/YYYY" },
        { type: "matDatepickerFilter", message: "DD/MM/YYYY" },
        { type: "matDatepickerMax", message: "DD/MM/YYYY" },
      ],
      insuranceCar: [{ type: "required", message: "שדה חובה" }],
      insuranceHouse: [{ type: "required", message: "שדה חובה" }],
      phone: [
        { type: "required", message: "שדה חובה" },
        {
          type: "pattern",
          message: this.textService.textSource["ERROR_FIELD_NUMBERS_ONLY"],
        },
        {
          type: "maxlength",
          message: this.textService.textSource["ERROR_FIELD_PHONE"],
        },
        {
          type: "minlength",
          message: this.textService.textSource["ERROR_FIELD_PHONE"],
        },
      ],
      email: [
        { type: "required", message: "שדה חובה" },
        {
          type: "pattern",
          message: this.textService.textSource["ERROR_FIELD_EMAIL"],
        },
      ],
      city: [
        { type: "required", message: "שדה חובה" },
        { type: "maxlength", message: "יש להזין לכל היותר 40 תווים" },
        { type: "minlength", message: "יש להזין לפחות 2 תווים" },
      ],
      street: [
        { type: "required", message: "שדה חובה" },
        { type: "maxlength", message: "יש להזין לכל היותר 40 תווים" },
        { type: "minlength", message: "יש להזין לפחות 2 תווים" },
      ],
      number: [
        { type: "required", message: "שדה חובה" },
        { type: "maxlength", message: "יש להזין לכל היותר 8 תווים" },
        { type: "minlength", message: "יש להזין לפחות 2 תווים" },
      ],
      role: [
        { type: "required", message: "שדה חובה" },
        { type: "maxlength", message: "יש להזין לכל היותר 40 תווים" },
        { type: "minlength", message: "יש להזין לפחות 2 תווים" },
      ],
      startDate: [{ type: "required", message: "שדה חובה" }],
      employeeNumber: [
        { type: "maxlength", message: "יש להזין לכל היותר 40 תווים" },
        { type: "minlength", message: "יש להזין לפחות 2 תווים" },
        { type: "pattern", message: "יש להזין מספרים בלבד" },
      ],
      employeeSalary: [
        { type: "required", message: "שדה חובה" },
        { type: "pattern", message: "יש להזין מספרים בלבד" },
        { type: "maxlength", message: "יש להזין לכל היותר 5 תווים" },
      ],
      insuredSalaryEmployee: [
        { type: "required", message: "שדה חובה" },
        { type: "pattern", message: "יש להזין מספרים בלבד" },
        { type: "maxlength", message: "יש להזין לכל היותר 5 תווים" },
      ],
      employer_compensation: [{ type: "required", message: "שדה חובה" }],
      employee_compensation: [{ type: "required", message: "שדה חובה" }],
      allowance_compensation: [{ type: "required", message: "שדה חובה" }],
      date_pension_arrangement: [{ type: "required", message: "שדה חובה" }],
      allowance_compensation_other: [
        { type: "required", message: "שדה חובה" },
        { type: "pattern", message: "יש להזין מספרים בלבד" },
        { type: "max", message: "יש להזין מספרים קטנים מ - 10" },
        { type: "min", message: "יש להזין מספרים גדולים מ - 0" },
      ],
      employer_compensation_loss_work: [
        { type: "required", message: "שדה חובה" },
        { type: "pattern", message: "יש להזין מספרים בלבד" },
        { type: "max", message: "יש להזין מספרים קטנים מ - 10" },
        { type: "min", message: "יש להזין מספרים גדולים מ - 0" },
      ],
      employee_compensation_loss_work: [
        { type: "required", message: "שדה חובה" },
        { type: "pattern", message: "יש להזין מספרים בלבד" },
        { type: "max", message: "יש להזין מספרים קטנים מ - 10" },
        { type: "min", message: "יש להזין מספרים גדולים מ - 0" },
      ],
      is_up_to_the_ceiling_of_the_training_fund: [
        { type: "required", message: "שדה חובה" },
      ],
      employer_retirement: [
        { type: "required", message: "שדה חובה" },
        { type: "pattern", message: "יש להזין מספרים בלבד" },
        { type: "max", message: "יש להזין מספרים קטנים מ - 10" },
        { type: "min", message: "יש להזין מספרים גדולים מ - 0" },
      ],
      employee_retirement: [
        { type: "required", message: "שדה חובה" },
        { type: "pattern", message: "יש להזין מספרים בלבד" },
        { type: "max", message: "יש להזין מספרים קטנים מ - 10" },
        { type: "min", message: "יש להזין מספרים גדולים מ - 0" },
      ],
      date_training_fund: [{ type: "required", message: "שדה חובה" }],
      input_for_other_study_fund_deposit: [{ type: "required", message: "שדה חובה" }, { type: "maxlength", message: "יש להזין לכל היותר 6 תווים" }, { type: "pattern", message: "יש להזין מספרים בלבד" }]
    };
    return this.validation_messages;
  }
}
