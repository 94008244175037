import {
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Observable } from "rxjs";
import { FileAttachmentService } from "src/app/services/file-attachment.service";
import { TextService } from "src/app/services/text.service";
import { NgIf, NgFor, AsyncPipe } from "@angular/common";
import { DndDirective } from "../../../directives/dnd.directive";
import * as moment from "moment";

const MAX_FILE_SIZE = 5;
const MAX_NUM_OF_FILES_ALLOWED = 3;

@Component({
  selector: "app-upload-files",
  templateUrl: "./upload-files.component.html",
  styleUrls: ["./upload-files.component.scss"],
  standalone: true,
  imports: [DndDirective, NgIf, NgFor, AsyncPipe]
})
export class UploadFilesComponent implements OnInit, OnDestroy {
  @ViewChild("fileInput") fileInput: ElementRef;
  @Input()
  isDisabled = false;
  @Input()
  typesExtension: string | null = null;
  @Input()
  fileName: string | null = null;
  @Input()
  allowes_mime_types: string[] = ["image/jpeg", "image/png", "application/pdf"];
  public files: File[] = [];
  files$: Observable<File[]>;
  filesUploadErrors$: Observable<string[]>;
  error = "";

  constructor(
    private fileAttachmentService: FileAttachmentService,
    public textService: TextService
  ) {}

  ngOnInit(): void {
    this.files$ = this.fileAttachmentService.getFiles();
    this.filesUploadErrors$ = this.fileAttachmentService.getFilesUploadErrors();
    this.fileAttachmentService.getFiles().subscribe((files) => {
      this.files = files;
    });
  }

  onFileSelected(): void {
    const input = this.fileInput.nativeElement;
    if (input.files && input.files.length) {
      this.error = this.fileAttachmentService.validateFiles(
        input.files,
        this.allowes_mime_types,
        MAX_FILE_SIZE
      );
      if (this.error) {
        return;
      }

      for (const file of input.files) {
        if (this.fileName) {
          const fileType = file.name.substring(file.name.lastIndexOf('.'));
          const newFileName = `${this.fileName} ${moment(Date.now()).format("MM/YYYY")}${fileType}`;

          // Create a new File object with the new name
          const newFile = new File([file], newFileName, { type: file.type || fileType  });
          this.fileAttachmentService.addFile(newFile);

        }
        else {
          this.fileAttachmentService.addFile(file);
        }
      }
    }
  }

  removeFile(index: number): void {
    this.fileAttachmentService.removeFile(index);
  }

  onFileDropped(event: any): void {
    event.preventDefault();
    const files = event.dataTransfer.files;

    this.error = this.fileAttachmentService.validateFiles(
      files,
      this.allowes_mime_types,
      MAX_FILE_SIZE
    );
    if (this.error) {
      return;
    }

    for (const file of files) {
      if (this.fileName) {
        const fileType = file.name.substring(file.name.lastIndexOf('.'));
                const newFileName = `${this.fileName} ${moment(Date.now()).format("MM/YYYY")}${fileType}`;

        // Create a new File object with the new name
        const newFile = new File([file], newFileName, { type: file.type || fileType });
        this.fileAttachmentService.addFile(newFile);

      }
      else {
        this.fileAttachmentService.addFile(file);
      }
    }
  }

  ngOnDestroy(): void {
    this.fileAttachmentService.clearFiles();
  }
}
