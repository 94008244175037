import { Component, Input, OnInit } from "@angular/core";
import { UntypedFormControl, ReactiveFormsModule } from "@angular/forms";
import { ISelectOption } from "src/app/models/import-new-employees.model";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacyRadioModule } from "@angular/material/legacy-radio";
import { NgIf, NgClass, NgFor } from "@angular/common";

enum DefaultBulletOptionsLabelsEnum {
  YES = "כן",
  NO = "לא",
}

type RadioGroupStyles = {
  gap: "0" | "1";
};

@Component({
    selector: "app-radio-button",
    templateUrl: "./radio-button.component.html",
    styleUrls: ["./radio-button.component.scss"],
    standalone: true,
    imports: [NgIf, MatLegacyRadioModule, NgClass, ReactiveFormsModule, NgFor, MatLegacyFormFieldModule]
})
export class RadioButtonComponent implements OnInit {
  @Input() control: UntypedFormControl = new UntypedFormControl();
  @Input() label: string = "";
  @Input() options: ISelectOption[] = [];
  @Input() errorMessage: string = "";
  @Input() radioGroupGap: RadioGroupStyles["gap"] = "1";
  @Input() defaultValue: boolean | null = null;
  defaultBulletOptions: ISelectOption[] = [
    { label: DefaultBulletOptionsLabelsEnum.YES, value: true },
    { label: DefaultBulletOptionsLabelsEnum.NO, value: false },
  ];

  constructor() {}

  ngOnInit(): void {
    this.options = this.options.length
      ? this.options
      : this.defaultBulletOptions;

    if (this.defaultValue !== null) {
      this.control.setValue(this.defaultValue);
    }
  }

  get radioGroupGapStyle() {
    return `radio-group-gap-${this.radioGroupGap}`;
  }
}
