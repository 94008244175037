import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { PopupTypes } from "../../../models/popuptype";
import * as moment from "moment";
import { TextService } from "../../../services/text.service";
import { EmployeeService } from "src/app/services/employee.service";
import { GeneralPopupComponent } from "../../common/general-popup/general-popup.component";
import { NgIf, NgClass } from "@angular/common";
@Component({
    selector: "app-foreign-component",
    templateUrl: "./foreign-component.component.html",
    styleUrls: ["./foreign-component.component.scss"],
    standalone: true,
    imports: [NgIf, NgClass, GeneralPopupComponent]
})
export class ForeignComponentComponent implements OnInit {
  @Input() workerData: any;
  openCard = false;
  showPopup = false;
  changeBranch = PopupTypes.changeBranch;

  passportText = "";
  startDateText;
  endDateText;
  workPlaceText;
  premiumText;
  policyText;
  constructor(
    public dataService: DataService,
    public textService: TextService,
    public employeeService: EmployeeService
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    if (this.workerData.start_of_work_date) {
      this.workerData.start_of_work_date = moment(
        this.workerData.start_of_work_date
      ).format("DD/MM/YYYY");
    }

    this.passportText = this.dataService.getResourceText(
      this.textService.textSource["FOREIGN_EMPLOYERS_SCREEN_LABEL_1"],
      this.workerData
    );
    this.startDateText = this.dataService.getResourceText(
      this.textService.textSource["FOREIGN_EMPLOYERS_SCREEN_LABEL_2"],
      this.workerData
    );
    this.endDateText = this.dataService.getResourceText(
      this.textService.textSource["FOREIGN_EMPLOYERS_SCREEN_LABEL_3"],
      this.workerData
    );
    this.workPlaceText = this.dataService.getResourceText(
      this.textService.textSource["FOREIGN_EMPLOYERS_SCREEN_LABEL_4"],
      this.workerData
    );
    this.premiumText = this.dataService.getResourceText(
      this.textService.textSource["FOREIGN_EMPLOYERS_SCREEN_LABEL_5"],
      this.workerData
    );
    this.policyText = this.dataService.getResourceText(
      this.textService.textSource["FOREIGN_EMPLOYERS_SCREEN_LABEL_6"],
      this.workerData
    );
  }
}
