import { Component, OnInit } from "@angular/core";
import { DataService } from "src/app/services/data.service";
@Component({
    selector: "app-login-error",
    templateUrl: "./login-error.component.html",
    styleUrls: ["./login-error.component.scss"],
    standalone: true
})
export class LoginErrorComponent implements OnInit {
  constructor(public dataService: DataService) {}

  ngOnInit(): void {}
}
