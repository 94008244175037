import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { environment } from "../../../../environments/environment";
import { TextService } from "../../../services/text.service";
import { LoaderComponent } from "../loader/loader.component";
import { RouterLink } from "@angular/router";
import { NgClass, NgIf } from "@angular/common";
@Component({
    selector: "app-footer",
    templateUrl: "./footer.component.html",
    styleUrls: ["./footer.component.scss"],
    standalone: true,
    imports: [NgClass, RouterLink, NgIf, LoaderComponent]
})
export class FooterComponent implements OnInit {
  name: string = environment.name;
  @Input() areLoggedIn = true;

  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {}
}
