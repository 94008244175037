import { Injectable } from "@angular/core";
import { HttpHandler } from "../../../services/httpHandler";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpService } from "../../../services/http.service";
import { ErrorService } from "../../../services/error.service";
import { Router } from "@angular/router";
import { environment } from "../../../../environments/environment";
const ADMIN_TOKEN_KEY = "a-auth-token";

@Injectable({
  providedIn: "root",
})
export class AdminService extends HttpHandler {
  name: string = environment.name;

  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    router: Router
  ) {
    super(_http, httpService, errorService);
    // this.httpService.headers.subscribe(evt => console.log('subscribe!!!'));
  }

  public login(userName: string, password: string) {
    const url = "/login";
    let body = {
      username: userName,
      password: password,
    };
    return this.post(url, body, true);
  }

  public setAccessTokenAndUpdateHeader(accessToken: string) {
    sessionStorage.setItem(ADMIN_TOKEN_KEY, accessToken);
    this.httpService.updateHeader(ADMIN_TOKEN_KEY, accessToken);
  }
}
