import {
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  AfterViewChecked,
} from "@angular/core";
import { UntypedFormGroup, ReactiveFormsModule } from "@angular/forms";
import * as moment from "moment";
import { RegisterUserDataEnum } from "src/app/models/register-user-data.model";
import { FileUploadService } from "src/app/services/file-upload.service";
import { FormValidatorsService } from "src/app/services/form-validators.service";
import { ModalService } from "src/app/services/modal.service";
import {
  IdTypeEnum,
  RegisterDataService,
} from "src/app/services/register-data.service";
import { TextService } from "src/app/services/text.service";
import { ToasterService } from "src/app/services/toaster.service";
import { ButtonComponent } from "../../common/button/button.component";
import { SelectComponent } from "../../common/select/select.component";
import { InputComponent } from "../../common/input/input.component";
import { ModalComponent } from "../../common/modal/modal.component";

@Component({
    selector: "app-employee-details-modal",
    templateUrl: "./employee-details-modal.component.html",
    styleUrls: ["./employee-details-modal.component.scss"],
    standalone: true,
    imports: [ModalComponent, ReactiveFormsModule, InputComponent, SelectComponent, ButtonComponent]
})
export class EmployeeDetailsModalComponent implements OnInit, AfterViewChecked {
  @Input() employeeDetailsFormGroup: UntypedFormGroup;
  newFormData: UntypedFormGroup;
  registerUserDataEnum = RegisterUserDataEnum;
  textSource;
  formOptions;
  today: Date = new Date();

  constructor(
    private modalService: ModalService,
    private textService: TextService,
    private fileUploadService: FileUploadService,
    private registerDataService: RegisterDataService,
    private toasterService: ToasterService,
    private cdr: ChangeDetectorRef,
    private formValidatorsService: FormValidatorsService
  ) {}

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  ngOnInit(): void {
    if (!this.textService.textSource) {
      this.textService.setTextSource();
    }
    this.textSource = this.textService.textSource;

    this.registerDataService.getFormOptions().subscribe((resp) => {
      this.formOptions = resp;
    });

    this.newFormData = new UntypedFormGroup(this.employeeDetailsFormGroup.controls);
    this.newFormData.markAllAsTouched();

    // set id validators depending on id type
    this.setIdValidations(this.newFormData);

    // listen for further id type changes to update id validators accordingly
    this.listenForIdTypeChanges();
  }

  save() {
    const record = this.newFormData.getRawValue();
    this.formatDatepickerChosenDate(record);
    this.fileUploadService.patchRecordValue(record);

    this.modalService.closeModal();
  }

  closeModal() {
    this.modalService.closeModal();
  }

  setIdValidations(fg: UntypedFormGroup) {
    const idType = fg.controls[RegisterUserDataEnum.ID_NUMBER_TYPE].value;
    if (idType === IdTypeEnum.PASSPORT) {
      fg.controls[RegisterUserDataEnum.ID_NUMBER].setValidators(
        this.formValidatorsService.formValidatorMap("PASSPORT")
      );
      fg.controls[RegisterUserDataEnum.ID_NUMBER].updateValueAndValidity();
    } else {
      fg.controls[RegisterUserDataEnum.ID_NUMBER].setValidators(
        this.formValidatorsService.formValidatorMap(
          RegisterUserDataEnum.ID_NUMBER
        )
      );
      fg.controls[RegisterUserDataEnum.ID_NUMBER].updateValueAndValidity();
    }
  }

  listenForIdTypeChanges() {
    this.newFormData
      .get(this.registerUserDataEnum.ID_NUMBER_TYPE)!
      .valueChanges.subscribe((data) => {
        this.setIdValidations(this.newFormData);
      });
  }

  formatDatepickerChosenDate(record: {}) {
    for (const [key, value] of Object.entries(record)) {
      if (typeof value === "object" && value !== null) {
        record[key] = moment(value).format("YYYY-MM-DD");
      }
    }
  }
}
