import { Component, OnInit, HostListener } from "@angular/core";
import { PopupTypes } from "../../../models/popuptype";
import { DataService } from "src/app/services/data.service";
import { TextService } from "../../../services/text.service";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
import { GeneralPopupComponent } from "../../common/general-popup/general-popup.component";
import { NgIf } from "@angular/common";
import { RegisterStageComponentComponent } from "../../generic-components/register-stage-component/register-stage-component.component";
import { HeaderComponent } from "../../common/header/header.component";
import { BreadcrumbsComponent } from "../../common/breadcrumbs/breadcrumbs.component";
@Component({
    selector: "app-register-employee",
    templateUrl: "./register-employee.component.html",
    styleUrls: ["./register-employee.component.scss"],
    standalone: true,
    imports: [BreadcrumbsComponent, HeaderComponent, RegisterStageComponentComponent, NgIf, GeneralPopupComponent]
})
export class RegisterEmployeeComponent implements OnInit {
  titleName = "הקמת עובד";
  stageNumber = 1;
  progressBarName = [
    "שלב 1 פרטי עובד",
    "שלב 2 פרטי עסקה",
    "שלב 3 מדיניות פנסיונית",
  ];
  name = "gur";
  stageTitlesArray = [];
  errorPopup = PopupTypes.error;
  breadcrumbsLinks = [
    BreadcrumbsLinkNames.HOME,
    BreadcrumbsLinkNames.MY_EMPLOYEES,
    BreadcrumbsLinkNames.REGISTER,
  ];
  // @HostListener('window:beforeunload', ['$event'])

  hasUnsavedData() {
    return true;
  }
  constructor(
    public dataService: DataService,
    public textService: TextService
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    this.stageTitlesArray = [
      this.textService.textSource["NEW_EMPLOYER_STEP_1_TITLE"],
      this.textService.textSource["NEW_EMPLOYER_STEP_2_TITLE"],
      this.textService.textSource["NEW_EMPLOYER_STEP_3_TITLE"],
    ];
  }
  nextStep() {
    this.stageNumber++;
  }
  backStep() {
    this.stageNumber = this.stageNumber - 1;
  }
  unloadNotification(e) {
    if (this.hasUnsavedData()) {
      e.returnValue = true;
    }
  }
}
