import { Component, OnInit, Input, NgZone } from "@angular/core";
import { text } from "../../../../texts/texts";
import { DataService } from "src/app/services/data.service";
import { NgIf } from "@angular/common";
@Component({
    selector: "app-information-page",
    templateUrl: "./information-page.component.html",
    styleUrls: ["./information-page.component.scss"],
    standalone: true,
    imports: [NgIf]
})
export class InformationPageComponent implements OnInit {
  text: string;
  title: string;
  @Input() type: string = "";
  constructor(public dataService: DataService, private zone: NgZone) {}

  ngOnInit(): void {
    if (this.type == "privacy") {
      this.zone.run(() => {
        this.title = "מדיניות פרטיות";
        this.text = text.privacy;
      });
    } else if (this.type == "terms") {
      this.zone.run(() => {
        this.title = "תנאי שימוש";
        this.text = text.terms;
      });
    } else if (this.type == "accessibility") {
      this.zone.run(() => {
        this.title = "הצהרת נגישות";
        this.text = text.accessibility;
      });
    }

    window.scrollTo(0, 0);
  }
}
