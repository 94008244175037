import { Component, OnInit } from "@angular/core";

@Component({
    selector: "app-logo",
    templateUrl: "./logo.component.html",
    styleUrls: ["./logo.component.scss"],
    standalone: true
})
export class LogoComponent implements OnInit {
  logoUrl = "assets/images/gur/logo-reg.svg";

  constructor() {}

  ngOnInit(): void {}
}
