import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  Renderer2,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { Toast } from "src/app/models/toast.interface";

@Component({
    selector: "app-toaster",
    templateUrl: "./toaster.component.html",
    styleUrls: ["./toaster.component.scss"],
    standalone: true
})
export class ToasterComponent implements AfterViewInit, OnChanges {
  @Input() toast: Toast;
  @Input() toastIndex: number;
  @ViewChild("toaster") el: ElementRef;

  @Output() remove = new EventEmitter<number>();

  constructor(private renderer: Renderer2) {}

  ngAfterViewInit(): void {
    this.setElementPosition();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.toastIndex && !changes.toastIndex.firstChange) {
      const currentValue = changes.toastIndex.currentValue;
      const previousValue = changes.toastIndex.previousValue;

      currentValue > previousValue ? this.setElementPosition() : "";
    }
  }

  setElementPosition(): void {
    this.renderer.setStyle(
      this.el.nativeElement,
      "top",
      `${this.toastIndex * 56}px`
    );
  }
}
