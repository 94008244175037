import { Component, OnDestroy, OnInit } from "@angular/core";
import { TextService } from "src/app/services/text.service";
import { RouterLink } from "@angular/router";
import { ButtonComponent } from "../../common/button/button.component";
import { ModalComponent } from "../../common/modal/modal.component";
import { FileUploadService } from "src/app/services/file-upload.service";
import { CommonModule } from "@angular/common";

@Component({
  selector: "app-registered-imported-employees-success",
  templateUrl: "./registered-imported-employees-success.component.html",
  styleUrls: ["./registered-imported-employees-success.component.scss"],
  standalone: true,
  imports: [ModalComponent, ButtonComponent, RouterLink, CommonModule]
})
export class RegisteredImportedEmployeesSuccessComponent
  implements OnInit, OnDestroy {

  type;
  constructor(public textService: TextService, public fileUploadService: FileUploadService) { this.type = this.fileUploadService.getType(); }

  ngOnDestroy(): void { }

  ngOnInit(): void { }
}
