import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from "@angular/router";
import { Observable } from "rxjs";
import { FileUploadService } from "../services/file-upload.service";

@Injectable({
  providedIn: "root",
})
export class ImportEmployeesGuard  {
  constructor(
    private fileUploadService: FileUploadService,
    public router: Router
  ) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ):
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree>
    | boolean
    | UrlTree {
    if (!this.fileUploadService.getAllowUploadNewEmployeesPageAccess()) {
      this.router.navigateByUrl("/");
      return false;
    }
    return true;
  }
}
