import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from "@angular/core";
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, ReactiveFormsModule } from "@angular/forms";
import { RegisterUserDataEnum } from "src/app/models/register-user-data.model";
import { DataService } from "src/app/services/data.service";
import { FormValidatorsService } from "src/app/services/form-validators.service";
import { ModalService } from "src/app/services/modal.service";
import { ToasterService } from "src/app/services/toaster.service";
import { EmployeeDetailsModalComponent } from "../../generic-components/employee-details-modal/employee-details-modal.component";
import { MatIconModule } from "@angular/material/icon";
import { NgFor, NgClass, NgIf } from "@angular/common";
import { BidiModule } from "@angular/cdk/bidi";

@Component({
    selector: "app-imported-employees-table",
    templateUrl: "./imported-employees-table.component.html",
    styleUrls: ["./imported-employees-table.component.scss"],
    standalone: true,
    imports: [ReactiveFormsModule, BidiModule, NgFor, NgClass, NgIf, MatIconModule, EmployeeDetailsModalComponent]
})
export class ImportedEmployeesTableComponent implements OnInit, OnChanges {
  @Input() data = [];
  tableForm: UntypedFormGroup;
  tableColumns = [
    { value: RegisterUserDataEnum.FIRST_NAME, label: "שם פרטי" },
    { value: RegisterUserDataEnum.LAST_NAME, label: "שם משפחה" },
    { value: RegisterUserDataEnum.ID_NUMBER, label: "מספר זיהוי" },
    { value: "Actions", label: "פעולות" },
  ];
  chosenEmployeeDetails: UntypedFormGroup;
  modals: {};

  get users() {
    return this.tableForm.get("users") as UntypedFormArray;
  }

  constructor(
    public dataService: DataService,
    private fb: UntypedFormBuilder,
    public modalService: ModalService,
    private formValidatorsService: FormValidatorsService,
    private toasterService: ToasterService
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes["data"] && !changes["data"].firstChange) {
      this.initFormTable(changes.data.currentValue);
    }
  }

  ngOnInit(): void {
    this.initFormTable(this.data);
    this.modalService.getModals().subscribe((modals) => {
      this.modals = modals;
    });
  }

  editFormGroup(i) {
    this.chosenEmployeeDetails = this.buildFormGroup(this.data[i]);
    this.modalService.openModal("editForm");
  }

  deleteFormGroup(i) {
    this.data.splice(i, 1);
    this.initFormTable(this.data);
    this.toasterService.show("success", "success!", "רשומה נמחקה בהצלחה", 2000);
  }

  getNumberOfInvalidFields(fg: any) {
    let sum = 0;
    Object.values(fg).forEach((value) => {
      typeof value === "object" && value !== null ? sum++ : null;
    });
    return sum;
  }

  buildFormGroup(data: Object) {
    const form = {};
    for (const [key, value] of Object.entries(data)) {
      const parsedValue =
        typeof value === "object" && value !== null ? value["value"] : value;
      form[key] = new UntypedFormControl(parsedValue, {
        validators: this.formValidatorsService.formValidatorMap(key),
      });
    }

    return this.fb.group(form);
  }

  initFormTable(data) {
    this.tableForm = this.fb.group({
      users: this.fb.array([]),
    });

    // initialize TableForm
    data.forEach((user) => {
      const formGroup = this.buildFormGroup(user);
      this.users.push(formGroup);
    });
  }
}
