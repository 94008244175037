import { Injectable } from "@angular/core";
import { HttpHandler } from "./httpHandler";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { DataService } from "./data.service";


@Injectable({
  providedIn: "root",
})
export class TasksService extends HttpHandler {
  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService
  ) {
    super(_http, httpService, errorService);
  }

  getEmployeeTasks(employment_id, status) {
    const url = "/contact/missions/employee/" + employment_id;
    if (status != undefined) {
      const params = new HttpParams({
        fromObject: {
          status: `${status}`,
        },
      });

      return this.get(url, params);
    } else {
      return this.get(url);
    }
  }

  getCounts(from, to, unit) {
    const url = "/contact/missions/counts";
    if (from == "" && to == "" && unit == "") {
      return this.get(url);
    } else {
      let params = new HttpParams({
        fromObject: {
          from: `${from}`,
          to: `${to}`,
        },
      });
      if (unit != "") {
        params = params.append("unit", `${unit}`);
      }
      return this.get(url, params);
    }
  }

  getTasks(from, to, unit) {
    const url = "/contact/missions/counts-by-date";
    if (from == "" && to == "" && unit == "") {
      return this.get(url);
    } else {
      let params = new HttpParams({
        fromObject: {
          from: `${from}`,
          to: `${to}`,
        },
      });
      if (unit != "") {
        params = params.append("unit", `${unit}`);
      }
      return this.get(url, params);
    }
  }

  getAllTasksByTypeByFilter(
    type,
    from,
    to,
    unit,
    status,
    searchTerm,
    skip,
    limit
  ) {
    const url = "/contact/missions";
    let params = new HttpParams({
      fromObject: {
        type: `${type}`,
        from: `${from}`,
        to: `${to}`,
      },
    });
    if (unit != "") {
      params = params.append("unit", `${unit}`);
    }
    if (searchTerm != "") {
      params = params.append("searchTerm", `${searchTerm}`);
    }
    if (status != "") {
      params = params.append("status", `${status}`);
    }
    if (limit != "") {
      params = params.append("limit", `${limit}`);
    }

    if (skip !== "") {
      params = params.append("skip", `${skip}`);
    }

    return this.get(url, params);
  }

  getCountsType(type, from, to, unit) {
    const url = "/contact/missions/counts";
    let params = new HttpParams({
      fromObject: {
        type: `${type}`,
      },
    });
    if (to != "") {
      params = params.append("to", `${to}`);
    }
    if (from != "") {
      params = params.append("from", `${from}`);
    }
    if (unit != "") {
      params = params.append("unit", `${unit}`);
    }
    return this.get(url, params);
  }

  download161Form(attachment_id:string){
    const url = `/contact/missions/download-161-form/${attachment_id}`;
    return this.download(url);
  }
  getWorkTerminationProcedureDetails(id: string) {
    const url = `/contact/missions/get-procedure-details/${id}`;
    return this.get(url);
  }
}
