import { Injectable } from "@angular/core";
import { HttpHandler } from "./httpHandler";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { DataService } from "./data.service";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class EmployeeService extends HttpHandler {
  employee_id = "";
  employeeCardsGhostElements = false;
  showGhostElementsTabs = false;
  employeeDataArrived = new Subject();
  employeeData;
  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService
  ) {
    super(_http, httpService, errorService);
  }

  getForeignWorker(skip, limit, searchTerm) {
    const url = "/contact/policy/foreign-employee";
    const params = new HttpParams({
      fromObject: {
        skip: `${skip}`,
        limit: `${limit}`,
        searchTerm: `${searchTerm}`,
      },
    });
    return this.get(url, params);
  }

  getRegularWorker(
    skip,
    limit,
    searchTerm,
    activesoptional,
    factorOptional,
    regularWorker,
    showAllWorkers
  ) {
    const url = "/contact/employees";
    const idType = regularWorker ? "תעודת זהות" : "דרכון";
    let params = new HttpParams({
      fromObject: {
        skip: `${skip}`,
        limit: `${limit}`,
        searchTerm: `${searchTerm}`,
        factor: factorOptional,
      },
    });

    if (activesoptional != null) {
      params = params.append("actives", `${activesoptional}`);
    }
    if (!showAllWorkers) {
      params = params.append("idType", idType);
    }
    return this.get(url, params);
  }

  getEmployeesCounts() {
    const url = "/contact/employees/counts";
    return this.get(url);
  }

  getEmployee(employee_id) {
    const url = "/contact/employee/" + employee_id;
    return this.get(url);
  }

  updateEmployeeDetails(employee_id, body) {
    const url = "/contact/task/update-employee/" + employee_id;
    return this.post(url, body);
  }

  postEmployeeWorkTermination(employee_id, body) {
    const url = `/contact/task/work-termination/${employee_id}`;
    return this.post(url, body);
  }
  checkWorkTerminationExists(employment_id) {
    const url = `/contact/task/work-termination-exists/${employment_id}`
    return this.get(url);
  }
}
