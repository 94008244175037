import { Component, Inject, OnInit } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { MatLegacyButtonModule } from '@angular/material/legacy-button';
import { NgFor } from '@angular/common';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss'],
  standalone: true,
  imports: [NgFor, MatLegacyButtonModule]
})
export class LoginPopupComponent implements OnInit {

  constructor(private dialogRef: MatDialogRef<LoginPopupComponent>, @Inject(MAT_DIALOG_DATA) public businesses: any) { }

  ngOnInit(): void {

  }
  closePopup(i) {
    this.dialogRef.close(i);
  }
  selectBusiness(i) {
    this.closePopup(i)
  }
}
