import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";
import { Router } from "@angular/router";
import { NgIf, NgClass } from "@angular/common";
import { BidiModule } from "@angular/cdk/bidi";

@Component({
    selector: "app-employers-interface-card",
    templateUrl: "./employers-interface-card.component.html",
    styleUrls: ["./employers-interface-card.component.scss"],
    standalone: true,
    imports: [BidiModule, NgIf, NgClass]
})
export class EmployersInterfaceCardComponent implements OnInit {
  constructor(
    private router: Router,
    private dataService: DataService,
    public textService: TextService
  ) {
    this.textService.setTextSource();
  }

  @Input() cardData: any;
  @Input() isItEmployeeCard = false;

  contact_name;
  contact_id_number;
  manufacturer_name;
  cash_number;
  policy_type_name;
  money_related;
  money_related_value;
  money_unrelated;
  money_unrelated_value;
  money_sent;
  money_sent_value;
  comment;
  status;

  ngOnInit(): void {
    this.contact_name = this.dataService.getResourceText(
      this.textService.textSource[
        "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_ITEM_LABEL_1"
      ],
      this.cardData
    );
    this.contact_id_number = this.dataService.getResourceText(
      this.textService.textSource[
        "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_ITEM_LABEL_2"
      ],
      this.cardData
    );
    this.manufacturer_name = this.dataService.getResourceText(
      this.textService.textSource[
        "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_ITEM_LABEL_3"
      ],
      this.cardData
    );
    this.cash_number = this.dataService.getResourceText(
      this.textService.textSource[
        "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_ITEM_LABEL_4"
      ],
      this.cardData
    );
    this.policy_type_name = this.dataService.getResourceText(
      this.textService.textSource[
        "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_ITEM_LABEL_5"
      ],
      this.cardData
    );
    this.money_related = this.dataService.getResourceText(
      this.textService.textSource[
        "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_ITEM_LABEL_7"
      ],
      this.cardData
    );
    this.money_related_value = this.dataService.getResourceText(
      this.textService.textSource[
        "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_ITEM_VALUE_7"
      ],
      this.cardData
    );
    this.money_unrelated = this.dataService.getResourceText(
      this.textService.textSource[
        "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_ITEM_LABEL_8"
      ],
      this.cardData
    );
    this.money_unrelated_value = this.dataService.getResourceText(
      this.textService.textSource[
        "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_ITEM_VALUE_8"
      ],
      this.cardData
    );
    this.money_sent = this.dataService.getResourceText(
      this.textService.textSource[
        "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_ITEM_LABEL_9"
      ],
      this.cardData
    );
    this.money_sent_value = this.dataService.getResourceText(
      this.textService.textSource[
        "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_ITEM_VALUE_9"
      ],
      this.cardData
    );
    this.comment = this.dataService.getResourceText(
      this.textService.textSource[
        "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_ITEM_VALUE_10"
      ],
      this.cardData
    );
    this.status = this.dataService.getResourceText(
      this.textService.textSource[
        "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_ITEM_LABEL_6"
      ],
      this.cardData
    );
  }
  goToCardEmployee(id) {
    if (id) {
      this.router.navigateByUrl("employee-card/" + id);
    }
  }
}
