import { Injectable } from "@angular/core";
import { HttpHandler } from "./httpHandler";
import { HttpClient, HttpParams } from "@angular/common/http";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { DataService } from "./data.service";
@Injectable({
  providedIn: "root",
})
export class PolicyService extends HttpHandler {
  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService
  ) {
    super(_http, httpService, errorService);
  }
  policiesGhostElements = false;
  getPolicy(skip, limit) {
    // const url = '/contact/policy/agent?skip=' + skip + '&limit='+ limit;

    const url = "/contact/policy/agent";
    const params = new HttpParams({
      fromObject: {
        skip: `${skip}`,
        limit: `${limit}`,
      },
    });
    return this.get(url, params);
  }

  getElementaryPolicies(skip, limit, searchTerm, actives) {
    const url = "/contact/policy/elementary-policies";
    let params = new HttpParams({
      fromObject: {
        skip: `${skip}`,
        limit: `${limit}`,
        searchTerm: `${searchTerm}`
      },
    });
    if (actives != null) {
      params = params.append("actives", `${actives}`);
    }
    return this.get(url, params);
  }
}
