import { Component, OnInit } from "@angular/core";
import { InformationPageComponent } from "../../generic-components/information-page/information-page.component";

@Component({
    selector: "app-terms",
    templateUrl: "./terms.component.html",
    styleUrls: ["./terms.component.scss"],
    standalone: true,
    imports: [InformationPageComponent]
})
export class TermsComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
