import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class ModalService {
  private _modals = new BehaviorSubject({});

  constructor() {}

  openModal(modal: string) {
    this._modals.value[modal] = true;
    this._modals.next(this._modals.value);
  }

  closeModal() {
    this._modals.next({});
  }

  getModals() {
    return this._modals.asObservable();
  }
}
