// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.policies {
  margin: 0 auto;
  padding: 15px;
}

:host ::ng-deep.mat-paginator-container {
  direction: rtl;
  font-family: var(--main-font-regular);
  font-size: var(--font-size-button-mobile);
  margin-top: 10px;
  display: flex;
  justify-content: center;
  padding-bottom: 20px;
}

:host ::ng-deep.mat-paginator-page-size {
  display: none;
}

:host ::ng-deep.mat-paginator-range-label {
  margin-top: 7px;
}

:host ::ng-deep.mat-paginator-icon {
  transform: rotate(180deg);
}

:host ::ng-deep.mat-icon-button {
  background: none;
}

@media screen and (min-width: 600px) {
  .desktop {
    max-width: 82%;
    margin: 0 auto;
  }
  .desktop .policies {
    flex-wrap: wrap;
    display: flex;
    flex-direction: row;
    direction: rtl;
    padding-bottom: 25px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/pages/policy-archive/policy-archive.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,aAAA;AACF;;AAEA;EACE,cAAA;EACA,qCAAA;EACA,yCAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;EACA,oBAAA;AACF;;AAEA;EACE,aAAA;AACF;;AAEA;EACE,eAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE;IACE,cAAA;IACA,cAAA;EACF;EACE;IACE,eAAA;IACA,aAAA;IACA,mBAAA;IACA,cAAA;IACA,oBAAA;EACJ;AACF","sourcesContent":[".policies {\n  margin: 0 auto;\n  padding: 15px;\n}\n\n:host ::ng-deep.mat-paginator-container {\n  direction: rtl;\n  font-family: var(--main-font-regular);\n  font-size: var(--font-size-button-mobile);\n  margin-top: 10px;\n  display: flex;\n  justify-content: center;\n  padding-bottom: 20px;\n}\n\n:host ::ng-deep.mat-paginator-page-size {\n  display: none;\n}\n\n:host ::ng-deep.mat-paginator-range-label {\n  margin-top: 7px;\n}\n\n:host ::ng-deep.mat-paginator-icon {\n  transform: rotate(180deg);\n}\n\n:host ::ng-deep.mat-icon-button {\n  background: none;\n}\n\n@media screen and (min-width: 600px) {\n  .desktop {\n    max-width: 82%;\n    margin: 0 auto;\n\n    .policies {\n      flex-wrap: wrap;\n      display: flex;\n      flex-direction: row;\n      direction: rtl;\n      padding-bottom: 25px;\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
