import { Injectable } from "@angular/core";
import { HttpHandler } from "./httpHandler";
import { BehaviorSubject, Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FeedbackService extends HttpHandler {
  dateChanged = new Subject();
  createChart = new Subject();
  private _displayFeedback = new BehaviorSubject<boolean>(null);

  async getDisplayFeedback() {
    const url = "/contact/feedback/display";

    try {
      const { display_feedback } = await this.get(url).toPromise();
      this._displayFeedback.next(display_feedback);
    } catch (error) {
      // couldnt get displayFeedback
    }
  }

  getDisplayFeedbackObs() {
    return this._displayFeedback.asObservable();
  }

  getDisplayFeedbackValue() {
    return this._displayFeedback.value;
  }

  getFeedbackStats(params) {
    const url = "/contact/feedback/stats";
    return this.get(url, params);
  }

  getFeedback(params) {
    const url = "/contact/feedback";
    return this.get(url, params);
  }

  getFeedbackStatsByPolicy(params) {
    const url = "/contact/feedback/stats/filter-by-policy";
    return this.get(url, params);
  }

  getFeedbackByID(id, params) {
    const url = "/contact/feedback/employee/" + id;
    return this.get(url, params);
  }
  getFeedbackStatsByID(id, params) {
    const url = "/contact/feedback/employee/" + id + "/stats";
    return this.get(url, params);
  }
}
