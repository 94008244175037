import { NgIf } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { DataService } from 'src/app/services/data.service';
import { TextService } from 'src/app/services/text.service';

@Component({
  selector: 'app-successfully-loading',
  templateUrl: './successfully-loading.component.html',
  styleUrls: ['./successfully-loading.component.scss'],
  standalone: true,
  imports: [NgIf]
})
export class SuccessfullyLoadingComponent implements OnInit {
  SR = "";
  SR_text = "";
   constructor(
    public dataService: DataService,
    public textService: TextService,
    private router: Router,    
  ) { }
  ngOnInit(): void {
  
  }
  goToHomePage() {
    this.dataService.showNewAttachment = false;
    this.router.navigateByUrl("/");
  }

}
