// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.bread-crumbs-wrapper {
  font-family: var(--main-font-regular);
  font-size: 25px;
  text-align: right;
  padding-top: 25px;
  padding-bottom: 19px;
  direction: rtl;
}
.bread-crumbs-wrapper .selected {
  color: var(--main_button_color);
}

a {
  color: inherit;
  text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/breadcrumbs/breadcrumbs.component.scss"],"names":[],"mappings":"AAAA;EACE,qCAAA;EACA,eAAA;EACA,iBAAA;EACA,iBAAA;EACA,oBAAA;EACA,cAAA;AACF;AAAE;EACE,+BAAA;AAEJ;;AACA;EACE,cAAA;EACA,qBAAA;AAEF","sourcesContent":[".bread-crumbs-wrapper {\n  font-family: var(--main-font-regular);\n  font-size: 25px;\n  text-align: right;\n  padding-top: 25px;\n  padding-bottom: 19px;\n  direction: rtl;\n  .selected {\n    color: var(--main_button_color);\n  }\n}\na {\n  color: inherit;\n  text-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
