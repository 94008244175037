import { Injectable } from "@angular/core";
import { Observable, Subject } from "rxjs";
import { filter } from "rxjs/operators";
import { Toast, ToastType } from "../models/toast.interface";

@Injectable({
  providedIn: "root",
})
export class ToasterService {
  private subject: Subject<Toast>;
  toast$: Observable<Toast>;

  constructor() {
    this.subject = new Subject<Toast>();
    this.toast$ = this.subject
      .asObservable()
      .pipe(filter((toast) => toast !== null));
  }

  show(type: ToastType, title?: string, body?: string, delay?: number) {
    this.subject.next({ type, title, body, delay });
  }
}
