import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
} from "@angular/core";
import { FeedbackStatsModel } from "src/app/models/feedback.stats.model";
import { DataService } from "src/app/services/data.service";
import { TextService } from "src/app/services/text.service";
import { PieChartComponent } from "../../common/pie-chart/pie-chart.component";
import { DatePickerComponent } from "../../common/date-picker/date-picker.component";
import { NgClass, NgIf, NgFor } from "@angular/common";

@Component({
    selector: "app-employers-interface-type-details",
    templateUrl: "./employers-interface-type-details.component.html",
    styleUrls: ["./employers-interface-type-details.component.scss"],
    standalone: true,
    imports: [NgClass, DatePickerComponent, NgIf, PieChartComponent, NgFor]
})
export class EmployersInterfaceTypeDetailsComponent implements OnInit {
  constructor(
    public textService: TextService,
    public dataService: DataService
  ) {
    this.textService.setTextSource();
  }

  @Input() feedbackStatsData: FeedbackStatsModel;
  @Output() emitDates = new EventEmitter();
  @Output() emitPieStatus = new EventEmitter();
  @Input() absorption_rate;
  @Input() isItEmployeeCard = false;

  @Input() total_related_money;
  @Input() total_unrelated_money;
  @Input() total_money_sent;

  ghostArray = new Array(3);

  ngOnInit(): void {}

  datesChanged(data) {
    this.emitDates.emit(data);
  }

  getPieData(status) {
    this.emitPieStatus.emit(status);
  }
}
