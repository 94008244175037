// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal {
  direction: rtl;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  z-index: 99;
}
.modal .modal__container {
  background: #fff;
  border-radius: 0.25rem;
  border: 2px solid #157454;
  width: 90vw;
  height: 80vh;
  max-width: 720px;
  text-align: center;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  overflow-y: scroll;
}
.modal .modal__close-button {
  border: transparent;
  background: transparent;
  color: var(--main_button_color);
  font-size: 30px;
  position: absolute;
  top: 20px;
  left: 5%;
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/modal/modal.component.scss"],"names":[],"mappings":"AAAA;EACE,cAAA;EACA,eAAA;EACA,MAAA;EACA,OAAA;EACA,WAAA;EACA,YAAA;EACA,8BAAA;EACA,aAAA;EACA,mBAAA;EACA,WAAA;AACF;AACE;EACE,gBAAA;EACA,sBAAA;EACA,yBAAA;EACA,WAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;EACA,kBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,SAAA;EACA,kBAAA;AACJ;AAEE;EACE,mBAAA;EACA,uBAAA;EACA,+BAAA;EACA,eAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,eAAA;AAAJ","sourcesContent":[".modal {\n  direction: rtl;\n  position: fixed;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  background: rgba(0, 0, 0, 0.5);\n  display: grid;\n  place-items: center;\n  z-index: 99;\n\n  .modal__container {\n    background: #fff;\n    border-radius: 0.25rem;\n    border: 2px solid #157454;\n    width: 90vw;\n    height: 80vh;\n    max-width: 720px;\n    text-align: center;\n    position: relative;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: column;\n    gap: 1rem;\n    overflow-y: scroll;\n  }\n\n  .modal__close-button {\n    border: transparent;\n    background: transparent;\n    color: var(--main_button_color);\n    font-size: 30px;\n    position: absolute;\n    top: 20px;\n    left: 5%;\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
