import {
  Component,
  OnInit,
  Input,
  AbstractType,
  ElementRef,
  AfterViewInit,
  NgZone,
} from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { Subscription } from "rxjs";
import { environment } from "src/environments/environment";
import { HomepageService } from "src/app/services/homepage.service";
import * as moment from "moment";
import { Router } from "@angular/router";
import { TextService } from "../../../services/text.service";
import { EmployeeService } from "src/app/services/employee.service";
import { EmployersInterfaceSectionHomePageComponent } from "../employers-interface-section-home-page/employers-interface-section-home-page.component";
import { ButtonDropdownComponent } from "../../common/button-dropdown/button-dropdown.component";
import { ButtonComponent } from "../../common/button/button.component";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { NgIf, NgFor } from "@angular/common";

declare var $;
@Component({
  selector: "app-home-component",
  templateUrl: "./home-component.component.html",
  styleUrls: ["./home-component.component.scss"],
  standalone: true,
  imports: [NgIf, MatLegacyFormFieldModule, MatLegacySelectModule, ReactiveFormsModule, FormsModule, NgFor, MatLegacyOptionModule, ButtonComponent, ButtonDropdownComponent, EmployersInterfaceSectionHomePageComponent]
})
export class HomeComponentComponent implements OnInit, AfterViewInit {
  @Input() title: string = "";
  @Input() type: string = "";
  @Input() buttonsName = [];
  @Input() bottomButton: string = "";
  @Input() valid: boolean = false;
  @Input() selectOptions: any;

  appointmentsType = ["", ""];
  name = this.dataService.name;
  showAppointmentsCards = true;
  calcForWidth;
  selected;
  select;
  innerRadius;
  radius;
  dataPoints: any = [];
  colors = environment.colors;
  selectedSectionIndex: number;
  activeMediaQuery = "";
  watcher: Subscription;
  translateQuarterNumToHebrew = {
    1: "רבעון ראשון",
    2: "רבעון שני",
    3: "רבעון שלישי",
    4: "רבעון רביעי",
  };
  optionsQuarter = [];
  total_count = "";
  total_actives = "";
  total_in_actives = "";

  constructor(
    private router: Router,

    public zone: NgZone,
    public dataService: DataService,
    public el: ElementRef,
    private homepageService: HomepageService,
    public textService: TextService,
    private employeeService: EmployeeService
  ) { }

  ngOnInit(): void {
    this.dataService.showHomePie = true;
    this.textService.setTextSource();

    let that = this;
    this.calcForWidth = this.buttonsName.length;

    if (this.type === "appointment") {
      let today = moment();
      let year = moment(today).year();
      let quarter = moment(today).quarter();
      if (this.dataService.isItMobileDisplay()) {
        this.dataService.showDataLoader = true;
      }
      this.dataService.showGhostElements = true;
      this.showAppointmentsCards = true;
      this.homepageService.getMeetings(year, quarter).subscribe(
        (resp) => {
          this.dataService.showGhostElements = false;
          this.showAppointmentsCards = false;

          this.dataService.showDataLoader = false;
          this.appointmentsType = resp["meetings"];
          this.appointmentsType.forEach((element) => {
            element["name"] = this.dataService.getResourceText(
              this.textService.textSource["HOME_SCREEN_SECTION_MID_ITEM_TITLE"],
              element
            );
            element["total_count"] = this.dataService.getResourceText(
              this.textService.textSource[
              "HOME_SCREEN_SECTION_MID_ITEM_SUBTITLE_1"
              ],
              element
            );
            element["scheduled_count"] = this.dataService.getResourceText(
              this.textService.textSource[
              "HOME_SCREEN_SECTION_MID_ITEM_SUBTITLE_3"
              ],
              element
            );
            element["scheduled_count"] =
              this.dataService.changeNumStartingLocation(
                element["scheduled_count"]
              );
            element["held_count"] = this.dataService.getResourceText(
              this.textService.textSource[
              "HOME_SCREEN_SECTION_MID_ITEM_SUBTITLE_2"
              ],
              element
            );
            element["held_count"] = this.dataService.changeNumStartingLocation(
              element["held_count"]
            );
          });

          this.selectOptions = resp["filter"];
          this.selected = this.selectOptions[0];
        },
        (err) => {
          this.dataService.handleErrors(err);
        }
      );
    }

    if (this.type === "task") {
      this.getEmployeesCountsInfo();
    }
  }

  ngAfterViewInit(): void { }

  changeQuarter() {
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.showAppointmentsCards = true;
    this.homepageService
      .getMeetings(this.selected["year"], this.selected["quarter"])
      .subscribe(
        (resp) => {
          this.showAppointmentsCards = false;
          this.dataService.showDataLoader = false;
          this.appointmentsType = resp["meetings"];
          this.appointmentsType.forEach((element) => {
            element["name"] = this.dataService.getResourceText(
              this.textService.textSource["HOME_SCREEN_SECTION_MID_ITEM_TITLE"],
              element
            );
            element["total_count"] = this.dataService.getResourceText(
              this.textService.textSource[
              "HOME_SCREEN_SECTION_MID_ITEM_SUBTITLE_1"
              ],
              element
            );
            element["scheduled_count"] = this.dataService.getResourceText(
              this.textService.textSource[
              "HOME_SCREEN_SECTION_MID_ITEM_SUBTITLE_3"
              ],
              element
            );
            element["scheduled_count"] =
              this.dataService.changeNumStartingLocation(
                element["scheduled_count"]
              );
            element["held_count"] = this.dataService.getResourceText(
              this.textService.textSource[
              "HOME_SCREEN_SECTION_MID_ITEM_SUBTITLE_2"
              ],
              element
            );
            element["held_count"] = this.dataService.changeNumStartingLocation(
              element["held_count"]
            );
          });
        },
        (err) => {
          this.dataService.handleErrors(err);
        }
      );
  }

  goTo(btnText) {
    if (btnText == "דיווח על עובד חדש") {
      this.router.navigate(["/register"]);
    } else if (
      btnText === this.textService.textSource["HOME_SCREEN_SECTION_TOP_TITLE"]
    ) {
      this.router.navigate(["/my-employees"]);
    }
  }
  goToMoreDetails() {
    if (this.type == "appointment") {
      this.router.navigate(["/appointments"]);
    } else if (this.type == "task") {
      this.router.navigate(["/tasks"]);
    } else if (this.type === "interface") {
      this.router.navigate(["/employers-interface"]);
    }
  }

  getEmployeesCountsInfo() {
    this.employeeService.getEmployeesCounts().subscribe(
      (resp) => {
        this.total_count = this.dataService.getResourceText(
          this.textService.textSource["MY_EMPLOYEES_SCREEN_SUBTITLE_1"],
          resp
        );
        this.total_actives = this.dataService.getResourceText(
          this.textService.textSource["MY_EMPLOYEES_SCREEN_SUBTITLE_2"],
          resp
        );
        this.total_in_actives = this.dataService.getResourceText(
          this.textService.textSource["MY_EMPLOYEES_SCREEN_SUBTITLE_3"],
          resp
        );
      },
      (err) => {
        this.dataService.handleErrors(err);
      }
    );
  }
}
