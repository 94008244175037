import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
} from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { DateFilter, DateRange } from "src/app/models/feedback.stats.model";
import { TextService } from "src/app/services/text.service";
import * as moment from "moment";
import { FeedbackService } from "src/app/services/feedback.service";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { BidiModule } from "@angular/cdk/bidi";
declare var $: any;

@Component({
    selector: "app-date-picker",
    templateUrl: "./date-picker.component.html",
    styleUrls: ["./date-picker.component.scss"],
    standalone: true,
    imports: [BidiModule, NgIf, MatLegacyFormFieldModule, MatLegacySelectModule, ReactiveFormsModule, FormsModule, NgFor, MatLegacyOptionModule, NgClass]
})
export class DatePickerComponent implements OnInit {
  constructor(
    public dataService: DataService,
    public textService: TextService,
    private cdr: ChangeDetectorRef
  ) {
    this.dataService.dataArrived.subscribe((res) => {
      if (this.selectedDateLabel && this.dateInput.from) {
        return;
      }
      this.selectedDateLabel =
        this.selectedDateLabel || this.optionForFilter[0].label;
      this.filter(this.optionForFilter[0]);
    });
  }

  @Input() optionForFilter: DateFilter[];
  @Output() emitDates = new EventEmitter();
  error_message_datepicker;
  show_error = false;
  selectedDateLabel: string;
  showGhostElementsForTopPage = false;
  name: string;
  dateInput = {
    from: null,
    to: null,
  };
  datePicker: any;

  ngOnInit(): void {}

  ngAfterContentInit(): void {
    this.initDatePicker();
    $(".date-input").val("");
  }

  ngAfterViewChecked(): void {
    this.cdr.detectChanges();
  }

  initDatePicker() {
    this.datePicker = $(".date-input")
      .daterangepicker(
        {
          autoApply: true,
          autoUpdateInput: false,
          opens: "left",
          locale: {
            format: "DD/MM/YYYY",
            separator: " - ",
            applyLabel: "אישור",
            cancelLabel: "ביטול",
            fromLabel: "מתאריך",
            toLabel: "עד תאריך",
            daysOfWeek: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
            monthNames: [
              "ינואר",
              "פברואר",
              "מרץ",
              "אפריל",
              "מאי",
              "יוני",
              "יולי",
              "אוגוסט",
              "ספטמבר",
              "אוקטובר",
              "נובמבר",
              "דצמבר",
            ],
            firstDay: 0,
          },
        },
        (start, end, label) => {
          this.dateInput.from = start.format("YYYY-MM-DD");
          this.dateInput.to = end.format("YYYY-MM-DD");
          $(".date-input").val(
            end.format("DD/MM/YY") + " - " + start.format("DD/MM/YY")
          );
          this.emitDates.emit(this.dateInput);
        }
      )
      .on("change", function (e, picker) {
        // let dates = e.target.value.split(' - ')
        // let from = moment(dates[0],'DD/MM/YYYY');
        // let to = moment(dates[1],'DD/MM/YYYY');
        // if(moment(from).format() === 'Invalid date' || moment(to).format() === 'Invalid date' ){
        //   $(".error-msg").text("יש להזין תאריך תקין בפורמט הבא: DD/MM/YYYY - DD/MM/YYYY");
        //   debugger
        // }else{
        //   $(".error-msg").text(" ");
        // }
      });
    $(".date-input").val("");
    $(".date-input").prop("disabled", true);
  }

  clearDate() {
    $(".date-input").val("");

    let date = this.optionForFilter[0];
    this.selectedDateLabel = date.label;
    this.filter(date);
    this.dateInput = date.date_range;
    this.emitDates.emit(this.dateInput);
    // $(".date-input").data('daterangepicker').setStartDate(this.dateInput.from);
    // $(".date-input").data('daterangepicker').setEndDate(this.dateInput.to);
  }

  datePicked(date) {
    this.filter(date);
    if (!date.value) {
      return;
    }
    this.dateInput = date.date_range;
    this.emitDates.emit(this.dateInput);
  }

  filter(date) {
    if (date["value"] != "") {
      this.dateInput["from"] = moment(date["date_range"]["from"]);
      this.dateInput["to"] = moment(date["date_range"]["to"]);
      $(".date-input").val(
        this.dateInput["to"].format("DD/MM/YY") +
          " - " +
          this.dateInput["from"].format("DD/MM/YY")
      );
      $(".date-input")
        .data("daterangepicker")
        .setStartDate(this.dateInput["from"].format("DD/MM/YY"));
      $(".date-input")
        .data("daterangepicker")
        .setEndDate(this.dateInput["to"].format("DD/MM/YY"));
      $(".date-input").prop("disabled", true);
    } else {
      $(".date-input").prop("disabled", false);
    }
  }
}
