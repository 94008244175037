import { Component, OnInit, ViewChild } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { FeedbackService } from "src/app/services/feedback.service";
import {
  FeedbackStatsModel,
  DateRange,
} from "src/app/models/feedback.stats.model";
import { UntypedFormControl, ReactiveFormsModule, FormsModule } from "@angular/forms";
import { TextService } from "src/app/services/text.service";
import { SearchService, ISearchable } from "src/app/services/search.service";
import { Subject } from "rxjs";
import { statSync } from "fs";
import { MatLegacyPaginator as MatPaginator, MatLegacyPaginatorModule } from "@angular/material/legacy-paginator";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
import { EmployersInterfaceCardComponent } from "../../generic-components/employers-interface-card/employers-interface-card.component";
import { MatLegacyInputModule } from "@angular/material/legacy-input";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { DropDownComponent } from "../../common/drop-down/drop-down.component";
import { EmployersInterfaceTypeDetailsComponent } from "../../generic-components/employers-interface-type-details/employers-interface-type-details.component";
import { NgIf, NgFor, NgClass } from "@angular/common";
import { HeaderComponent } from "../../common/header/header.component";
import { BreadcrumbsComponent } from "../../common/breadcrumbs/breadcrumbs.component";
import { BidiModule } from "@angular/cdk/bidi";

@Component({
    selector: "app-employers-interface",
    templateUrl: "./employers-interface.component.html",
    styleUrls: ["./employers-interface.component.scss"],
    standalone: true,
    imports: [BidiModule, BreadcrumbsComponent, HeaderComponent, NgIf, NgFor, NgClass, EmployersInterfaceTypeDetailsComponent, DropDownComponent, MatLegacyFormFieldModule, MatLegacyInputModule, ReactiveFormsModule, FormsModule, EmployersInterfaceCardComponent, MatLegacyPaginatorModule]
})
export class EmployersInterfaceComponent implements OnInit, ISearchable {
  // @ViewChild(MatPaginator, {static: false}) paginator: MatPaginator;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  endpoint: string = "/contact/feedback";
  threshold: number = 2;
  paramsObj: {
    skip: number;
    limit: number;
    type: string;
    status: string;
    from: string;
    to: string;
  };
  onSearch(): void {
    //this.employeeService.employeeCardsGhostElements = true;

    this.showCardGhost = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
  }
  textObserver(): import("rxjs").Observable<string> {
    return this.search_text.asObservable();
  }

  total_related_money;
  total_unrelated_money;
  total_money_sent;
  paginatorLength;
  breadcrumbsPages = [BreadcrumbsLinkNames.HOME, BreadcrumbsLinkNames.FEEDBACK];

  constructor(
    public searchService: SearchService,
    public textService: TextService,
    public dataService: DataService,
    private feedbackService: FeedbackService
  ) {
    this.textService.setTextSource();
    this.searchService.search(this).subscribe(
      (resp) => {
        this.onSearchResponse(resp);
      },
      (err) => {
        this.dataService.showDataLoader = false;
        this.dataService.handleErrors(err);
      }
    );
  }
  feedbackStatsData: FeedbackStatsModel;
  pieFeedbackData: any;
  selectedType: string;
  selectedDates: DateRange;
  // selectedDates:DateRange = {
  //   from:'2019-01-30',
  //   to: '2020-06-30'
  // }
  btnData = new Array(4);
  manufacturer = new UntypedFormControl("");
  statusFilter = new UntypedFormControl("");
  showCardGhost: boolean;
  status_title = "";
  absorption_rate = "";
  search_text = new Subject<string>();
  textModel;
  ngOnInit(): void {
    this.dataService.showHomePie = false;
    this.selectedType = "היזון חוזר כללי";

    this.dataService.showGhostElements = true;
    this.feedbackService
      .getFeedbackStats({
        // from: this.selectedDates.from,//delete
        // to:  this.selectedDates.to,//delete
        type: this.selectedType,
      })
      .subscribe((res) => {
        this.feedbackStatsData = res;
        this.absorption_rate = this.dataService.getResourceText(
          this.textService.textSource[
            "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_LABEL_4"
          ],
          this.feedbackStatsData
        );
        this.total_related_money = this.dataService.getResourceText(
          this.textService.textSource[
            "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_VALUE_2"
          ],
          this.feedbackStatsData
        );
        this.total_unrelated_money = this.dataService.getResourceText(
          this.textService.textSource[
            "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_VALUE_3"
          ],
          this.feedbackStatsData
        );
        this.total_money_sent = this.dataService.getResourceText(
          this.textService.textSource[
            "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_VALUE_1"
          ],
          this.feedbackStatsData
        );
        this.selectedDates = this.feedbackStatsData.date_filter[0].date_range;
        console.log(res);

        this.dataService.showGhostElements = false;
        setTimeout(() => {
          this.dataService.dataArrived.next();
          this.feedbackService.createChart.next();
        }, 0);
      });
  }

  getStats(query) {
    this.dataService.showGhostElements = true;
    this.feedbackService.getFeedbackStats(query).subscribe((res) => {
      this.feedbackStatsData = res;
      this.absorption_rate = this.dataService.getResourceText(
        this.textService.textSource[
          "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_LABEL_4"
        ],
        this.feedbackStatsData
      );
      this.total_related_money = this.dataService.getResourceText(
        this.textService.textSource[
          "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_VALUE_2"
        ],
        this.feedbackStatsData
      );
      this.total_unrelated_money = this.dataService.getResourceText(
        this.textService.textSource[
          "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_VALUE_3"
        ],
        this.feedbackStatsData
      );
      this.total_money_sent = this.dataService.getResourceText(
        this.textService.textSource[
          "EMPLOYERS_INTERFACE_SCREEN_FEEDBACK_VALUE_1"
        ],
        this.feedbackStatsData
      );

      console.log(res);

      this.dataService.showGhostElements = false;
      setTimeout(() => {
        this.dataService.dataArrived.next();
        this.feedbackService.createChart.next();
      }, 0);
    });
  }

  datesChanged(dates) {
    this.pieFeedbackData = null;
    this.paginatorLength = 0;
    this.selectedDates = dates;
    let data = {
      from: dates.from,
      to: dates.to,
      type: this.selectedType,
    };
    this.getStats(data);
  }

  typeClicked(type) {
    this.paginatorLength = 0;
    this.pieFeedbackData = null;
    this.textModel = "";
    this.selectedType = type;
    let data = {
      from: this.selectedDates.from,
      to: this.selectedDates.to,
      type: this.selectedType,
    };

    this.getStats(data);
  }

  getPieDetails(status) {
    //first function after clicking on pie
    this.status_title = status;
    this.textModel = "";
    this.paramsObj = {
      skip: 0,
      limit: 10,
      type: this.selectedType,
      status: status,
      from: this.selectedDates.from,
      to: this.selectedDates.to,
    };
    this.showCardGhost = true;
    if (!status) {
      return;
    }
    this.statusFilter.setValue(status);
    this.filterEmitted(0);
  }

  getSelectedPieData(data) {
    //click on pie part - server request
    this.feedbackService.getFeedback(data).subscribe((res) => {
      this.pieFeedbackData = res;
      this.paginatorLength = this.pieFeedbackData["pagination"]["total_count"];

      if (data.skip == 0 && this.paginatorLength > 10) {
        setTimeout(() => {
          this.paginator.pageIndex = 0;
        });
      }
      console.log(res);

      this.showCardGhost = false;
    });
  }

  filterEmitted(skip) {
    //click on pie part - collect data for the server request
    this.showCardGhost = true;

    let data = {
      skip: skip,
      limit: 10,
      from: this.selectedDates.from,
      to: this.selectedDates.to,
      type: this.selectedType,
    };
    if (this.manufacturer.value) {
      data["manufacturer"] = this.manufacturer.value;
    }
    if (this.statusFilter.value != "") {
      data["status"] = this.statusFilter.value;
    }
    this.getSelectedPieData(data);
  }

  onSearchResponse(resp: any) {
    this.pieFeedbackData = resp;
    this.paginatorLength = this.pieFeedbackData["pagination"]["total_count"];
    if (this.paginatorLength > 10) {
      setTimeout(() => {
        this.paginator.pageIndex = 0;
      });
    }
    console.log(resp);

    this.showCardGhost = false;
    this.dataService.showDataLoader = false;
  }

  onPageChange($event) {
    let startIndex = $event.pageIndex * $event.pageSize;
    this.filterEmitted(startIndex);
  }
}
