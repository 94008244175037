import { Component, Input, OnInit } from "@angular/core";
import { FileUploadService } from "src/app/services/file-upload.service";
import { ModalService } from "src/app/services/modal.service";
import { NgIf } from "@angular/common";

@Component({
    selector: "app-modal",
    templateUrl: "./modal.component.html",
    styleUrls: ["./modal.component.scss"],
    standalone: true,
    imports: [NgIf]
})
export class ModalComponent implements OnInit {
  @Input() showCloseButton: boolean = true;

  constructor(
    private modalService: ModalService,
    private fileUploadService: FileUploadService
  ) {}

  ngOnInit(): void {}

  closeModal() {
    this.modalService.closeModal();
    this.fileUploadService.removeFile();
  }
}
