import { Component, Input, OnInit } from "@angular/core";
import { NgClass, NgIf } from "@angular/common";

type ButtonStyles = {
  buttonBorder: "none" | "primary";
  buttonBackground: "primary" | "white";
  buttonTextAlign: "left" | "center" | "right";
};

type ArrowStyles = {
  arrowColor: "primary" | "white" | "black";
  arrowDirection: "left" | "right";
};

@Component({
    selector: "app-button",
    templateUrl: "./button.component.html",
    styleUrls: ["./button.component.scss"],
    standalone: true,
    imports: [NgClass, NgIf]
})
export class ButtonComponent implements OnInit {
  @Input() buttonText: string = "";
  @Input() arrowColor: ArrowStyles["arrowColor"] = "white";
  @Input() arrowDirection: ArrowStyles["arrowDirection"] = "left";
  @Input() arrowAbsolute: boolean = false;
  @Input() buttonWidth: number;
  @Input() disabled: boolean = false;
  @Input() isShowArrow: boolean = true;
  @Input() buttonBackground: ButtonStyles["buttonBackground"] = "primary";
  @Input() buttonBorder: ButtonStyles["buttonBorder"] = "none";
  @Input() buttonTextAlign: ButtonStyles["buttonTextAlign"] = "center";
  @Input() type: string = "button";

  constructor() {}

  ngOnInit(): void {}

  get arrowDirectionAndColor() {
    return `arrow__${this.arrowDirection}-${this.arrowColor}`;
  }

  get centerTextAndAbsoluteArrow() {
    return this.arrowAbsolute ? "absolute" : "";
  }

  get isDisabled() {
    return this.disabled ? "btn__disabled" : "";
  }

  get btnBackground() {
    return `btn__background-${this.buttonBackground}`;
  }
  get btnBorder() {
    return `btn__border-${this.buttonBorder}`;
  }
  get btnTextAlign() {
    return `btn__textAlign-${this.buttonTextAlign}`;
  }
}
