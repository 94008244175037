import { Injectable } from "@angular/core";
import * as moment from "moment";
import { BehaviorSubject } from "rxjs";
import { IRegisterUserData } from "../models/register-user-data.model";
import { RegisterPageService } from "./register-page.service";

enum FormOptionsEnum {
  INE_ID_TYPE = "ine_id_type",
  INE_INCAPACITY_TO_WORK = "ine_incapacity_to_work",
  INE_ELIGIBILITY_STUDY_FUND = "ine_eligibility_study_fund",
  INE_ELIGIBLE_HEALTH_INSURANCE = "ine_health_collective",
  INE_PARAGRAPH_14 = "ine_paragraph_14",
  INE_FAMILY_STATUS = "ine_family_status",
  INE_STUDY_FUND_DEPOSIT = "study_fund_deposit",
  INE_GENDER = "gender",
  LANGUAGE = "language",
  FOREIGN_EMPLOYEE = "foreign_employee",
}

export enum IdTypeEnum {
  ID = "ת.ז",
  PASSPORT = "דרכון",
}

enum SalaryTypeEnum {
  HOURLY = "שעתי",
  MONTHLY = "חודשי",
}

@Injectable({
  providedIn: "root",
})
export class RegisterDataService {
  private _formOptions = new BehaviorSubject<any>({});
  private dateFormats = ["DD/MM/YYYY", "D/M/YY", "YYYY-MM-DD"];

  constructor(private registerPageService: RegisterPageService) {}

  initFormOptions() {
    this.registerPageService.getFormOptions().subscribe((formOptions) => {
      this._formOptions.next(formOptions);
    });
  }

  getFormOptions() {
    return this._formOptions.asObservable();
  }

  private isForeignEmployee(id_type) {
    return id_type === IdTypeEnum.PASSPORT;
  }

  private buildForeignEmployeeObject(isForeignEmployee) {
    return {
      foreign_employee: isForeignEmployee,
    };
  }

  private buildPersonalDetailsObject(data: IRegisterUserData) {
    return {
      personal_details: {
        id_number_type: this.getFormOptionsDropdownValue(
          FormOptionsEnum.INE_ID_TYPE,
          data.id_number_type
        ),
        first_name: data.first_name,
        last_name: data.last_name,
        id_number: data.id_number,
        mobile_phone: data.mobile_phone,
        email: data.email,
        gender: this.getFormOptionsDropdownValue(
          FormOptionsEnum.INE_GENDER,
          data.gender
        ),
        family_status: this.getFormOptionsDropdownValue(
          FormOptionsEnum.INE_FAMILY_STATUS,
          data.family_status
        ),
        street: data.street,
        city: data.city,
        bulding_number: data.bulding_number,
        date_of_birth: moment(data.date_of_birth as string, this.dateFormats)
          .format("YYYY-MM-DD")
          .toString(),
        foreign_employee: this.getFormOptionsDropdownValue(
          FormOptionsEnum.FOREIGN_EMPLOYEE,
          data.foreign_employee
        ),
        language: this.getFormOptionsDropdownValue(
          FormOptionsEnum.LANGUAGE,
          data.language
        ),
      },
    };
  }

  private buildEmployeeDetailsObject(data: IRegisterUserData) {
    const { salary_type } = data;
    // api field sent is depending on salary_type - either 'salary' or 'hourlyPayment'
    const salaryAPIField =
      salary_type === SalaryTypeEnum.MONTHLY ? "salary" : "hourly_payment";

    return {
      work_details: {
        role: data.role,
        employer_number: Number(data.employer_number), // needs to be a number
        start_work_date: moment(
          data.start_work_date as string,
          this.dateFormats
        )
          .format("YYYY-MM-DD")
          .toString(),
        monthly_pension_salary: data.monthly_pension_salary,
        [salaryAPIField]: data.salary,
      },
      other: {
        eligible_health_insurance: this.getFormOptionsDropdownValue(
          FormOptionsEnum.INE_ELIGIBLE_HEALTH_INSURANCE,
          data.eligible_health_insurance
        ),
      },
      social_allowances: {
        eligibility_date: data.eligibility_date
          ? moment(data.eligibility_date as string, this.dateFormats)
              .format("YYYY-MM-DD")
              .toString()
          : null,
        employer_benefits: data.employer_benefits,
        employee_benefits: data.employee_benefits,
        allowance_compensations_percentage:
          data.allowance_compensations_percentage,
        paragraph_14: this.getFormOptionsDropdownValue(
          FormOptionsEnum.INE_PARAGRAPH_14,
          data.paragraph_14
        ),
      },
      fund_study: {
        eligibility_study_fund: this.getFormOptionsDropdownValue(
          FormOptionsEnum.INE_ELIGIBILITY_STUDY_FUND,
          data.eligibility_study_fund
        ),
        eligibility_date_study_fund: data.eligibility_date_study_fund
          ? moment(data.eligibility_date_study_fund as string, this.dateFormats)
              .format("YYYY-MM-DD")
              .toString()
          : null,
        study_fund_employer_deposit: data.study_fund_employer_deposit || null,
        study_fund_employee_deposit: data.study_fund_employee_deposit || null,
        study_fund_deposit: this.getFormOptionsDropdownValue(
          FormOptionsEnum.INE_STUDY_FUND_DEPOSIT,
          data.study_fund_deposit
        ),
      },
    };
  }

  /* return the formOption value of given dropdown labels */
  private getFormOptionsDropdownValue(formOption, controlValue) {
    const formOptions = this._formOptions.value[formOption];
    const foundOption = formOptions.find(
      (option) => option.label === controlValue
    );

    return foundOption ? foundOption.value : "";
  }

  buildRegisterUserBody(data: IRegisterUserData) {
    const personalDetails = this.buildPersonalDetailsObject(data);
    const workDetails = this.buildEmployeeDetailsObject(data);

    let parsedBody = { ...personalDetails, ...workDetails };

    /* convert necessary string/null fields into values salesforce accepts */
    const { study_fund_deposit } = parsedBody["fund_study"];
    const { eligible_health_insurance } = parsedBody["other"];
    if (study_fund_deposit === "") {
      parsedBody["fund_study"].study_fund_deposit = null;
    }
    if (eligible_health_insurance === null) {
      parsedBody["other"].eligible_health_insurance = "";
    }

    return parsedBody;
  }
}
