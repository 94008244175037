import { Component, OnInit } from "@angular/core";
import { Toast } from "src/app/models/toast.interface";
import { ToasterService } from "src/app/services/toaster.service";
import { ToasterComponent } from "./toaster/toaster.component";
import { NgFor } from "@angular/common";

@Component({
    selector: "app-toaster-container",
    templateUrl: "./toaster-container.component.html",
    styleUrls: ["./toaster-container.component.scss"],
    standalone: true,
    imports: [NgFor, ToasterComponent]
})
export class ToasterContainerComponent implements OnInit {
  toasts: Toast[] = [];

  constructor(public toaster: ToasterService) {}

  ngOnInit() {
    this.toaster.toast$.subscribe((toast) => {
      this.toasts = [toast, ...this.toasts];
      setTimeout(() => this.toasts.pop(), toast.delay || 6000);
    });
  }

  remove(index: number) {
    this.toasts = this.toasts.filter((v, i) => i !== index);
  }
}
