import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators, ReactiveFormsModule } from '@angular/forms';
import * as moment from 'moment';
import { WorkTerminationDataEnum, IRetirementGrantPaymentRaterItem } from 'src/app/models/work-termination.model';
import { TextService } from 'src/app/services/text.service';
import { InputComponent } from '../../../common/input/input.component';

@Component({
    selector: 'app-retirement-grant-payment-rater-item',
    templateUrl: './retirement-grant-payment-rater-item.component.html',
    styleUrls: ['./retirement-grant-payment-rater-item.component.scss'],
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: RetirementGrantPaymentRaterItemComponent,
            multi: true,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: RetirementGrantPaymentRaterItemComponent,
            multi: true,
        },
    ],
    standalone: true,
    imports: [ReactiveFormsModule, InputComponent]
})
export class RetirementGrantPaymentRaterItemComponent implements OnInit, ControlValueAccessor, Validator {

  workTerminationDataEnum = WorkTerminationDataEnum;

  item = this.fb.group({
    [this.workTerminationDataEnum.PAYMENT_DATE]: new UntypedFormControl("",[this.validateDate]),
    [this.workTerminationDataEnum.PAYMENT_SUM]: new UntypedFormControl(
      "",[
        Validators.pattern(/^[0-9.]+$/i),

      ]
    )
  });

  callBack?: any;

  constructor(
    public textService: TextService,
    private readonly fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.item.valueChanges.subscribe((o) => {
      if (this.callBack) {
        this.callBack(o);
      }
    });
  }

  validateDate(control: UntypedFormControl) {
    if (control.value) {
      const date = moment(control.value);
      const isValid = date.isValid();
      if (!isValid) {
        return { date: true };
      }
    }
    return null;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.item.valid) {
      return null;
    }
    return { item: "not-valid" };
  }

  writeValue(obj: IRetirementGrantPaymentRaterItem): void {
    if (!obj) {
      return;
    }
    this.item.patchValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.callBack = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.item.disable();
    } else {
      this.item.enable();
    }
  }
}
