import { MatLegacyPaginatorIntl as MatPaginatorIntl } from "@angular/material/legacy-paginator";

const hebrewRangeLabel = (page: number, pageSize: number, length: number) => {
  if (length == 0 || pageSize == 0) {
    return ``;
  }

  length = Math.max(length, 0);

  const startIndex = page * pageSize;
  const totalPages = length < pageSize ? 1 : Math.ceil(length / pageSize);
  // If the start index exceeds the list length, do not try and fix the end index to the end.
  const endIndex =
    startIndex < length
      ? Math.min(startIndex + pageSize, length)
      : startIndex + pageSize;

  return `עמוד ${page + 1} מתוך ${totalPages}`;
};

export function getHebrewPaginatorIntl() {
  const paginatorIntl = new MatPaginatorIntl();

  paginatorIntl.itemsPerPageLabel = "פריטים לעמוד:";
  paginatorIntl.nextPageLabel = "";
  paginatorIntl.previousPageLabel = "";
  paginatorIntl.getRangeLabel = hebrewRangeLabel;

  return paginatorIntl;
}
