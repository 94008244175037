import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders, HttpParams } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../../environments/environment";
import { HttpService } from "./http.service";
import { ErrorService } from "./error.service";
import { catchError } from "rxjs/operators";


const ADMIN_TOKEN_KEY = "a-auth-token";

@Injectable()
export class HttpHandler {
  headerSubscriber: any;
  private baseUrl = environment.url;
  public headers = new HttpHeaders();

  constructor(
    public http: HttpClient,
    public httpService: HttpService,
    public errorService: ErrorService
  ) {
    this.headers = new HttpHeaders({
      "Content-Type": "application/json",
    }).append(environment.auth_header_key, environment.auth_header_value);

    let tokenKey = "token";
    let tokenKeyAdmin = 'a-auth-token'


    if (window.location.href.includes("/dashboard/createBanner")) {
      this.headers = new HttpHeaders({
        // 'Content-Type': 'application/json',
      }).append(environment.auth_header_key, environment.auth_header_value);
    }

    if (sessionStorage.getItem(tokenKey)) {
      this.headers = this.headers.set(
        "token",
        sessionStorage.getItem(tokenKey)
      ); // remove this line ( only for dev purpose )
    }
    if (sessionStorage.getItem(tokenKeyAdmin)) {
      this.headers = this.headers.append(tokenKeyAdmin, sessionStorage.getItem(tokenKeyAdmin));
    }
    // this.headers= this.headers.set('token', "556d8e2953222ba596821cebbc35dddfb9ba9ae2c44206796f…qucilb1V4mt+1fX1Kkvcomn0sYwV8DarVyG43wRB2nhz8Mw==" )// remove this line ( only for dev purpose )
    if (this.httpService.headers.getValue()) {
      let headers = this.httpService.headers.getValue();
      this.headers = this.headers.set(headers.key, headers.value);
      sessionStorage.setItem(tokenKey, headers.value);
    }

    this.headerSubscriber = this.httpService.headers.subscribe(
      (updatedValue) => {
        if (updatedValue) {
          this.headers = this.headers.set(updatedValue.key, updatedValue.value);
        }
      }
    );
  }

  public download(
    url: string,
    params: HttpParams = undefined,
    isAdmin?: boolean
  ): Observable<any> {
    this.checkForAdmin(isAdmin);
    return this.http
      .get(this.baseUrl + url, {
        params: params,
        headers: this.headers,
        withCredentials: true,
        responseType: "blob",
      })
      .pipe(catchError(this.errorService.handleError));
  }

  public get(
    url: string,
    params: HttpParams = undefined,
    isAdmin?: boolean
  ): Observable<any> {
    this.checkForAdmin(isAdmin);
    // this.setAccessToken(accessToken);
    return this.http
      .get(this.baseUrl + url, {
        params: params,
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(catchError(this.errorService.handleError));
  }
  public async getAsync(
    url: string,
    params: HttpParams = undefined,
    isAdmin?: boolean
  ) {
    this.checkForAdmin(isAdmin);
    // this.setAccessToken(accessToken);
    return await this.http
      .get(this.baseUrl + url, {
        params: params,
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(catchError(this.errorService.handleError));
  }

  public delete(url: string, params: HttpParams = undefined): Observable<any> {
    return this.http.delete(this.baseUrl + url, {
      params: params,
      headers: this.headers,
    });
  }

  public put(url: string, body: any, isAdmin?: boolean): Observable<any> {
    this.checkForAdmin(isAdmin);
    return this.http.put(this.baseUrl + url, body, {
      headers: this.headers,
      withCredentials: true,
    });
  }

  public post(url: string, body: any, isAdmin?: boolean): Observable<any> {
    this.checkForAdmin(isAdmin);
    if (this.getAdminToken() != undefined) {
      this.headers = this.headers.set(
        "ADMIN_TOKEN_KEY",
        sessionStorage.getItem(ADMIN_TOKEN_KEY)
      );
      // header = new HttpHeaders({
      //   ADMIN_TOKEN_KEY: sessionStorage.getItem(ADMIN_TOKEN_KEY)
      // }).append(environment.auth_header_key, 'HoJ20bK27$yj5WOmJ12hEy0iq0FC1A8j');
    }

    return this.http
      .post(this.baseUrl + url, body, {
        headers: this.headers,
        withCredentials: true,
      })
      .pipe(catchError(this.errorService.handleError));
  }

  public postFiles(url: string, body: FormData) {
    const newHeaders = this.headers.delete("Content-Type");

    return this.http
      .post(this.baseUrl + url, body, {
        headers: newHeaders,
        withCredentials: true,
      })
      .pipe(catchError(this.errorService.handleError));
  }

  public request(
    method: string,
    url: string,
    params?: HttpParams,
    body?: any,
    isAdmin?: boolean
  ): Observable<any> {
    let isAdminFallback = isAdmin || this.getAdminToken() != undefined;
    this.checkForAdmin(isAdminFallback);

    return this.http
      .request(method, this.baseUrl + url, { body, headers: this.headers })
      .pipe(catchError(this.errorService.handleError));
  }

  ngOnDestroy(): void {
    if (this.headerSubscriber) {
      this.headerSubscriber.unsubscribe();
    }
  }

  private checkForAdmin(isAdmin: boolean) {
    if (isAdmin) {
      this.baseUrl = this.baseUrl.replace("api/v1", "admin");
    }
  }

  public setAccessToken(accessToken: any) {
    this.headers = this.headers.set("token", accessToken);
  }

  protected getAdminToken() {
    return sessionStorage.getItem(ADMIN_TOKEN_KEY);
  }
}
