// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.highcharts-credits {
  display: none !important;
}

.no-content {
  margin-top: 70px;
  margin-bottom: 20px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/pie-chart/pie-chart.component.scss"],"names":[],"mappings":"AACA;EACE,wBAAA;AAAF;;AAGA;EACE,gBAAA;EACA,mBAAA;AAAF","sourcesContent":["// @import 'https://code.highcharts.com/css/highcharts.css';\n.highcharts-credits {\n  display: none !important;\n}\n\n.no-content {\n  margin-top: 70px;\n  margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
