import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, throwError } from "rxjs";
import { filter, switchMap } from "rxjs/operators";
import { IRegisterUserData } from "../models/register-user-data.model";
import { DataService } from "./data.service";
import { ErrorService } from "./error.service";
import { HttpService } from "./http.service";
import { HttpHandler } from "./httpHandler";
import { ModalService } from "./modal.service";
import { ToasterService } from "./toaster.service";

enum FileType {
  XLSX = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  CSV = "text/csv",
}

export enum FileImage {
  FILE_CHOSEN = "excel-1",
  FILE_NOT_CHOSEN = "cloud",
  FILE_NOT_VALID = "file-error",
}

interface IParsedFile {
  correct: Array<IRegisterUserData>;
  incorrect: Array<IRegisterUserData>;
}

@Injectable({
  providedIn: "root",
})
export class FileUploadService extends HttpHandler {
  private _file = new BehaviorSubject<File>(null);
  private _fileImage = new BehaviorSubject(FileImage.FILE_NOT_CHOSEN);
  private _parsedFileResponse = new BehaviorSubject<IParsedFile>(
    {} as IParsedFile
  );
  private _templateUrl = new BehaviorSubject<string>("");
  private _allowPageAccess = false;
  private type;

  constructor(
    _http: HttpClient,
    httpService: HttpService,
    errorService: ErrorService,
    public dataService: DataService,
    private router: Router,
    private modalService: ModalService,
    private toasterService:ToasterService
  ) {
    super(_http, httpService, errorService);
  }
  setType(type) {
    this.type = type;
  }
  getType() {
    return this.type;
  }

  getFile() {
    return this._file.asObservable();
  }

  getFileImage() {
    return this._fileImage.asObservable();
  }

  getParsedFileResponse() {
    return this._parsedFileResponse.asObservable();
  }

  getTemplateUrlObs() {
    return this._templateUrl
      .asObservable()
      .pipe(filter((templateUrl) => templateUrl !== ""));
  }

  getTemplateUrl() {
    const url = this.type ? "/template-file/new-employees" : "/template-file/work-termination";
    this.get(url).subscribe((response) => {
      const { template_url } = response;
      this._templateUrl.next(template_url);
    });
  }


  setFile(value: File) {
    const isValidFileType = this.checkFileType(value.type);
    const isValidFileSize = this.checkFileSize(value.size);

    if (isValidFileType && isValidFileSize) {
      this._file.next(value);
      this._fileImage.next(FileImage.FILE_CHOSEN);
    } else {
      console.log("WRONG FILE TYPE OR SIZE:", value.type, value.size);
      this._file.next(null);
      this._fileImage.next(FileImage.FILE_NOT_VALID);
    }
  }

  private checkFileType(type: string) {
    return type === FileType.CSV || type === FileType.XLSX;
  }

  private checkFileSize(size: number) {
    return size <= 5000000;
  }

  sendFile(file: File) {
    const url = this.type ? "/read-file/new-employees" : "/read-file/employees-work-termination";
    const formData: FormData = new FormData();
    formData.append("file", file, file.name);
    this.dataService.showDataLoader = true;
    this.postFiles(url, formData).subscribe((resp) => {
      this.dataService.showDataLoader = false;
      this.modalService.closeModal();
      this._parsedFileResponse.next(resp["result"] as IParsedFile);

      this.setAllowUploadNewEmployeesPageAccess(true);
      this.router.navigateByUrl(this.type ? "/import-new-employees" : "/import-employees-work-termination");
      this._fileImage.next(FileImage.FILE_NOT_CHOSEN);

    });
  }

  sendFileAttachment() {
    const url = "/salesforce-attachment";
    const formData: FormData = new FormData();
    formData.append("files[]", this._file.value, this._file.value.name);

    return this.postFiles(url, formData).toPromise();
  }

  patchRecordValue(recordId) {
    const { correct, incorrect } = this._parsedFileResponse.getValue();

    //search for id
    const incorrectRecord = incorrect.find(
      (record) => record["recordId"] === recordId.recordId
    );
    const correctRecord = correct.find(
      (record) => record["recordId"] === recordId.recordId
    );

    if (correctRecord) {
      const newCorrectRecords = correct.map((record) => {
        return record["recordId"] === recordId.recordId ? recordId : record;
      });

      this._parsedFileResponse.next({ incorrect, correct: newCorrectRecords });
    }

    if (incorrectRecord) {
      const newIncorrect = incorrect.filter(
        (record) => record["recordId"] !== recordId.recordId
      );
      const newCorrect = [...correct, recordId];

      this._parsedFileResponse.next({
        incorrect: newIncorrect,
        correct: newCorrect,
      });
    }
    this.toasterService.show(
      "success",
      "success!",
      "רשומה עודכנה בהצלחה",
      2000
    );
  }

  getAllowUploadNewEmployeesPageAccess() {
    return this._allowPageAccess;
  }
  setAllowUploadNewEmployeesPageAccess(permission: boolean) {
    this._allowPageAccess = permission;
  }

  removeFile() {
    if (this._file.value) {
      this._file.next(null);
      this._fileImage.next(FileImage.FILE_NOT_CHOSEN);
    }
  }
}
