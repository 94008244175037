import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { Router } from "@angular/router";
import { PopupTypes } from "src/app/models/popuptype";
import { NgIf } from "@angular/common";

@Component({
    selector: "app-general-error-popup",
    templateUrl: "./general-error-popup.component.html",
    styleUrls: ["./general-error-popup.component.scss"],
    standalone: true,
    imports: [NgIf]
})
export class GeneralErrorPopupComponent implements OnInit {
  name = this.dataService.name;
  errorMessage = "";

  constructor(private router: Router, private dataService: DataService) {}

  ngOnInit(): void {
    if (this.dataService.error_message_popup != "") {
      this.errorMessage = this.dataService.error_message_popup;
    }
  }

  goToHomePage() {
    this.router.navigate(["/"]);
    this.dataService.closePopup(PopupTypes.error);
  }
}
