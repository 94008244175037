// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.site__logo {
  width: 75px;
  height: 75px;
}
.site__logo img {
  display: inline-block;
  width: 100%;
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/logo/logo.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;AACF;AAAE;EACE,qBAAA;EACA,WAAA;EACA,YAAA;AAEJ","sourcesContent":[".site__logo {\n  width: 75px;\n  height: 75px;\n  img {\n    display: inline-block;\n    width: 100%;\n    height: 100%;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
