import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { NgIf } from "@angular/common";
@Component({
    selector: "app-no-content",
    templateUrl: "./no-content.component.html",
    styleUrls: ["./no-content.component.scss"],
    standalone: true,
    imports: [NgIf]
})
export class NoContentComponent implements OnInit {
  @Input() text = "";
  constructor(public dataService: DataService) {}

  ngOnInit(): void {}
}
