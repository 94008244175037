import { Component, Input, OnInit } from "@angular/core";
import { ReactiveFormsModule, UntypedFormGroup } from "@angular/forms";
import { TextService } from "src/app/services/text.service";
import { WorkTerminationDataEnum } from "src/app/models/work-termination.model";
import { CommonModule, NgIf } from "@angular/common";
import { RadioButtonComponent } from "../../../common/radio-button/radio-button.component";
import { InputComponent } from "../../../common/input/input.component";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { ISelectOption } from "src/app/models/import-new-employees.model";
import { WorkTerminationPeriodArrayComponentComponent } from "../work-termination-period-array-component/work-termination-period-array-component.component";
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { WorkTerminationTooltipText } from "src/texts/texts.gur";
declare var $: any;


enum DefaultBulletOptionsLabelsEnum {
  YES = "כן",
  NO = "לא",
}

@Component({
  selector: "app-work-termination",
  templateUrl: "./work-termination.component.html",
  styleUrls: ["./work-termination.component.scss"],
  standalone: true,
  imports: [InputComponent, ReactiveFormsModule, RadioButtonComponent, NgIf, CommonModule, MatLegacyOptionModule, MatLegacySelectModule, MatLegacyFormFieldModule, WorkTerminationPeriodArrayComponentComponent, NgbTooltipModule]
})

export class WorkTerminationComponent implements OnInit {
  @Input() workTerminationFormGroup: UntypedFormGroup;
  @Input() controllingOwnerOptions = [];
  @Input() authorizedToSignOptions = [];
  @Input() authorizedToViewOptions = [];
  @Input() foreignLanguageOptions = [];
  @Input() isExcelUpload = false;
  workTerminationDataEnum = WorkTerminationDataEnum;
  tooltipText = WorkTerminationTooltipText;
  textSource;
  defaultBulletOptions: ISelectOption[] = [
    { label: DefaultBulletOptionsLabelsEnum.YES, value: true },
    { label: DefaultBulletOptionsLabelsEnum.NO, value: false },
  ];
  validation_messages = {
    authorizedSignatory161: [{ type: "required", message: "חובה להזין מורשה חתימה תקין" }]
  }
  constructor(public textService: TextService) {

  }

  ngOnInit(): void {
    if (!this.textService.textSource) {
      this.textService.setTextSource();
    }
    this.textSource = this.textService.textSource;
    
  }
}
