import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";
import { UntypedFormControl, ReactiveFormsModule } from "@angular/forms";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { NgFor } from "@angular/common";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";

@Component({
    selector: "app-drop-down",
    templateUrl: "./drop-down.component.html",
    styleUrls: ["./drop-down.component.scss"],
    standalone: true,
    imports: [MatLegacyFormFieldModule, MatLegacySelectModule, ReactiveFormsModule, NgFor, MatLegacyOptionModule]
})
export class DropDownComponent implements OnInit {
  constructor() {}

  @Input() placeholder: string;
  @Input() selectOptions: any;
  @Input() control: UntypedFormControl;
  @Output() valueChangedEmit = new EventEmitter();

  ngOnInit(): void {}

  valueChanged() {
    this.valueChangedEmit.emit();
  }
}
