import { Component, OnInit, Input, Output, EventEmitter } from "@angular/core";

import { BasePopup } from "../../../models/popuptype";
import { DataService } from "src/app/services/data.service";
import { Router, RouterLinkActive } from "@angular/router";
import { StringNullableChain } from "lodash";
import { GeneralErrorPopupComponent } from "../../pages/general-error-popup/general-error-popup.component";
import { NewAppointmentPopupComponent } from "../../pages/new-appointment-popup/new-appointment-popup.component";
import { ThankYouComponent } from "../../pages/thank-you/thank-you.component";
import { MatLegacyButtonModule } from "@angular/material/legacy-button";
import { CommonModule} from "@angular/common";
import { ExistsWorkTerminationProcessComponent } from "../../pages/exists-work-termination-process/exists-work-termination-process.component";
import { SuccessfullyLoadingComponent } from "../../successfully-loading/successfully-loading.component";
import { MonthlyOrHourlyComponent } from "src/app/monthly-or-hourly/monthly-or-hourly.component";
@Component({
    selector: "app-general-popup",
    templateUrl: "./general-popup.component.html",
    styleUrls: ["./general-popup.component.scss"],
    standalone: true,
    imports: [CommonModule, MatLegacyButtonModule, RouterLinkActive, ThankYouComponent, NewAppointmentPopupComponent, GeneralErrorPopupComponent, ExistsWorkTerminationProcessComponent, SuccessfullyLoadingComponent, MonthlyOrHourlyComponent]
})
export class GeneralPopupComponent extends BasePopup implements OnInit {
  @Input() popup_type: number = this.PopupTypes.error;
  @Input() allowClose: boolean = true;
  @Input() userName: string;
  @Input() errorMessage = "";
  @Output() closeBranchPopup = new EventEmitter();
  // @Output() notifyGrandparent = new EventEmitter<any>();

  constructor(public dataService: DataService, private router: Router) {
    super();
  }

  ngOnInit(): void {}

  goTo(url) {
    this.dataService.closePopup(this.popup_type);
    if (url == "/") {
      this.dataService.logOut();
    } else if (url == "home") {
      this.router.navigate(["/"]);
    } else {
      this.router.navigate([url]);
    }
  }

  closePopup() {
    this.dataService.showDataLoader = false;
    this.dataService.closePopup(this.popup_type);
  }

  closeTheBranchPopup() {
    this.closeBranchPopup.emit();
  }
  // onSalaryMethodChange(selectedMethod: any) {
  //    this.notifyGrandparent.emit(selectedMethod);
  // }
}
