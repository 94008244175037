import { Component, OnInit, Input } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { Router } from "@angular/router";
import * as moment from "moment";

import { TextService } from "../../../services/text.service";
import { CommonModule } from "@angular/common";
@Component({
    selector: "app-policy-component",
    templateUrl: "./policy-component.component.html",
    styleUrls: ["./policy-component.component.scss"],
    standalone: true,
    imports: [CommonModule]
})
export class PolicyComponentComponent implements OnInit {
  @Input() policy;
  @Input() foreign_workers_premium
  title = "";
  numberPolicy = "";
  date_policy = "";
  @Input() foreign_employee_policies_count = 500;
  date_text = "";

  constructor(
    public dataService: DataService,
    public router: Router,
    public textService: TextService
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    this.title = this.policy["name"];
    this.numberPolicy = this.dataService.getResourceText(
      this.textService.textSource["POLICIES_SCREEN_SECTION_NUMBER"],
      this.policy
    );
    if (this.policy["end_of_work_date"] != "") {
      this.date_policy = this.policy["end_of_work_date"];
      this.policy["end_of_work_date"] = moment(this.date_policy).format(
        "DD-MM-YYYY"
      );

      this.date_text = this.dataService.getResourceText(
        this.textService.textSource["POLICIES_SCREEN_SECTION_LABEL"],
        this.policy
      );
    }
  }
  goToPolicy() {
    this.router.navigate(["/foreign", this.foreign_employee_policies_count]);
  }
  goToElementaryPolicies(){
    this.router.navigate(["/elementary-policies"]);

  }
}
