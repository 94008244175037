import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DataService } from 'src/app/services/data.service';
import { Router } from '@angular/router';
import { TextService } from 'src/app/services/text.service';

@Component({
  selector: 'app-exists-work-termination-process',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './exists-work-termination-process.component.html',
  styleUrls: ['./exists-work-termination-process.component.scss']
})
export class ExistsWorkTerminationProcessComponent {
  constructor( private router: Router,public dataService: DataService,public textService: TextService,){

  }
  name = this.dataService.name;
  goToHomePage() {
    this.dataService.showIsExistsProcedurePopup = false;
    this.router.navigateByUrl("/");
  }
}
