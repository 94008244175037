import { Component, ElementRef, Input, ViewChild } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { BidiModule } from "@angular/cdk/bidi";
import { NgIf, NgFor, NgClass } from "@angular/common";

@Component({
    selector: "app-new-employees-welcome-table",
    templateUrl: "./new-employees-welcome-table.component.html",
    styleUrls: ["./new-employees-welcome-table.component.scss"],
    standalone: true,
    imports: [NgIf, BidiModule, NgFor, NgClass]
})
export class NewEmployeesWelcomeTableComponent {
  @Input() data;
  @ViewChild("table") myTable: ElementRef;
  isSticky: boolean = false;

  constructor(public dataService: DataService) {}

  detectTableScroll(event) {
    const horizontal = event.currentTarget.scrollLeft;
    const maxScrollLeft =
      this.myTable.nativeElement.scrollWidth -
      this.myTable.nativeElement.clientWidth;
    const scrollDifference = maxScrollLeft - horizontal;

    this.isSticky = scrollDifference > 0 ? true : false;
  }
}
