export enum BreadcrumbsLinkNames {
  HOME = "ראשי",
  REGISTER = "הקמת עובד",
  MY_EMPLOYEES = "העובדים שלי",
  EMPLOYEE_CARD = "פרטי העובד",
  TASKS = "תהליכים",
  MEETINGS = "פגישות",
  FEEDBACK = "היזון חוזר",
  COMPANY_POLICIES = "הפוליסות של החברה",
  COMPANY_ELEMENTARY_POLICIES = "פוליסות עובדים זרים",
  TASK_TYPE_NEW_EMPLOYEES = "עובדים חדשים",
  TASK_TYPE_EMPLOYEE_DEPARTURE = "עזיבות עבודה",
  TASK_TYPE_ACCUMULATION_FORMS = "הזמנת טפסי צבירות",
  TASK_TYPE_WELCOME = "עובדים חדשים תהליכי WELCOME",
  IMPORT_NEW_EMPLOYEES = "ייבוא נתוני עובדים",
  IMPORT_EMPLOYEES_WORK_TERMINATION = "ייבוא נתוני עזיבות עובדים",
  EMPLOYER_ACTIONS = "פעולות למעסיק",
}

export const breadcrumbs = [
  {
    name: BreadcrumbsLinkNames.HOME,
    link: "/",
  },
  {
    name: BreadcrumbsLinkNames.REGISTER,
    link: "/register",
  },
  {
    name: BreadcrumbsLinkNames.MY_EMPLOYEES,
    link: "/my-employees",
  },
  {
    name: BreadcrumbsLinkNames.EMPLOYEE_CARD,
    link: "/employee-card",
  },
  {
    name: BreadcrumbsLinkNames.TASKS,
    link: "/tasks",
  },
  {
    name: BreadcrumbsLinkNames.FEEDBACK,
    link: "/employers-interface",
  },
  {
    name: BreadcrumbsLinkNames.EMPLOYER_ACTIONS,
    link: "/action",
  },
  {
    name: BreadcrumbsLinkNames.COMPANY_POLICIES,
    link: "/policy",
  },
  {
    name: BreadcrumbsLinkNames.MEETINGS,
    link: "/appointments",
  },
  {
    name: BreadcrumbsLinkNames.TASK_TYPE_NEW_EMPLOYEES,
    link: `/task-type/${BreadcrumbsLinkNames.TASK_TYPE_NEW_EMPLOYEES}`,
  },
  {
    name: BreadcrumbsLinkNames.TASK_TYPE_EMPLOYEE_DEPARTURE,
    link: `/task-type/${BreadcrumbsLinkNames.TASK_TYPE_EMPLOYEE_DEPARTURE}`,
  },
  {
    name: BreadcrumbsLinkNames.TASK_TYPE_ACCUMULATION_FORMS,
    link: `/task-type/${BreadcrumbsLinkNames.TASK_TYPE_ACCUMULATION_FORMS}`,
  },
  {
    name: BreadcrumbsLinkNames.TASK_TYPE_WELCOME,
    link: `/task-type/${BreadcrumbsLinkNames.TASK_TYPE_WELCOME}`,
  },
  {
    name: BreadcrumbsLinkNames.IMPORT_NEW_EMPLOYEES,
    link: "",
  },
  {
    name: BreadcrumbsLinkNames.IMPORT_EMPLOYEES_WORK_TERMINATION,
    link: "",
  },
  {
    name: BreadcrumbsLinkNames.COMPANY_ELEMENTARY_POLICIES,
    link: "elementary-policies",
  },
];
