import { Injectable } from "@angular/core";
import { HttpErrorResponse } from "@angular/common/http";
import { throwError } from "rxjs/internal/observable/throwError";
import { DataService } from "./data.service";

@Injectable({
  providedIn: "root",
})
export class ErrorService {
  constructor() {}

  public handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error("An error occurred:", error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
          `body was: ${error.error}` +
          `Message: ${error.error ? error.error.message : ""}`
      );
    }
    // return an observable with a user-facing error message
    return throwError({
      code: error.status,
      message: error.error
        ? error.error.message
        : "Something bad happened; please try again later.",
    });
  }
}
