import { Component, Input } from "@angular/core";
import { DataService } from "src/app/services/data.service";
import { CommonModule } from "@angular/common";

@Component({
  selector: 'app-elementary-policies-table',
  templateUrl: './elementary-policies-table.component.html',
  styleUrls: ['./elementary-policies-table.component.scss'],
    standalone: true,
    imports: [CommonModule]
})
export class ElementaryPoliciesTableComponent {
  @Input() data;

  constructor(
    public dataService: DataService,
  ) {}


}
