import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, ReactiveFormsModule } from '@angular/forms';
import { WorkTerminationDataEnum, IPensionBeforeRetirementItem } from 'src/app/models/work-termination.model';
import { TextService } from 'src/app/services/text.service';
import { PensionBeforeRetirementItemComponent } from '../pension-before-retirement-item/pension-before-retirement-item.component';
import { NgFor, NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-pension-before-retirement-array',
    templateUrl: './pension-before-retirement-array.component.html',
    styleUrls: ['./pension-before-retirement-array.component.scss'],
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: PensionBeforeRetirementArrayComponent,
            multi: true,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: PensionBeforeRetirementArrayComponent,
            multi: true,
        },
    ],
    standalone: true,
    imports: [NgFor, PensionBeforeRetirementItemComponent, ReactiveFormsModule, NgClass, NgIf]
})
export class PensionBeforeRetirementArrayComponent implements OnInit, ControlValueAccessor, Validator {
  array_error_message = "";
  workTerminationDataEnum = WorkTerminationDataEnum;
  array = this.formBuilder.array([new UntypedFormControl(null)]);
  registerOnChangeCallback: any;

  constructor(
    public textService: TextService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.array.valueChanges.subscribe((items) => {
      if (this.registerOnChangeCallback) {
        this.registerOnChangeCallback(items);
      }
    });
  }
  validate(control: AbstractControl): ValidationErrors | null {
    if (this.array.valid) {
      return null;
    }
    return { itemsArray: "not-valid" };
  }

  registerOnChange(fn: any): void {
    this.registerOnChangeCallback = fn;
  }

  removeItem(index: number) {
    this.array.removeAt(index);
    if (this.array.length <= 2) {
      this.array_error_message = "";
    }
  }

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.array.disable();
    }
    else {
      this.array.enable();
    }
  }

  addItem() {
    if (this.array.length < 2) {
      this.array.push(new UntypedFormControl(null));
    } else {
      this.array_error_message = "אין אפשרות להכניס יותר מ2";
    }
  }

  writeValue(items: IPensionBeforeRetirementItem[]): void {
    if (!items) {
      return;
    }

    this.array.clear();
    for (let index = 0; index < items.length; index++) {
      this.addItem();
    }
    this.array.patchValue(items, {
      emitEvent: false,
    });
  }

  registerOnTouched(fn: any): void { }
}
