import { Component, Input, OnInit } from "@angular/core";
import { FormArray, UntypedFormGroup } from "@angular/forms";
import { TextService } from "src/app/services/text.service";
import { WorkTerminationDataEnum } from "src/app/models/work-termination.model";
import { InputComponent } from "../../../common/input/input.component";
declare var $: any;

@Component({
    selector: "app-maximum-amounts-retirement",
    templateUrl: "./maximum-amounts-retirement.component.html",
    styleUrls: ["./maximum-amounts-retirement.component.scss"],
    standalone: true,
    imports: [InputComponent]
})
export class MaximumAmountsRetirement implements OnInit {
  @Input() workTerminationFormGroup: UntypedFormGroup;
  workTerminationDataEnum = WorkTerminationDataEnum;
  textSource;

  constructor(public textService: TextService) {}

  ngOnInit(): void {
    if (!this.textService.textSource) {
      this.textService.setTextSource();
    }
    this.textSource = this.textService.textSource;
  }
}
