import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LoginService } from "./login.service";
import { tap } from "rxjs/operators";
import { DataService } from "./data.service";
import { Router } from "@angular/router";


@Injectable({
  providedIn: "root",
})
export class BusinessesForUserService {
  private subBusinesses = new BehaviorSubject([]);
  businesses$ = this.subBusinesses.asObservable();
  private subSelectedBusinessID = new BehaviorSubject(0);
  selectedBusinessID$ = this.subSelectedBusinessID.asObservable();
  needToShowAllSubect = new BehaviorSubject<boolean>(true)
  needToShowAll$ = this.needToShowAllSubect.asObservable();

  constructor(private readonly loginService: LoginService, public dataService: DataService, private router: Router) {
    if (sessionStorage.getItem("businesses")) {
      this.subBusinesses.next(JSON.parse(sessionStorage.getItem("businesses")));
    }
    if (sessionStorage.getItem("subSelectedBusinessID")) {
      this.subSelectedBusinessID.next(JSON.parse(sessionStorage.getItem("subSelectedBusinessID")));
    }
  }
  changeBusiness(id, reloadPage = false) {
    sessionStorage.setItem("subSelectedBusinessID", id);
    this.subSelectedBusinessID.next(+id);
    const currentURL = window.location.href;
    const expectedURL = `/employee-card/`;
    this.dataService.showDataLoader = true;
    return this.loginService.chooseBusiness(this.subBusinesses.value[this.subSelectedBusinessID.value].businessID)
      .pipe(tap(() => {
        this.dataService.showDataLoader = false;
        if (currentURL.indexOf(expectedURL) !== -1) {
          this.router.navigate(['/my-employees']).then(x => { if (reloadPage) { this.reload(); } });
        }
        else if (reloadPage) { this.reload(); }
      }));
  }
  async reload() {
    this.needToShowAllSubect.next(false);
    this.dataService.showDataLoader = true;
    await this.delay(100);
    this.needToShowAllSubect.next(true);
    this.dataService.showDataLoader = false;
  }
  delay(mili: number) {
    return new Promise((res, rej) => {
      setTimeout(() => {
        res(null);
      }, (mili));

    });
  }
  loadBusinesses(businesses) {
    this.subBusinesses.next(businesses);
  }
}
