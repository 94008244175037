export enum PopupTypes {
  // updatePersonalDetails,
  // updatePersonalDetailsSuccess,
  // newRequest,
  // openingTimes,
  loggedOut,
  approvedRequest,
  changeBranch,
  newAppointment,
  isExistsProcedure,
  error,
  newAttachment,
  monthlyOrHourly,
}

export class BasePopup {
  PopupTypes = PopupTypes;
}
