// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
table tr:not(:last-child) {
  border-bottom: 1px solid #3cb79c;
}
table tr:not(:first-child) {
  cursor: default;
}
table td:last-child img {
  vertical-align: middle;
}
table td,
table th {
  padding: 0.5rem 0.5rem;
  white-space: nowrap;
}
table th {
  text-align: start;
  font-family: var(--main-font-medium);
}
table .green-bg {
  background: #cbe3de;
}
table .sticky-col {
  position: sticky;
  background: #ffffff;
  mix-blend-mode: normal;
  right: 0;
  opacity: 0.95;
  min-width: 70px;
  text-align: center;
  display: block;
  box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
}
table .sticky-col:nth-child(2n) {
  background: #cbe3de;
}
.wrapper {
  overflow-x: auto;
  border-radius: 8px;
  border: 1.5px solid #3cb79c;
  font-family: var(--main-font-light);
  font-size: 1.2rem;
}

.ghost {
  height: 165px;
  width: 100%;
  margin-top: 16px;
  display: flex;
}

@media screen and (min-width: 600px) {
  .desktop td,
  .desktop th {
    padding: 0.75rem 1rem;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/components/tables/new-employees-welcome-table/new-employees-welcome-table.component.scss"],"names":[],"mappings":"AAEA;EACE,WAAA;EACA,yBAAA;EACA,iBAAA;AADF;AAGE;EACE,gCAAA;AADJ;AAIE;EACE,eAAA;AAFJ;AAMI;EACE,sBAAA;AAJN;AAQE;;EAEE,sBAAA;EACA,mBAAA;AANJ;AASE;EACE,iBAAA;EACA,oCAAA;AAPJ;AAUE;EACE,mBAAA;AARJ;AAWE;EACE,gBAAA;EACA,mBAAA;EACA,sBAAA;EACA,QAAA;EACA,aAAA;EACA,eAAA;EACA,kBAAA;EAIA,cAAA;EACA,iDAAA;AAZJ;AAQI;EACE,mBAAA;AANN;AAeA;EACE,gBAAA;EACA,kBAAA;EACA,2BAAA;EACA,mCAAA;EACA,iBAAA;AAbF;;AAgBA;EACE,aAAA;EACA,WAAA;EACA,gBAAA;EACA,aAAA;AAbF;;AAeA;EACE;;IAEE,qBAAA;EAZF;AACF","sourcesContent":["@import \"../../../varibles.scss\";\n\ntable {\n  width: 100%;\n  border-collapse: collapse;\n  border-spacing: 0;\n\n  tr:not(:last-child) {\n    border-bottom: 1px solid #3cb79c;\n  }\n\n  tr:not(:first-child) {\n    cursor: default;\n  }\n\n  td:last-child {\n    img {\n      vertical-align: middle;\n    }\n  }\n\n  td,\n  th {\n    padding: 0.5rem 0.5rem;\n    white-space: nowrap;\n  }\n\n  th {\n    text-align: start;\n    font-family: var(--main-font-medium);\n  }\n\n  .green-bg {\n    background: #cbe3de;\n  }\n\n  .sticky-col {\n    position: sticky;\n    background: #ffffff;\n    mix-blend-mode: normal;\n    right: 0;\n    opacity: 0.95;\n    min-width: 70px;\n    text-align: center;\n    &:nth-child(2n) {\n      background: #cbe3de;\n    }\n    display: block;\n    box-shadow: 0px 10px 20px 2px rgba(0, 0, 0, 0.25);\n  }\n  .first-col {\n  }\n}\n\n.wrapper {\n  overflow-x: auto;\n  border-radius: 8px;\n  border: 1.5px solid #3cb79c;\n  font-family: var(--main-font-light);\n  font-size: 1.2rem;\n}\n\n.ghost {\n  height: 165px;\n  width: 100%;\n  margin-top: 16px;\n  display: flex;\n}\n@media screen and (min-width: 600px) {.desktop {\n  td,\n  th {\n    padding: 0.75rem 1rem;\n  }\n}\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
