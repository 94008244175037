import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { ActivatedRoute, Router } from "@angular/router";
import { TextService } from "../../../services/text.service";
import { NewAppointmentService } from "src/app/services/new-appointment.service";
import { NgIf } from "@angular/common";
@Component({
    selector: "app-thank-you",
    templateUrl: "./thank-you.component.html",
    styleUrls: ["./thank-you.component.scss"],
    standalone: true,
    imports: [NgIf]
})
export class ThankYouComponent implements OnInit {
  SR = "";
  SR_text = "";
  constructor(
    public dataService: DataService,
    private route: ActivatedRoute,
    private router: Router,
    public textService: TextService,
    private newAppointmentService: NewAppointmentService
  ) {}

  ngOnInit(): void {
    this.textService.setTextSource();
    if (this.newAppointmentService.SR_response["name"] != "") {
      this.SR_text = this.dataService.getResourceText(
        this.textService.textSource["FINISH_NEW_MEETING_SCREEN_SUBTITLE"],
        this.newAppointmentService.SR_response
      );
    }
  }

  goToHomePage() {
    this.dataService.showApprovedRequestPopup = false;
    this.router.navigateByUrl("/");
  }
}
