import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { AuthService } from "../services/auth.service";

@Injectable()
export class CanActivateNotLoggedUser  {
  constructor(private router: Router, private authService: AuthService) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    return this.canActivateGuard();
  }

  canActivateGuard(): Promise<boolean> {
    return new Promise((resolve) => {
      this.authService.validateToken().then((valid) => {
        if (valid) {
          this.router.navigateByUrl("/");
          resolve(false);
        } else {
          resolve(true);
        }
      });
    });
  }
}
