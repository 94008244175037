import { Component, OnInit } from "@angular/core";
import { Observable } from "rxjs";
import {
  FileImage,
  FileUploadService,
} from "src/app/services/file-upload.service";
import { TextService } from "src/app/services/text.service";
import { ButtonComponent } from "../../common/button/button.component";
import { NgSwitch, NgClass, NgSwitchCase, AsyncPipe } from "@angular/common";
import { DndDirective } from "../../../directives/dnd.directive";
import { ModalComponent } from "../../common/modal/modal.component";

@Component({
    selector: "app-upload-file",
    templateUrl: "./upload-file.component.html",
    styleUrls: ["./upload-file.component.scss"],
    standalone: true,
    imports: [ModalComponent, DndDirective, NgSwitch, NgClass, NgSwitchCase, ButtonComponent, AsyncPipe]
})
export class UploadFileComponent implements OnInit {
  file: File;
  fileImage$: Observable<FileImage>;
  fileImage = FileImage;
  templateUrl$: Observable<string>;

  constructor(
    private fileUploadService: FileUploadService,
    public textService: TextService
  ) {}

  ngOnInit(): void {
    this.fileImage$ = this.fileUploadService.getFileImage();
    this.fileUploadService.getFile().subscribe((file) => {
      this.file = file;
    });
    this.fileUploadService.getTemplateUrl();
    this.templateUrl$ = this.fileUploadService.getTemplateUrlObs();
  }

  onFileDropped($event: File) {
    this.fileUploadService.setFile($event);
  }

  incomingFile($event: Event) {
    const target = $event.target as HTMLInputElement;
    if (target.files && target.files.length > 0) {
      const file = target.files[0];
      this.fileUploadService.setFile(file);
    }
  }

  sendFile() {
    this.fileUploadService.sendFile(this.file);
  }
}
