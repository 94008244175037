import { Component, OnInit } from "@angular/core";
import { TextService } from "src/app/services/text.service";
import { DataService } from "src/app/services/data.service";
import { FeedbackService } from "src/app/services/feedback.service";
import {
  FeedbackStatsModel,
} from "src/app/models/feedback.stats.model";
declare var $: any;
import * as moment from "moment";
import { Router } from "@angular/router";
import { PieChartComponent } from "../../common/pie-chart/pie-chart.component";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { NgIf, NgFor } from "@angular/common";

@Component({
    selector: "app-employers-interface-section-home-page",
    templateUrl: "./employers-interface-section-home-page.component.html",
    styleUrls: ["./employers-interface-section-home-page.component.scss"],
    standalone: true,
    imports: [NgIf, MatLegacyFormFieldModule, MatLegacySelectModule, ReactiveFormsModule, FormsModule, NgFor, MatLegacyOptionModule, PieChartComponent]
})
export class EmployersInterfaceSectionHomePageComponent implements OnInit {
  feedbackStatsData: FeedbackStatsModel;
  pieFeedbackData: any;
  optionsForYear = [];
  optionsForMonth = [];
  selectedYear;
  selectedMonth;
  absorption_rate = "";
  total_deposite = "";
  datePicker: any;
  showGhostElementsOnPie = false;
  check = moment(new Date(), "YYYY/MM/DD");
  month = (Number(this.check.format("M")) - 3).toString();
  constructor(
    public textService: TextService,
    public dataService: DataService,
    router: Router,
    private feedbackService: FeedbackService
  ) {
    this.textService.setTextSource();
//  router.routeReuseStrategy.shouldReuseRoute = ()=>{

//  };
// .
  }

  ngOnInit(): void {
    var year = this.check.format("YYYY");
    this.showGhostElementsOnPie = true;
    this.dataService.showGhostElements = true;
    this.feedbackService
      .getFeedbackStatsByPolicy({
        /*month:10,year:2019*/
      })
      .subscribe((res) => {
        // this.dataService.showGhostElements = false;
        this.showGhostElementsOnPie = false;
        this.feedbackStatsData = res;
        this.absorption_rate = this.dataService.getResourceText(
          this.textService.textSource["HOME_SCREEN_SECTION_BOTTOM_LABEL_2"],
          this.feedbackStatsData
        );
        this.total_deposite = this.dataService.getResourceText(
          this.textService.textSource["HOME_SCREEN_SECTION_BOTTOM_LABEL_1"],
          this.feedbackStatsData
        );
        this.optionsForYear = this.feedbackStatsData["year_filter"];

        this.optionsForYear.filter((yearOption) => {
          if (
            yearOption["value"] === year ||
            yearOption["value"] === moment().year(2021).year().toString()
          ) {
            this.selectedYear = yearOption;
          }
        });

        this.optionsForMonth = this.selectedYear["month_filter"];

        this.optionsForMonth.filter((monthOption) => {
          if (
            monthOption["value"] === this.month ||
            monthOption["value"] === "10"
          ) {
            this.selectedMonth = monthOption;
          }
        });
        console.log(res);

        // this.dataService.showGhostElements = false;
        setTimeout(() => {
          this.dataService.dataArrived.next();
          this.feedbackService.createChart.next();
        }, 0);
      });
  }

  getStats(query) {
    this.showGhostElementsOnPie = true;
    this.feedbackService.getFeedbackStatsByPolicy(query).subscribe((res) => {
      this.showGhostElementsOnPie = false;
      this.feedbackStatsData = res;
      this.absorption_rate = this.dataService.getResourceText(
        this.textService.textSource["HOME_SCREEN_SECTION_BOTTOM_LABEL_2"],
        this.feedbackStatsData
      );
      this.total_deposite = this.dataService.getResourceText(
        this.textService.textSource["HOME_SCREEN_SECTION_BOTTOM_LABEL_1"],
        this.feedbackStatsData
      );
      console.log(res);

      setTimeout(() => {
        this.dataService.dataArrived.next();
        this.feedbackService.createChart.next();
      }, 0);
    });
  }

  datesChanged() {
    this.optionsForMonth = this.selectedYear["month_filter"];

    let isMonthExist = this.selectedYear["month_filter"].find((month) => {
      if (month.value == this.selectedMonth["value"]) {
        this.selectedMonth = month;
        return true;
      }
    });

    if (!isMonthExist) this.selectedMonth = this.optionsForMonth[0];
    let data = {
      year: this.selectedYear["value"],
      month: this.selectedMonth["value"],
    };

    this.getStats(data);
  }
}
