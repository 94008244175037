// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button__group {
  position: relative;
  direction: rtl;
}
.button__group .button__group-dropdown {
  position: absolute;
  background: #fff;
  border: 1px solid rgba(143, 143, 143, 0.18);
  border-top: none;
  z-index: 3;
}
.button__group .button__group-dropdown .button__group-dropdown-button {
  font-family: var(--main-font-regular);
  background: transparent;
  border: transparent;
  width: 100%;
  font-size: 25px;
  text-align: start;
  padding: 0.75rem;
  position: relative;
  cursor: pointer;
}
.button__group .button__group-dropdown .button__group-dropdown-button:not(:last-child):after {
  content: "";
  height: 1px;
  width: 92%;
  background: #8f8f8f;
  position: absolute;
  bottom: 0;
  right: 0;
  left: 0;
  margin: 0 auto;
  opacity: 0.18;
}`, "",{"version":3,"sources":["webpack://./src/app/components/common/button-dropdown/button-dropdown.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EACA,cAAA;AACF;AACE;EACE,kBAAA;EACA,gBAAA;EACA,2CAAA;EACA,gBAAA;EACA,UAAA;AACJ;AACI;EACE,qCAAA;EACA,uBAAA;EACA,mBAAA;EACA,WAAA;EACA,eAAA;EACA,iBAAA;EACA,gBAAA;EACA,kBAAA;EACA,eAAA;AACN;AACM;EACE,WAAA;EACA,WAAA;EACA,UAAA;EACA,mBAAA;EACA,kBAAA;EACA,SAAA;EACA,QAAA;EACA,OAAA;EACA,cAAA;EACA,aAAA;AACR","sourcesContent":[".button__group {\n  position: relative;\n  direction: rtl;\n\n  .button__group-dropdown {\n    position: absolute;\n    background: #fff;\n    border: 1px solid rgba(143, 143, 143, 0.18);\n    border-top: none;\n    z-index: 3;\n\n    .button__group-dropdown-button {\n      font-family: var(--main-font-regular);\n      background: transparent;\n      border: transparent;\n      width: 100%;\n      font-size: 25px;\n      text-align: start;\n      padding: 0.75rem;\n      position: relative;\n      cursor: pointer;\n\n      &:not(:last-child):after {\n        content: \"\";\n        height: 1px;\n        width: 92%;\n        background: #8f8f8f;\n        position: absolute;\n        bottom: 0;\n        right: 0;\n        left: 0;\n        margin: 0 auto;\n        opacity: 0.18;\n      }\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
