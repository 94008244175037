import { enableProdMode, LOCALE_ID, importProvidersFrom } from "@angular/core";
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic";
import { environment } from "./environments/environment";
import { AppComponent } from "./app/app.component";
import { MatIconModule } from "@angular/material/icon";
import { MatLegacyTableModule as MatTableModule } from "@angular/material/legacy-table";
import { MatLegacyAutocompleteModule as MatAutocompleteModule } from "@angular/material/legacy-autocomplete";
import { LottieModule } from "ngx-lottie";
import { ScrollingModule } from "@angular/cdk/scrolling";
import { MatLegacyButtonModule as MatButtonModule } from "@angular/material/legacy-button";
import { MatLegacyDialogModule as MatDialogModule } from "@angular/material/legacy-dialog";
import { MatLegacyRadioModule as MatRadioModule } from "@angular/material/legacy-radio";
import { MatLegacySelectModule as MatSelectModule } from "@angular/material/legacy-select";
import { MatLegacyInputModule as MatInputModule } from "@angular/material/legacy-input";
import { MatLegacyFormFieldModule as MatFormFieldModule } from "@angular/material/legacy-form-field";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatSidenavModule } from "@angular/material/sidenav";
import { provideAnimations } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "./app/app-routing.module";
import { BrowserModule, bootstrapApplication } from "@angular/platform-browser";
import { withInterceptorsFromDi, provideHttpClient, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { DateFormat } from "./app/services/date-format.service";
import { DateAdapter } from "@angular/material/core";
import { getHebrewPaginatorIntl } from "./app/hebrew-paginator-intl";
import { MatLegacyPaginatorIntl as MatPaginatorIntl, MatLegacyPaginatorModule as MatPaginatorModule } from "@angular/material/legacy-paginator";
import { SearchService } from "./app/services/search.service";
import { TextService } from "./app/services/text.service";
import { AuthService } from "./app/services/auth.service";
import { CanActivateNotLoggedUser } from "./app/shared/CanActivateNotLoggedUser";
import { CanActivateLoggedUser } from "./app/shared/CanActivateLoggedUser";
import player from "lottie-web";
// import { RecaptchaInterceptor } from "./app/interceptors/recaptcha.interceptor";
// import { RECAPTCHA_SETTINGS, RECAPTCHA_V3_SITE_KEY, ReCaptchaV3Service, RecaptchaSettings } from "ng-recaptcha";
import { registerLocaleData } from "@angular/common";
import localeHE from "@angular/common/locales/he";

registerLocaleData(localeHE);

export function playerFactory() {
  return player;
}

if (environment.production) {
  enableProdMode();
}

bootstrapApplication(AppComponent, {
  providers: [
    // ReCaptchaV3Service,
    // {
    //   provide: RECAPTCHA_V3_SITE_KEY, useValue: '6LeWWN0oAAAAAHUtmga89Iqavyfud9KWlqBnU1YD'
    // },
    // {
      // 6LeWWN0oAAAAAHUtmga89Iqavyfud9KWlqBnU1YD   old client
    //  "6LeWWN0oAAAAAL4TzIH12i5Zidkv_yGLEybq7gOp" old server
      // '6Le1hhEpAAAAABUwDAN62a7FWgc7Hg8D7qK_PVAS'  new client
      // '6Le1hhEpAAAAAGMg9joeJ5OurkdaCCjWO5L52ZWq' new serever
    //   provide: RECAPTCHA_SETTINGS,
    //   useValue: {
    //     siteKey: '6LeWWN0oAAAAAHUtmga89Iqavyfud9KWlqBnU1YD'
    //   } as RecaptchaSettings
    // },
    // {
    //   provide: HTTP_INTERCEPTORS,
    //   useClass: RecaptchaInterceptor,
    //   multi: true,
    // },
    importProvidersFrom(MatPaginatorModule, MatDatepickerModule, BrowserModule, AppRoutingModule, MatSidenavModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatInputModule, MatSelectModule, MatRadioModule, MatDialogModule, MatButtonModule,
      // SatNativeDateModule,
      // SatDatepickerModule,
      ScrollingModule, LottieModule.forRoot({ player: playerFactory }), MatAutocompleteModule, MatTableModule, MatIconModule),
    CanActivateLoggedUser,
    CanActivateNotLoggedUser,
    AuthService,
    TextService,
    SearchService,
    { provide: MatPaginatorIntl, useValue: getHebrewPaginatorIntl() },
    {
      provide: LOCALE_ID,
      useValue: "he-IL",
    },
    {
      provide: DateAdapter,
      useClass: DateFormat,
    },
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations()
  ]
})
  .catch((err) => console.error(err));

