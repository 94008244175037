import { Component, OnInit } from "@angular/core";
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ControlValueAccessor, Validator, UntypedFormControl, Validators, UntypedFormBuilder, AbstractControl, ValidationErrors, ReactiveFormsModule } from "@angular/forms";
import * as moment from "moment";
import {
  WorkTerminationDataEnum,
  IPensionBeforeRetirementItem,
} from "src/app/models/work-termination.model";
import { TextService } from "src/app/services/text.service";
import { InputComponent } from "../../../common/input/input.component";

@Component({
    selector: "app-pension-before-retirement-item",
    templateUrl: "./pension-before-retirement-item.component.html",
    styleUrls: ["./pension-before-retirement-item.component.scss"],
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: PensionBeforeRetirementItemComponent,
            multi: true,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: PensionBeforeRetirementItemComponent,
            multi: true,
        },
    ],
    standalone: true,
    imports: [ReactiveFormsModule, InputComponent]
})
export class PensionBeforeRetirementItemComponent
  implements OnInit, ControlValueAccessor, Validator
{
  workTerminationDataEnum = WorkTerminationDataEnum;

  item = this.fb.group({
    [this.workTerminationDataEnum.PAYER_NAME60]: new UntypedFormControl(""),
    [this.workTerminationDataEnum.DEDUCTIONS_PORTFOLIO60]: new UntypedFormControl(""),
    [this.workTerminationDataEnum.PAYER_ID]: new UntypedFormControl(
      "",
      Validators.pattern(/^[0-9]+$/i)
    ),
    [this.workTerminationDataEnum.CONVERSION_MONTH]: new UntypedFormControl(""),
    [this.workTerminationDataEnum.PENSION_AMOUNT]: new UntypedFormControl(
      "",
      Validators.pattern(/^[0-9.]+$/i)
    ),
    [this.workTerminationDataEnum.TOTAL_AMOUNT]: new UntypedFormControl(
      "",
      Validators.pattern(/^[0-9.]+$/i)
    ),
  });

  callBack?: any;

  constructor(
    public textService: TextService,
    private readonly fb: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.item.valueChanges.subscribe((o) => {
      if (this.callBack) {
        this.callBack(o);
      }
    });
  }

  validateDate(control: UntypedFormControl) {
    if (control.value) {
      const date = moment(control.value);
      const isValid = date.isValid();
      if (!isValid) {
        return { date: true };
      }
    }
    return null;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    if (this.item.valid) {
      return null;
    }
    return { item: "not-valid" };
  }

  writeValue(obj: IPensionBeforeRetirementItem): void {
    if (!obj) {
      return;
    }
    this.item.patchValue(obj, { emitEvent: false });
  }

  registerOnChange(fn: any): void {
    this.callBack = fn;
  }

  registerOnTouched(fn: any): void {}

  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.item.disable();
    } else {
      this.item.enable();
    }
  }
}
