// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal__title {
  display: flex;
  gap: 1rem;
  font-family: var(--main-font-light);
}
.modal__title a,
.modal__title h3 {
  font-size: 30px;
}
.modal__title a {
  color: var(--main_button_color);
  display: flex;
  text-decoration: none;
  align-items: center;
  gap: 0.5rem;
}
.modal__title a:visited {
  color: var(--main_button_color);
}
.modal__title a img {
  display: inline-block;
  width: 20px;
}

.modal__input {
  width: 80%;
  height: 200px;
  border: 1px gray dashed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: var(--main-font-light);
  opacity: 0.75;
}
.modal__input h3,
.modal__input label {
  font-size: 25px;
  font-family: var(--main-font-light);
}
.modal__input label {
  cursor: pointer;
  text-decoration: underline;
  text-underline-offset: 4px;
  text-decoration-thickness: 1px;
}
.modal__input img {
  display: inline-block;
  width: 75px;
  height: 75px;
}
.modal__input img.error__img {
  width: 60px;
  height: 60px;
  margin-bottom: 1rem;
}

.modal__buttons {
  padding-top: 3rem;
}

.fileover {
  background: var(--main_button_color);
  transition: all ease-in-out 0.3s;
}`, "",{"version":3,"sources":["webpack://./src/app/components/modals/upload-file/upload-file.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,SAAA;EACA,mCAAA;AACF;AACE;;EAEE,eAAA;AACJ;AAEE;EACE,+BAAA;EACA,aAAA;EACA,qBAAA;EACA,mBAAA;EACA,WAAA;AAAJ;AAEI;EACE,+BAAA;AAAN;AAGI;EACE,qBAAA;EACA,WAAA;AADN;;AAMA;EACE,UAAA;EACA,aAAA;EACA,uBAAA;EACA,aAAA;EACA,sBAAA;EACA,uBAAA;EACA,mBAAA;EACA,mCAAA;EACA,aAAA;AAHF;AAKE;;EAEE,eAAA;EACA,mCAAA;AAHJ;AAME;EACE,eAAA;EACA,0BAAA;EACA,0BAAA;EACA,8BAAA;AAJJ;AAOE;EACE,qBAAA;EACA,WAAA;EACA,YAAA;AALJ;AAQE;EACE,WAAA;EACA,YAAA;EACA,mBAAA;AANJ;;AAUA;EACE,iBAAA;AAPF;;AAUA;EACE,oCAAA;EACA,gCAAA;AAPF","sourcesContent":[".modal__title {\n  display: flex;\n  gap: 1rem;\n  font-family: var(--main-font-light);\n\n  a,\n  h3 {\n    font-size: 30px;\n  }\n\n  a {\n    color: var(--main_button_color);\n    display: flex;\n    text-decoration: none;\n    align-items: center;\n    gap: 0.5rem;\n\n    &:visited {\n      color: var(--main_button_color);\n    }\n\n    img {\n      display: inline-block;\n      width: 20px;\n    }\n  }\n}\n\n.modal__input {\n  width: 80%;\n  height: 200px;\n  border: 1px gray dashed;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  font-family: var(--main-font-light);\n  opacity: 0.75;\n\n  h3,\n  label {\n    font-size: 25px;\n    font-family: var(--main-font-light);\n  }\n\n  label {\n    cursor: pointer;\n    text-decoration: underline;\n    text-underline-offset: 4px;\n    text-decoration-thickness: 1px;\n  }\n\n  img {\n    display: inline-block;\n    width: 75px;\n    height: 75px;\n  }\n\n  img.error__img {\n    width: 60px;\n    height: 60px;\n    margin-bottom: 1rem;\n  }\n}\n\n.modal__buttons {\n  padding-top: 3rem;\n}\n\n.fileover {\n  background: var(--main_button_color);\n  transition: all ease-in-out 0.3s;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
