import { Component, OnInit, Input } from "@angular/core";
import { Router } from "@angular/router";
import { DataService } from "src/app/services/data.service";
import { TextService } from "../../../services/text.service";
import { NgIf, NgFor } from "@angular/common";
@Component({
    selector: "app-appointments-component",
    templateUrl: "./appointments-component.component.html",
    styleUrls: ["./appointments-component.component.scss"],
    standalone: true,
    imports: [NgIf, NgFor]
})
export class AppointmentsComponentComponent implements OnInit {
  name;
  @Input() data;
  @Input() meetingTypes = [
    { name: "פגישה פרונטלית", past_meeting: "30", future_meeting: "25" },
    { name: "פגישה דיגיטאלית", past_meeting: "30", future_meeting: "25" },
    { name: "פגישה טלפונית", past_meeting: "30", future_meeting: "25" },
  ];
  meetingTypesArrayLength;
  type_title;
  type_subtitle;
  constructor(
    public dataService: DataService,
    public router: Router,
    public textService: TextService
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    if (this.data != "") {
      this.meetingTypesArrayLength = this.data["methods"].length - 1;
      this.name = this.dataService.name;

      this.type_title = this.dataService.getResourceText(
        this.textService.textSource["COUNT_ALL_MEETINGS_SCREEN_ITEM_TITLE"],
        this.data
      );
      this.type_subtitle = this.dataService.getResourceText(
        this.textService.textSource["COUNT_ALL_MEETINGS_SCREEN_ITEM_SUBTITLE"],
        this.data
      );

      this.data["methods"].forEach((element) => {
        element["method"] = this.dataService.getResourceText(
          this.textService.textSource["COUNT_ALL_MEETINGS_SCREEN_ITEM_LABEL_1"],
          element
        );
        element["held_count"] = this.dataService.getResourceText(
          this.textService.textSource["COUNT_ALL_MEETINGS_SCREEN_ITEM_LABEL_2"],
          element
        );
        element["scheduled_count"] = this.dataService.getResourceText(
          this.textService.textSource["COUNT_ALL_MEETINGS_SCREEN_ITEM_LABEL_3"],
          element
        );
      });
    }
  }

  goToTypeAppointment() {
    this.router.navigate(["appointment-type", this.data["type"]]);
  }
}
