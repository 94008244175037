import { Component, OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, UntypedFormBuilder, UntypedFormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, ReactiveFormsModule } from '@angular/forms';
import { IPartTimeSalaryReductionPeriod, WorkTerminationDataEnum } from 'src/app/models/work-termination.model';
import { TextService } from 'src/app/services/text.service';
import { WorkTerminationPeriodItemComponentComponent } from '../work-termination-period-item-component/work-termination-period-item-component.component';
import { NgFor, NgClass, NgIf } from '@angular/common';

@Component({
    selector: 'app-work-termination-period-array-component',
    templateUrl: './work-termination-period-array-component.component.html',
    styleUrls: ['./work-termination-period-array-component.component.scss'],
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: WorkTerminationPeriodArrayComponentComponent,
            multi: true
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: WorkTerminationPeriodArrayComponentComponent,
            multi: true
        }
    ],
    standalone: true,
    imports: [NgFor, WorkTerminationPeriodItemComponentComponent, ReactiveFormsModule, NgClass, NgIf]
})
export class WorkTerminationPeriodArrayComponentComponent implements OnInit,  ControlValueAccessor, Validator {

  part_time_salary_reduction_periods_error_message = "";
  workTerminationDataEnum = WorkTerminationDataEnum;
  partTimeSalaryReductionPeriods = this.formBuilder.array([
    new UntypedFormControl(null)
  ]);
  registerOnChangeCallback: any;

  constructor(public textService: TextService,
    private formBuilder: UntypedFormBuilder) {

  }

  ngOnInit(): void {
    this.partTimeSalaryReductionPeriods.valueChanges.subscribe(products => {
      if (this.registerOnChangeCallback) {
        this.registerOnChangeCallback(products);
      }
    });
  }
  validate(control: AbstractControl): ValidationErrors | null {
    if (this.partTimeSalaryReductionPeriods.valid) {
      return null;
    }
    return { 'productsArray': 'not-valid' };
  };


  registerOnChange(fn: any): void {
    this.registerOnChangeCallback = fn;
  }

  removePartTimeSalaryReductionPeriod(index: number) {
    this.partTimeSalaryReductionPeriods.removeAt(index);
    if (this.partTimeSalaryReductionPeriods.length <= 6) {
      this.part_time_salary_reduction_periods_error_message =
      "";
      }
  }

  addPartTimeSalaryReductionPeriod() {
    if (this.partTimeSalaryReductionPeriods.length < 6) {
      this.partTimeSalaryReductionPeriods.push(new UntypedFormControl(null))
    } else {
      this.part_time_salary_reduction_periods_error_message =
        "אין אפשרות להכניס יותר מ6 תקופות";
    }
  }


  writeValue(periods: IPartTimeSalaryReductionPeriod[]): void {
    if (!periods) {
      return;
    }

    this.partTimeSalaryReductionPeriods.clear();
    for (let index = 0; index < periods.length; index++) {
      this.addPartTimeSalaryReductionPeriod();
    }
    this.partTimeSalaryReductionPeriods.patchValue(periods, { emitEvent: false });
  }

  registerOnTouched(fn: any): void {
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.partTimeSalaryReductionPeriods.disable();
    }
    else {
      this.partTimeSalaryReductionPeriods.enable();
    }
  }
}
