import * as moment from "moment";

export interface IRegisterUserData {
  recordId: string;
  id_number_type: string | IncorrectField;
  id_number: string | IncorrectField;
  first_name: string | IncorrectField;
  last_name: string | IncorrectField;
  date_of_birth: string | IncorrectField;
  gender: string | IncorrectField;
  family_status: string | IncorrectField;
  city: string | IncorrectField;
  street: string | IncorrectField;
  bulding_number: string | IncorrectField;
  email: string | IncorrectField;
  mobile_phone: string | IncorrectField;
  employer_number: string | IncorrectField;
  role: string | IncorrectField;
  start_work_date: string | IncorrectField;
  salary_type: string | IncorrectField;
  salary: string | IncorrectField;
  monthly_pension_salary: string | IncorrectField;
  allowance_compensations_percentage: string | IncorrectField;
  employer_benefits: string | IncorrectField;
  employee_benefits: string | IncorrectField;
  paragraph_14: string | IncorrectField;
  employee_incapacity_to_work: string | IncorrectField;
  eligibility_study_fund: string | IncorrectField;
  eligibility_date_study_fund: string | IncorrectField;
  eligibility_date: string | IncorrectField;
  study_fund_deposit: string | IncorrectField;
  study_fund_employer_deposit: string | IncorrectField;
  study_fund_employee_deposit: string | IncorrectField;
  eligible_health_insurance: string | IncorrectField;
  foreign_employee: string | IncorrectField;
  language: string | IncorrectField;
}

type IncorrectField = {
  value: string;
  msg: string;
};

export interface IEmployeeData {
  personal_details: Partial<IPersonalDetails>;
  work_details: IWorkDetails;
  other: IOther;
  social_allowances: ISocialAllowance;
  fund_study: IFundStudy;
}

export interface IPersonalDetails {
  first_name: string;
  last_name: string;
  id_number: string;
  id_number_type: string;
  mobile_phone: string;
  email: string;
  gender: string;
  family_status: string;
  street: string;
  city: string;
  bulding_number: string;
  apartment_number: string;
  postal_code: string;
  date_of_birth: string;
  foreign_employee: boolean;
  language: string;
  personal_email: string;
}

interface IWorkDetails {
  role: string;
  employer_number: number;
  start_work_date: string;
  monthly_pension_salary: string;
}

interface IOther {
  employee_incapacity_to_work: string;
  employer_incapacity_to_work: string;
  health_collective: string;
  eligible_health_insurance: boolean;
}

interface ISocialAllowance {
  eligibility_date: string;
  employer_benefits: string;
  employee_benefits: string;
  allowance_compensations_percentage: string;
  paragraph_14: string;
  pension_date_of_entitlement: string;
}

interface IFundStudy {
  eligibility_date_study_fund: string;
  study_fund_employer_deposit: string;
  study_fund_employee_deposit: string;
  study_fund_deposit: string;
  study_fund_date_of_entitlement: string;
  other_study_fund_deposit?: string;
  percentage_study_fund_deposit?: string;
  eligibility_study_fund: boolean;
}

export interface IFieldOptions {
  label: string;
  value: string;
  default_value: boolean;
  sfValue?: string;
}

export enum RegisterUserDataEnum {
  ID_NUMBER_TYPE = "id_number_type",
  ID_NUMBER = "id_number",
  FIRST_NAME = "first_name",
  LAST_NAME = "last_name",
  DATE_OF_BIRTH = "date_of_birth",
  GENDER = "gender",
  FAMILY_STATUS = "family_status",
  CITY = "city",
  STREET = "street",
  BUILDING_NUMBER = "bulding_number",
  EMAIL = "email",
  MOBILE_PHONE = "mobile_phone",
  EMPLOYEE_NUMBER = "employer_number",
  ROLE = "role",
  START_WORK_DATE = "start_work_date",
  SALARY_TYPE = "salary_type",
  SALARY = "salary",
  MONTHLY_PENSION_SALARY = "monthly_pension_salary",
  ALLOWANCE_COMPENSATIONS_PERCENTAGE = "allowance_compensations_percentage",
  EMPLOYER_BENEFITS = "employer_benefits",
  EMPLOYEE_BENEFITS = "employee_benefits",
  PARAGRAPH_14 = "paragraph_14",
  EMPLOYEE_INCAPACITY_TO_WORK = "employee_incapacity_to_work",
  ELIGIBILITY_STUDY_FUND = "eligibility_study_fund",
  ELIGIBILITY_DATE_STUDY_FUND = "eligibility_date_study_fund",
  ELIGIBILITY_DATE = "eligibility_date",
  STUDY_FUND_DEPOSIT = "study_fund_deposit",
  STUDY_FUND_EMPLOYER_DEPOSIT = "study_fund_employer_deposit",
  STUDY_FUND_EMPLOYEE_DEPOSIT = "study_fund_employee_deposit",
  HEALTH_COLLECTIVE = "eligible_health_insurance",
  FOREIGN_EMPLOYEE = "foreign_employee",
  LANGUAGE = "language",
}

export enum StudyFundTimeEnum {
  IMMEDIATE = "Immediate",
  AFTER_THREE_MONTHS = "כעבור 3 חודשים",
  AFTER_HALF_YEAR = "After Half Year",
  AFTER_YEAR = "After Year",
  OTHER = "Other",
}

export enum PensionTimeEnum {
  IMMEDIATE = "Immediate",
  LEGALLY = "Legally",
  AFTER_THREE_MONTHS = "3 months",
  AFTER_THREE_MONTHS_RETRO = "כעבור 3 חודשים רטרו'",
  AFTER_HALF_YEAR = "6 months",
  OTHER = "Other",
}

function getDate(date: string, monthsToAdd: number) {
  return moment(date).add(monthsToAdd, "month").format("YYYY-MM-DD");
}

export function getPensionPolicyDate(date: string, sfValue) {
  switch (sfValue) {
    case PensionTimeEnum.IMMEDIATE:
    case StudyFundTimeEnum.IMMEDIATE:
      return getDate(date, 0);
    case PensionTimeEnum.LEGALLY:
      return getDate(date, 3);
    case PensionTimeEnum.AFTER_THREE_MONTHS:
    case StudyFundTimeEnum.AFTER_THREE_MONTHS:
    case PensionTimeEnum.AFTER_THREE_MONTHS_RETRO:
      return getDate(date, 3);
    case PensionTimeEnum.AFTER_HALF_YEAR:
    case StudyFundTimeEnum.AFTER_HALF_YEAR:
      return getDate(date, 6);
    case StudyFundTimeEnum.AFTER_YEAR:
      return getDate(date, 12);
    default:
      return getDate(date, 0);
  }
}
