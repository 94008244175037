import { Component, OnInit } from "@angular/core";
import { InformationPageComponent } from "../../generic-components/information-page/information-page.component";
declare var $;
@Component({
    selector: "app-accessability",
    templateUrl: "./accessability.component.html",
    styleUrls: ["./accessability.component.scss"],
    standalone: true,
    imports: [InformationPageComponent]
})
export class AccessabilityComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
