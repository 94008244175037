import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HttpService {
  headers: BehaviorSubject<any>;

  constructor() {
    this.headers = new BehaviorSubject<any>(null);
  }

  updateHeader(key, value) {
    this.headers.next({ key, value });
  }
}
