import { Component, OnInit } from "@angular/core";
import { NG_VALIDATORS, NG_VALUE_ACCESSOR, ControlValueAccessor, Validator, AbstractControl, ValidationErrors, UntypedFormBuilder, UntypedFormControl, ReactiveFormsModule } from "@angular/forms";
import {
  WorkTerminationDataEnum,
  IProvidentMoneyItem,
} from "src/app/models/work-termination.model";
import { TextService } from "src/app/services/text.service";
import { ProvidentMoneyItemComponent } from "../provident-money-item/provident-money-item.component";
import { NgFor, NgClass, NgIf } from "@angular/common";

@Component({
    selector: "app-provident-money-array",
    templateUrl: "./provident-money-array.component.html",
    styleUrls: ["./provident-money-array.component.scss"],
    providers: [
        {
            provide: NG_VALIDATORS,
            useExisting: ProvidentMoneyArrayComponent,
            multi: true,
        },
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: ProvidentMoneyArrayComponent,
            multi: true,
        },
    ],
    standalone: true,
    imports: [NgFor, ProvidentMoneyItemComponent, ReactiveFormsModule, NgClass, NgIf]
})
export class ProvidentMoneyArrayComponent
  implements OnInit, ControlValueAccessor, Validator
{
  money_array_error_message = "";
  workTerminationDataEnum = WorkTerminationDataEnum;
  moneyArray = this.formBuilder.array([new UntypedFormControl(null)]);
  registerOnChangeCallback: any;

  constructor(
    public textService: TextService,
    private formBuilder: UntypedFormBuilder
  ) {}

  ngOnInit(): void {
    this.moneyArray.valueChanges.subscribe((items) => {
      if (this.registerOnChangeCallback) {
        this.registerOnChangeCallback(items);
      }
    });
  }
  validate(control: AbstractControl): ValidationErrors | null {
    if (this.moneyArray.valid) {
      return null;
    }
    return { itemsArray: "not-valid" };
  }

  registerOnChange(fn: any): void {
    this.registerOnChangeCallback = fn;
  }

  removeItem(index: number) {
    this.moneyArray.removeAt(index);
    if (this.moneyArray.length <= 4) {
      this.money_array_error_message = "";
    }
  }

  addItem() {
    if (this.moneyArray.length < 3) {
      this.moneyArray.push(new UntypedFormControl(null));
    } else {
      this.money_array_error_message = "אין אפשרות להכניס יותר מ3";
    }
  }

  writeValue(items: IProvidentMoneyItem[]): void {
    if (!items) {
      return;
    }

    this.moneyArray.clear();
    for (let index = 0; index < items.length; index++) {
      this.addItem();
    }
    this.moneyArray.patchValue(items, {
      emitEvent: false,
    });
  }

  registerOnTouched(fn: any): void { }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) {
      this.moneyArray.disable();
    }
    else {
      this.moneyArray.enable();
    }
  }
}
