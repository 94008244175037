import { Component, OnInit } from "@angular/core";
import { DataService } from "../../../services/data.service";
import { TasksService } from "../../../services/tasks.service";
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from "@angular/material/core";
import { APP_DATE_FORMATS } from "../../../format-datepicker";
//import { MAT_DATE_LOCALE } from "saturn-datepicker";
import { MomentDateAdapter } from "@angular/material-moment-adapter";
import * as moment from "moment";
import { TextService } from "../../../services/text.service";
import { Router } from "@angular/router";
import { BreadcrumbsLinkNames } from "src/app/utils/breadcrumbs-links";
import { TaskComponentComponent } from "../../generic-components/task-component/task-component.component";
import { MatLegacyOptionModule } from "@angular/material/legacy-core";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { MatLegacySelectModule } from "@angular/material/legacy-select";
import { MatLegacyFormFieldModule } from "@angular/material/legacy-form-field";
import { HeaderComponent } from "../../common/header/header.component";
import { BreadcrumbsComponent } from "../../common/breadcrumbs/breadcrumbs.component";
import { NgIf, NgFor, NgClass } from "@angular/common";
declare var $: any;
@Component({
    selector: "app-employees-tasks",
    templateUrl: "./employees-tasks.component.html",
    styleUrls: ["./employees-tasks.component.scss"],
    providers: [
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE],
        },
        { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    ],
    standalone: true,
    imports: [NgIf, BreadcrumbsComponent, HeaderComponent, MatLegacyFormFieldModule, MatLegacySelectModule, ReactiveFormsModule, FormsModule, NgFor, MatLegacyOptionModule, NgClass, TaskComponentComponent]
})
export class EmployeesTasksComponent implements OnInit {
  total_count = "סה״כ: 65";
  total_scheduled = "בוצעו: 30";
  total_held = "טרם בוצעו: 30";
  timeOptionForFilter = [
    { label: "שנה נוכחית", value: "" },
    { label: "רבעון קודם", value: "" },
    { label: "חודש נוכחי", value: "" },
  ];
  name;
  pageWidth = window.innerWidth;
  data = ["", "", "", "", "", ""];
  datePicker: any;
  dateInput = { from: undefined, to: undefined };
  selectedTime;
  selectedStatus;
  showGhostElementsTopPage = false;
  breadcrumbsPages = [
    BreadcrumbsLinkNames.HOME,
    BreadcrumbsLinkNames.MY_EMPLOYEES,
    BreadcrumbsLinkNames.TASKS,
  ];

  constructor(
    public dataService: DataService,
    public tasksService: TasksService,
    public textService: TextService,
    public router: Router
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    this.name = this.dataService.name;
    this.dataService.showGhostElements = true;
    this.showGhostElementsTopPage = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.tasksService.getCounts("", "", "").subscribe(
      (resp) => {
        this.dataService.showGhostElements = false;
        this.showGhostElementsTopPage = false;
        this.dataService.showDataLoader = false;
        this.total_count = this.dataService.getResourceText(
          this.textService.textSource["MY_MISSIONS_SCREEN_SUBTITLE_1"],
          resp
        );
        this.total_scheduled = this.dataService.getResourceText(
          this.textService.textSource["MY_MISSIONS_SCREEN_SUBTITLE_2"],
          resp
        );
        this.total_held = this.dataService.getResourceText(
          this.textService.textSource["MY_MISSIONS_SCREEN_SUBTITLE_3"],
          resp
        );
      },
      (err) => {
        this.dataService.handleErrors(err);
      }
    );
    this.dataService.showGhostElements = true;
    this.showGhostElementsTopPage = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.tasksService.getTasks("", "", "").subscribe(
      (resp) => {
        this.dataService.showGhostElements = false;
        this.showGhostElementsTopPage = false;
        this.dataService.showDataLoader = false;
        this.data = resp["missions"];
        this.timeOptionForFilter = resp["filter"];
        this.selectedTime = this.timeOptionForFilter[0];

        this.dateInput.from = moment(this.selectedTime["date_range"]["from"]);
        this.dateInput.to = moment(this.selectedTime["date_range"]["to"]);
        $(".date-input").val(
          this.dateInput.to.format("DD/MM/YY") +
            " - " +
            this.dateInput.from.format("DD/MM/YY")
        );
        $(".date-input").prop("disabled", true);
        this.saveInSoterage();
      },
      (err) => {
        this.dataService.handleErrors(err);
      }
    );
  }
  ngAfterContentInit(): void {
    this.datePicker = $(".date-input").daterangepicker(
      {
        autoApply: true,
        autoUpdateInput: false,
        opens: "center",
        locale: {
          format: "DD/MM/YYYY",
          separator: " - ",
          applyLabel: "אישור",
          cancelLabel: "ביטול",
          fromLabel: "מתאריך",
          toLabel: "עד תאריך",
          daysOfWeek: ["א", "ב", "ג", "ד", "ה", "ו", "ש"],
          monthNames: [
            "ינואר",
            "פברואר",
            "מרץ",
            "אפריל",
            "מאי",
            "יוני",
            "יולי",
            "אוגוסט",
            "ספטמבר",
            "אוקטובר",
            "נובמבר",
            "דצמבר",
          ],
          firstDay: 0,
        },
      },
      (start, end, label) => {
        this.dateInput.from = start;
        this.dateInput.to = end;
        $(".date-input").val(
          end.format("DD/MM/YY") + " - " + start.format("DD/MM/YY")
        );
        this.filterTasks();
        this.saveInSoterage();
      }
    );
    $(".date-input").val("");
  }

  filterTasks() {
    if (this.selectedTime["value"] != "") {
      $(".date-input").prop("disabled", true);
      this.dateInput.from = moment(this.selectedTime["date_range"]["from"]);
      this.dateInput.to = moment(this.selectedTime["date_range"]["to"]);
      $(".date-input").val(
        this.dateInput.to.format("DD/MM/YY") +
          " - " +
          this.dateInput.from.format("DD/MM/YY")
      );
    } else {
      $(".date-input").prop("disabled", false);
    }
    this.dataService.showGhostElements = true;
    if (this.dataService.isItMobileDisplay()) {
      this.dataService.showDataLoader = true;
    }
    this.tasksService
      .getTasks(
        moment(this.dateInput.from).format("YYYY-MM-DD"),
        moment(this.dateInput.to).format("YYYY-MM-DD"),
        this.selectedTime["value"]
      )
      .subscribe(
        (resp) => {
          this.dataService.showGhostElements = false;
          this.dataService.showDataLoader = false;
          this.data = resp["missions"];
          this.saveInSoterage();
        },
        (err) => {
          this.dataService.showGhostElements = false;
          this.dataService.handleErrors(err);
        }
      );

    this.tasksService
      .getCounts(
        moment(this.dateInput.from).format("YYYY-MM-DD"),
        moment(this.dateInput.to).format("YYYY-MM-DD"),
        this.selectedTime["value"]
      )
      .subscribe(
        (resp) => {
          this.total_count = this.dataService.getResourceText(
            this.textService.textSource["MY_MISSIONS_SCREEN_SUBTITLE_1"],
            resp
          );
          this.total_scheduled = this.dataService.getResourceText(
            this.textService.textSource["MY_MISSIONS_SCREEN_SUBTITLE_2"],
            resp
          );
          this.total_held = this.dataService.getResourceText(
            this.textService.textSource["MY_MISSIONS_SCREEN_SUBTITLE_3"],
            resp
          );
        },
        (err) => {
          this.dataService.handleErrors(err);
        }
      );
  }
  saveInSoterage() {
    sessionStorage.setItem("task-filter", this.selectedTime["value"]);

    sessionStorage.setItem(
      "task-from-date",
      moment(this.dateInput.from).format("DD/MM/YY").toString()
    );
    sessionStorage.setItem(
      "task-to-date",
      moment(this.dateInput.to).format("DD/MM/YY").toString()
    );
  }
  goToTaskType(type) {
    this.router.navigate(["/task-type", type]);
  }
  goToRegister() {
    this.router.navigate(["/register"]);
  }
  resize(event: any) {
    this.pageWidth = event.target.innerWidth;
  }
}
