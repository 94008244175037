import { Component, OnInit, Input } from "@angular/core";
import { TextService } from "../../../services/text.service";
import { DataService } from "src/app/services/data.service";
import { NgClass, NgIf } from "@angular/common";
@Component({
    selector: "app-type-task-component",
    templateUrl: "./type-task-component.component.html",
    styleUrls: ["./type-task-component.component.scss"],
    standalone: true,
    imports: [NgClass, NgIf]
})
export class TypeTaskComponentComponent implements OnInit {
  @Input() data;
  @Input() jobLeavingTask;
  name = "ליאור ברטל";
  id = "308454115";
  manager = "מנהל התיק: דייב ביטון";
  right_side_text = "תאריך פנייה: 10.10.2020";
  left_side_text = "סטטוס";
  middle_text = "";
  description = "";
  constructor(
    public textService: TextService,
    public dataService: DataService
  ) {
    this.textService.setTextSource();
  }

  ngOnInit(): void {
    this.name = this.dataService.getResourceText(
      this.textService.textSource["MISSIONS_TYPE_SCREEN_ITEM_LABEL_1"],
      this.data
    );
    this.id = this.dataService.getResourceText(
      this.textService.textSource["MISSIONS_TYPE_SCREEN_ITEM_LABEL_2"],
      this.data
    );
    this.manager = this.dataService.getResourceText(
      this.textService.textSource["MISSIONS_TYPE_SCREEN_ITEM_LABEL_3"],
      this.data
    );
    this.left_side_text = this.dataService.getResourceText(
      this.textService.textSource["MISSIONS_TYPE_SCREEN_ITEM_LABEL_7"],
      this.data
    );
    if (this.jobLeavingTask) {
      this.right_side_text = this.dataService.getResourceText(
        this.textService.textSource["MISSIONS_TYPE_SCREEN_ITEM_LABEL_4"],
        this.data
      );
      this.middle_text = this.dataService.getResourceText(
        this.textService.textSource["MISSIONS_TYPE_SCREEN_ITEM_LABEL_5"],
        this.data
      );
      this.description = this.dataService.getResourceText(
        this.textService.textSource["MISSIONS_TYPE_SCREEN_ITEM_LABEL_8"],
        this.data
      );
    } else {
      this.right_side_text = this.dataService.getResourceText(
        this.textService.textSource["MISSIONS_TYPE_SCREEN_ITEM_LABEL_6"],
        this.data
      );
    }
  }
}
