import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterContentInit,
  Input,
  AfterViewInit,
  Output,
  EventEmitter,
} from "@angular/core";
import { FeedbackService } from "src/app/services/feedback.service";
import { environment } from "src/environments/environment";
import { DataService } from "src/app/services/data.service";
import { NoContentComponent } from "../no-content/no-content.component";
import { BidiModule } from "@angular/cdk/bidi";
import { NgIf } from "@angular/common";
declare var Highcharts: any;

@Component({
    selector: "app-pie-chart",
    templateUrl: "./pie-chart.component.html",
    styleUrls: ["./pie-chart.component.scss"],
    standalone: true,
    imports: [NgIf, BidiModule, NoContentComponent]
})
export class PieChartComponent implements OnInit {
  constructor(
    private feedbackService: FeedbackService,
    public dataService: DataService
  ) {}

  @ViewChild("container") chart: ElementRef;
  @Input() chartData: any;
  homePagePie: boolean;
  @Output() pieClickedEmit = new EventEmitter();
  showChart = true;
  ngOnInit(): void {
    this.feedbackService.createChart.subscribe((res) => {
      console.log(this.dataService.showHomePie);
      this.homePagePie = this.dataService.showHomePie;
      this.createPieChart();
    });
  }

  createPieChart() {
    this.showChart = this.chartData.length > 0;
    if (this.showChart) {
      let pieHeads = this.chartData.map((item) => {
        return this.homePagePie
          ? {
              name: item.policy,
              y: Number(item.rate.split("%")[0]),
              y1: item.total_money_sent,
              sign: "%",
              sign1: "₪",
            }
          : {
              name: item.status,
              y: Number(item.rate.split("%")[0]),
              y1: item.total_money_sent,
              sign: "%",
              sign1: "₪",
              emit: this.pieClickedEmit,
            };
      });
      Highcharts.chart("container", {
        credits: {
          text: "",
          href: "",
        },
        chart: {
          type: "pie",
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          style: {
            fontFamily: "almoni-light",
          },
          height: this.homePagePie ? "350px" : "350px",
        },
        title: { text: "" },
        tooltip: {
          enabled: false,
          //     useHTML: true,
          //      headerFormat: '',
          //     pointFormat: '<table style="text-align: right; font-size: 20px;><tr>' +
          //     '<td dir="rtl" style="text-align: right; font-size: 25px;">{point.name} </td></tr>' +
          //     '<br><tr>' +
          //     '<td dir="rtl" >{point.y} {point.sign}</td></tr>' +
          //     '<br><tr>' +
          //     '<td dir="rtl">{point.y1} {point.sign1}</td></tr></table>'
          //     ,
          // // footerFormat: '</table>',
          //     borderColor: '#8ae',
          //     style: {
          //       color: "black",
          //       fontSize: "20px",
          //     }
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: this.homePagePie ? false : true,
            cursor: this.homePagePie ? "" : "pointer",
            size: 200,
            dataLabels: {
              enabled: true,
              alignTo: this.homePagePie ? "connectors" : "connectors",
              format: /*this.homePagePie*/ false
                ? `<div style="max-width:100px">
              <div dir="rtl" style="text-align:center;">{point.name}:</div>
              <div  dir="rtl" style="text-align: right; font-size: 20px;"> {point.sign1}{point.y1}</div>
            </div>`
                : `<div style="max-width:100px;">
                          <div dir="rtl" style="margin-bottom: -10px;font-size: 18px">{point.name}:</div>
                          <div dir="rtl" style="margin-left: 22px;  margin-bottom: -10px; font-size: 20px;"> {point.y}{point.sign}</div>
                          <div dir="rtl" style="margin-left: 22px; font-size: 20px;"> {point.y1}{point.sign1}</div>
                        </div>`,
              useHTML: true,
              distance: 20,
              position: "center",
              style: {
                color: "black",
                fontSize: "20px",
                fontWeight: "regular",
                textOutline: "1px white",
              },
            },
          },
          series: {
            point: {
              events: {
                click: this.homePagePie
                  ? ""
                  : function () {
                      this.emit.emit(this.name);
                    },
              },
            },
          },

          cursor: this.homePagePie ? "" : "pointer",
          borderWidth: 3,
        },
        series: [
          {
            data: pieHeads,
            colors: environment.colors,
          },
        ],
        responsive: {
          rules: [
            {
              condition: {
                maxWidth: 400,
              },
              chartOptions: {
                chart: {
                  height: "350px",
                },
                plotOptions: {
                  series: {
                    dataLabels: {
                      format:
                        "<b>{point.name}</b>: {point.y:.1f} % {point.y:.2f} % ",
                    },
                  },
                  pie: {
                    dataLabels: {
                      enabled: true,
                      // alignTo:  '',
                      format: this.homePagePie
                        ? `<div style="max-width:100px; margin-top: -20px;">
                    <div dir="rtl" style="text-align: right; margin-bottom: -5px;">{point.name} :</div>
                   
                    <div  dir="rtl" style="text-align: right; font-size: 20px;"> {point.y1}{point.sign1}</div>
                  </div>`
                        : `<div style="width:80px;margin-top: -10px;">
                              <div dir="rtl" style="margin-bottom: -5px;">{point.name} :</div>
                              <div  dir="rtl" style="margin-left: 30px; font-size: 20px;"> {point.y}{point.sign}</div>
                            </div>`,
                      distance: 30,
                      position: "center",
                      style: {
                        color: "black",
                        fontSize: "15px",
                        fontWeight: "bold",
                        textOutline: "1px white",
                      },
                    },
                  },
                },
              },
            },
          ],
        },
      });
    }
  }

  pieClicked(value) {
    console.log(value);
  }
}

// ngOnInit(): void {
//   this.feedbackService.createChart.subscribe( res => {
//
//     this.createPieChart();
//   })
// }

// createPieChart(){
//   let pieHeads = this.chartData.map( item=>{
//     return {name:item.status, y:Number(item.rate.split('%')[0])}
//   })

// let pieHeads = this.chartData.map(item => {
//       return { name: item.status, y: Number(item.rate.split('%')[0]) }
//     })
