import { Injectable } from "@angular/core";
import { Observable, throwError, Subscription } from "rxjs";
import { catchError, retry, map } from "rxjs/operators";
import {
  HttpClient,
  HttpHeaders,
  HttpClientModule,
  HttpErrorResponse,
} from "@angular/common/http";
import * as Config from "../../environments/environment";
import { DataService } from "./data.service";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class RequestService {
  httpOptions: any;
  config: any;
  constructor(private http: HttpClient, public dataService: DataService) {
    this.config = Config.environment;
    this.httpOptions = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }).append(environment.auth_header_key, environment.auth_header_value),
    };
  }
}
