// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.max-hight {
  min-height: 86vh;
  max-width: 100%;
  margin: 0 auto;
}

:focus {
  border: 1px black solid;
}`, "",{"version":3,"sources":["webpack://./src/app/app.component.scss"],"names":[],"mappings":"AAAA;EAEE,gBAAA;EAIA,eAAA;EACA,cAAA;AAHF;;AAKA;EACE,uBAAA;AAFF","sourcesContent":[".max-hight {\n  // min-height: 100vh;\n  min-height: 86vh;\n  // min-height: calc(var(--vh, 1vh) * 100);\n  //height: 100vh;\n  // max-width: 1440px;\n  max-width: 100%;\n  margin: 0 auto;\n}\n:focus {\n  border: 1px black solid;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
